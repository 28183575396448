import { RefObject } from 'react'

export type WalletScreenTypes = 'general' | 'receive' | 'send' | 'manage'
export type SendSteps = 'select' | 'crypto' | 'confirm' | 'success'
export type ManageSteps = 'menu' | 'fill' | 'success'
export interface CryptoData {
  target: string
  data: { token: string; icon: string; symbol: string | null }
  value: string
}
export interface WalletScreenState {
  activeScreen: WalletScreenTypes
  setActiveScreen: (newActiveScreen: WalletScreenTypes) => void
  cryptoData?: CryptoData
  transHash?: string
  account?: string
}

export const handleCopyClick = (
  ref: RefObject<HTMLDivElement> | string,
  setState?: (value: boolean) => void,
) => {
  if (typeof ref !== 'string' && ref?.current) {
    const text = ref.current.innerText
    navigator.clipboard.writeText(text).then(() => {
      if (setState) {
        setState(true)
        setTimeout(() => setState(false), 1000)
      }
    })
  } else {
    if (typeof ref === 'string') {
      navigator.clipboard.writeText(ref).then(() => {
        if (setState) {
          setState(true)
          setTimeout(() => setState(false), 1000)
        }
      })
    }
  }
}
