import React from 'react'
import Logo from '../../shared/assets/MidLogo.png'
import NotFound from '../../shared/assets/NotFound.png'
import ServerError from '../../shared/assets/ServerError.png'
import Blocked from '../../shared/assets/Blocked.png'
import Maintenance from '../../shared/assets/Maintenance.png'
import useLocales from '../../shared/hooks/useLocales'
import { Flex, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import { PATH_CONTRACT } from '../../shared/constants/path'
import { useTheme } from '../../app/theme/useTheme'
import { Content, Footer, Header, InnerContent } from './UtilityPage.styled'
import Title from '../../shared/components/Typography/Title'
import Text from '../../shared/components/Typography/Text'
import Button from '../../shared/ui/Button'
import { Trans } from 'react-i18next'

type PageType = 'notFound' | 'serverError' | 'maintenance' | 'blocked'

interface UtilityPageProps {
  type: PageType
}

interface PageContent {
  title: string
  message: string
  image: string
  buttonLabel?: string
  buttonLink?: string
  footer?: string
}

const pageData: Record<PageType, PageContent> = {
  notFound: {
    image: NotFound,
    title: 'utility.notFound.title',
    message: 'utility.notFound.message',
    buttonLabel: 'utility.notFound.button',
    buttonLink: PATH_CONTRACT.pending,
    footer: 'utility.notFound.footer',
  },
  serverError: {
    image: ServerError,
    title: 'utility.serverError.title',
    message: 'utility.serverError.message',
    buttonLabel: 'utility.serverError.button',
    footer: 'utility.serverError.footer',
  },
  maintenance: {
    image: Maintenance,
    title: 'utility.maintenance.title',
    message: 'utility.maintenance.message',
  },
  blocked: {
    image: Blocked,
    title: 'utility.blocked.title',
    message: 'utility.blocked.message',
  },
}

const UtilityPage: React.FC<UtilityPageProps> = ({ type }) => {
  const {
    title,
    message,
    image,
    buttonLabel,
    buttonLink,
    footer = '',
  } = pageData[type]
  const { translate } = useLocales()
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Flex style={{ minHeight: '100vh' }} vertical>
      <Header onClick={() => navigate(PATH_CONTRACT.pending)}>
        <Image src={Logo} style={{ height: '30px' }} preview={false} />
      </Header>
      <Content vertical>
        <InnerContent>
          <Flex vertical align="center" gap={theme.marginXL}>
            <Flex vertical gap={theme.margin} align="center">
              <Image src={image} preview={false} style={{ width: '160px' }} />
              <Flex
                vertical
                gap={theme.marginXS}
                justify="center"
                align="center"
              >
                <Title level={2}>{translate(title)}</Title>
                <Text size="lg" style={{ textAlign: 'center' }}>
                  <Trans i18nKey={message} components={{ br: <br /> }} />
                </Text>
              </Flex>
            </Flex>
            {buttonLabel && (
              <Button
                onClick={() =>
                  buttonLink
                    ? navigate(buttonLink)
                    : type === 'serverError'
                      ? window.location.reload()
                      : null
                }
              >
                {translate(buttonLabel)}
              </Button>
            )}
          </Flex>
        </InnerContent>
        {footer && (
          <Footer>
            <Text type="secondary">{translate(footer)}</Text>
          </Footer>
        )}
      </Content>
    </Flex>
  )
}

export default UtilityPage
