import { theme } from 'antd'
import themeColors from './themeColors.ts'

export const config = {
  // algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: themeColors.gray[9],
    colorPrimaryBg: themeColors.gray[0],
    colorPrimaryBgHover: themeColors.gray[1],
    colorPrimaryBorder: themeColors.gray[2],
    colorPrimaryBorderHover: themeColors.gray[3],
    colorPrimaryHover: themeColors.gray[7],
    colorPrimaryActive: themeColors.gray[8],
    colorPrimaryTextHover: themeColors.gray[7],
    colorPrimaryText: themeColors.gray[8],
    colorPrimaryTextActive: themeColors.gray[9],
    colorTextQuaternary: 'rgba(0, 0, 0, 0.32)',
    colorTextDescription: 'rgba(0, 0, 0, 0.70)',
    colorTextLabel: 'rgba(0, 0, 0, 0.88)',
    colorBorder: '#cccccc',
    colorBgLayout: '#F9F9F9',
    colorBgActivityLayout: '#FCFCFD',
    colorBorderSecondary: '#e0e0e0',
    controlHeightSM: 32,
    controlHeightLG: 48,
    controlHeight: 40,
    colorSplit: 'rgba(231, 232, 236)',
    fontFamily: 'Inter',
    fontSizeHeading1: 30,
    fontSizeHeading2: 24,
    fontSizeHeading3: 20,
    fontSizeHeading4: 16,
    fontSizeHeading5: 14,
    colorLinkHover: '#4096FF',
    fontSizeSM: 13,
  },
  components: {
    Typography: {
      titleMarginBottom: 0,
      lineHeightHeading1: 1.3,
      lineHeightHeading2: 1.375,
      lineHeightHeading3: 1.4,
      lineHeightHeading4: 1.5,
      lineHeightHeading5: 1.5,
    },
    Pagination: {
      itemActiveBg: themeColors.gray[6],
      fontSize: 14,
      colorPrimary: theme.getDesignToken().colorTextLightSolid,
      colorPrimaryHover: theme.getDesignToken().colorTextLightSolid,
      borderRadius: theme.getDesignToken().borderRadiusLG,
      fontWeightStrong: 700,
    },
    Segmented: {
      colorTextLabel: 'rgba(0, 0, 0, 0.88)',
      colorText: 'white',
      borderRadiusSM: theme.getDesignToken().borderRadiusLG,
      colorBgElevated: themeColors.gray[6],
      borderRadiusXS: theme.getDesignToken().borderRadius,
    },
    Modal: {
      borderRadiusLG: 16,
      paddingContentHorizontalLG: theme.getDesignToken().paddingXL,
      paddingMD: theme.getDesignToken().paddingXL,
      marginSM: theme.getDesignToken().marginXL,
      marginXS: theme.getDesignToken().margin,
    },
    'Modal.confirm': {
      borderRadiusLG: 16,
      paddingContentHorizontalLG: theme.getDesignToken().paddingXL,
      paddingMD: theme.getDesignToken().paddingXL,
      marginSM: theme.getDesignToken().marginXL,
      marginXS: theme.getDesignToken().margin,
    },
    Card: {
      borderRadiusLG: 16,
      colorBorderSecondary: '#E7E8EC',
      // paddingLG: theme.getDesignToken().paddingMD,
    },
    Divider: {
      marginLG: 0,
      colorSplit: '#E7E8EC',
    },
    Tag: {
      borderRadiusSM: 6,
    },
    Select: {
      borderRadiusSM: theme.getDesignToken().borderRadius,
    },
    Button: {
      borderRadiusSM: theme.getDesignToken().borderRadius,
      borderRadius: theme.getDesignToken().borderRadiusLG,
      defaultActiveBg: theme.getDesignToken().colorFill,
      defaultHoverBg: theme.getDesignToken().colorBgTextHover,
      defaultActiveBorderColor: theme.getDesignToken().colorBorder,
      defaultBorderColor: theme.getDesignToken().colorBorder,
      defaultHoverBorderColor: theme.getDesignToken().colorBorder,
      borderColorDisabled: theme.getDesignToken().colorBorder,
      fontWeight: 600,
      paddingInlineSM: theme.getDesignToken().paddingSM,
    },
    Input: {
      borderRadius: theme.getDesignToken().borderRadiusLG,
    },
    DatePicker: {
      borderRadius: theme.getDesignToken().borderRadiusLG,
      borderRadiusSM: theme.getDesignToken().borderRadius,
    },
    Alert: {
      withDescriptionPadding: theme.getDesignToken().padding,
    },
  },
}
