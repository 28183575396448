import React, {
  createContext,
  useContext,
  useCallback,
  ReactNode,
  useEffect,
} from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { setApolloErrorHandler } from './errorHandler'

type ErrorHandler = (error: Error) => void

const ErrorContext = createContext<ErrorHandler | undefined>(undefined)

export const useErrorHandler = (): ErrorHandler => {
  const context = useContext(ErrorContext)
  if (!context) {
    throw new Error('useErrorHandler should be used ErrorProvider')
  }
  return context
}

interface ErrorProviderProps {
  children: ReactNode
}

export const ErrorProvider: React.FC<ErrorProviderProps> = ({ children }) => {
  const { showBoundary } = useErrorBoundary()

  const isCriticalError = (error: Error): boolean => {
    if (error.message.includes('Critical')) {
      return true
    }
    return false
  }

  const handleError = useCallback(
    (error: Error) => {
      console.error('Error:', error)

      if (isCriticalError(error)) {
        showBoundary(error)
      } else {
        console.warn('Non-critical error:', error.message)
      }
    },
    [showBoundary],
  )

  useEffect(() => {
    setApolloErrorHandler(handleError)
  }, [handleError])

  return (
    <ErrorContext.Provider value={handleError}>
      {children}
    </ErrorContext.Provider>
  )
}

export default ErrorProvider
