import { useEffect, useState } from 'react'
import {
  CardPayment,
  PaymentIcon,
} from '../../../../../../../../List.styled.ts'
import { Col, Image, Row, Skeleton, Tooltip } from 'antd'
import Text from '../../../../../../../../../../../../shared/components/Typography/Text'
import Button from '../../../../../../../../../../../../shared/ui/Button'
import { useTheme } from '../../../../../../../../../../../../app/theme/useTheme.tsx'
import copySvg from '../../../../../../../../../../../../shared/assets/copy.svg'
import { useTruncateByChars } from '../../../../../../../../../../../../shared/hooks/useTruncateByChars.tsx'
import alhemeSvg from '../../../../../../../../../../../../shared/assets/alheme.svg'
import alhemeSvgConnected from '../../../../../../../../../../../../shared/assets/alheme-connected.svg'
import { handleCopyClick } from '../../../../../../../../../../../../layouts/DashboardLayout/components/EmbeddedWallet/utils'
import { useCurrentAccount } from '../../../../../../../../../../../../shared/hooks/useCurrentAccount.tsx'
import { Icon } from '@iconify/react'
import { useAuthenticate, useChain } from '@account-kit/react'
import { EmbeddedWalletEmailModal } from './embeddedWalletEmailModal.tsx'
import { WALLET_TYPES } from '../../../../../../../../../../../../shared/constants/constants.ts'
import { useAuthStore } from '../../../../../../../../../../../../shared/services/authStore'
import { userSelector } from '../../../../../../../../../../../../shared/services/authStore/selectors.ts'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useDisconnect } from 'wagmi'
import { useWalletIcon } from '../../../../../../../../../../../../shared/hooks/useWalletIcon.tsx'
import { useLogoutEmbedded } from '../../../../../../../../../../../../shared/hooks/useLogoutEmbedded.tsx'
import { useLocation } from 'react-router-dom'

interface IConnectedWallet {
  wallet?: string | null
  error?: string | null
  icon?: string | null
  title?: string | null
  walletType?: string | null
  loading?: boolean
  showContractWallet?: boolean
  isPaymentFlow?: boolean
}

const ConnectedWallet: React.FC<IConnectedWallet> = ({
  walletType,
  wallet,
  icon,
  error,
  loading,
  title,
  showContractWallet,
  isPaymentFlow,
}) => {
  const theme = useTheme()
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const { currentAccount, embeddedAccount, externalAccount } =
    useCurrentAccount()
  const truncateByChars = useTruncateByChars()
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { email } = useAuthStore(userSelector)
  const { openConnectModal } = useConnectModal()
  const handleLogout = useLogoutEmbedded()
  const { disconnect } = useDisconnect()
  const location = useLocation()
  const currentPath = location.pathname
  const chain = useChain()

  const { authenticate } = useAuthenticate()
  const connectedIcon = useWalletIcon()

  const account = showContractWallet
    ? (wallet ?? '')
    : (currentAccount ?? wallet ?? '')

  const handleButton = () => {
    if (currentAccount) {
      embeddedAccount ? handleLogout() : disconnect()
    } else if (walletType === WALLET_TYPES.Embedded && email) {
      setIsEmailModalOpen(true)
      const redirectParams = new URLSearchParams({
        returnTo: currentPath,
      })
      authenticate({
        type: 'email',
        email,
        redirectParams,
      })
    } else {
      if (openConnectModal) {
        openConnectModal()
      }
    }
  }

  const getButtonName = () => {
    if (showContractWallet) return 'Connect'
    if (externalAccount || embeddedAccount) return 'Disconnect'
    if (wallet || title) return 'Connect'
  }

  const renderTitle = () => {
    const renderAccount = truncateByChars(account) || 'Metamask'

    if (externalAccount && !showContractWallet && !isPaymentFlow) {
      return (
        <Text size="sm" $bold>
          {truncateByChars(externalAccount)}
        </Text>
      )
    }

    const isEmbedded =
      (embeddedAccount && walletType !== WALLET_TYPES.External) ||
      walletType === WALLET_TYPES.Embedded

    if (isEmbedded || (title && account) || isPaymentFlow) {
      return (
        <Col>
          <Text size="sm" $bold>
            {renderAccount}
          </Text>
          <Text size="sm" type="secondary">
            {isPaymentFlow
              ? chain.chain.name.toLowerCase()
              : isEmbedded
                ? 'embedded'
                : title}
          </Text>
        </Col>
      )
    }

    return (
      <Text size="sm" $bold>
        {title || renderAccount}
      </Text>
    )
  }

  const renderIcon = () => {
    if (externalAccount && !showContractWallet) {
      return <Image src={connectedIcon} preview={false} />
    } else if (
      walletType === 'EMBEDDED' ||
      (embeddedAccount && !showContractWallet)
    ) {
      return (
        <Image
          src={
            title && icon
              ? icon
              : embeddedAccount
                ? alhemeSvgConnected
                : alhemeSvg
          }
          preview={false}
        />
      )
    } else if (showContractWallet && icon) {
      return <Image src={icon} preview={false} />
    } else if (icon) {
      return <Image src={icon} preview={false} />
    } else {
      return <PaymentIcon icon={<Icon icon="logos:metamask-icon" />} />
    }
  }

  const CopyButton = () => {
    return (
      <Tooltip title="Copied" open={tooltipVisible}>
        <Button
          type="link"
          style={{ height: 'auto', padding: '4px 0' }}
          onClick={() => handleCopyClick(String(account), setTooltipVisible)}
        >
          <Image src={copySvg} preview={false} />
        </Button>
      </Tooltip>
    )
  }

  useEffect(() => {
    if (loading) setIsLoading(true)
    if (showContractWallet || wallet || currentAccount) setIsLoading(false)
  }, [isLoading, showContractWallet, wallet, loading, currentAccount])

  return (
    <>
      <CardPayment error={error ? error : undefined} style={{ width: '100%' }}>
        <EmbeddedWalletEmailModal
          isOpen={isEmailModalOpen}
          setIsOpen={setIsEmailModalOpen}
        />
        <Row align="middle" justify="space-between">
          <Row align="middle" style={{ gap: theme.paddingSM }}>
            {isLoading ? (
              <Skeleton.Avatar active size="small" shape="circle" />
            ) : (
              renderIcon()
            )}
            {isLoading ? <Skeleton.Input active size="small" /> : renderTitle()}
            {!title && !isPaymentFlow && !isLoading && <CopyButton />}
          </Row>

          <Col>
            {isPaymentFlow ? (
              <CopyButton />
            ) : isLoading ? (
              <Skeleton.Button active size="small" shape="default" />
            ) : (
              <Button
                size="small"
                onClick={handleButton}
                disabled={
                  !!(showContractWallet && (externalAccount || embeddedAccount))
                }
              >
                {getButtonName()}
              </Button>
            )}
          </Col>
        </Row>
      </CardPayment>
      {error && (
        <Row style={{ minHeight: '22px', marginTop: theme.marginXXS }}>
          <Text color={theme.colorError}>{error}</Text>
        </Row>
      )}
    </>
  )
}

export default ConnectedWallet
