import { useEffect } from 'react'
import { auth } from '../firebase'
import Router from '../routes'
import ThemeProvider from './theme'
import useGetUserLazy from '../pages/auth/services/useGetUserLazy'
import { withProfiler } from '@sentry/react'
import { useRefresh, useSetLoading } from './hooks/hooks'
import { useNavigate } from 'react-router'
import { PATH_AUTH } from '../shared/constants/path.ts'
import { ErrorBoundary } from 'react-error-boundary'
import UtilityPage from '../pages/UtilityPage/index.tsx'
import ErrorProvider from './context/ErrorContext.tsx'

function Application() {
  const setUserAfterRefresh = useRefresh()
  const setLoading = useSetLoading()
  const { getUser } = useGetUserLazy()
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          await getUser({
            fetchPolicy: 'no-cache',
            onError: (e) => {
              e.message.includes('not') && navigate(PATH_AUTH.choose_role)
            },
            onCompleted: (data) =>
              setUserAfterRefresh(user, Boolean(data), data?.findMyUser),
          })
        }
      } catch (error) {
        console.error('Error during authentication state change:', error)
      } finally {
        setLoading(false)
      }
    })

    return () => {
      unsubscribe()
      setLoading(false)
    }
  }, [setUserAfterRefresh, setLoading, getUser])

  return (
    <ThemeProvider>
      <ErrorBoundary
        FallbackComponent={() => <UtilityPage type="serverError" />}
      >
        <ErrorProvider>
          <Router />
        </ErrorProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

const App = withProfiler(Application)

export default App
