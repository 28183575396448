import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Timestamp: { input: any; output: any }
}

export type AcceptContractDto = {
  contractId?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<ContractUserRole>
}

export type ActivationHistory = {
  __typename?: 'ActivationHistory'
  activationStatus: ContractActivationStatus
  createdAt: Scalars['Timestamp']['output']
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
}

export type Activity = {
  __typename?: 'Activity'
  contractId: Scalars['ID']['output']
  createdAt: Scalars['Timestamp']['output']
  /** The status of the event */
  event: Scalars['String']['output']
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  milestoneId?: Maybe<Scalars['ID']['output']>
  userId: Scalars['ID']['output']
}

export type ActivityFilterParams = {
  contractId?: InputMaybe<Scalars['ID']['input']>
  milestoneId?: InputMaybe<Scalars['ID']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type AddTransactionDataInput = {
  /** depositId */
  depositId: Scalars['Int']['input']
  /** disputeId */
  disputeId?: InputMaybe<Scalars['Int']['input']>
  /** hash */
  hash: Scalars['String']['input']
  /** milestoneIds */
  milestoneIds?: InputMaybe<Array<Scalars['Int']['input']>>
  /** refillAmount */
  refillAmount?: InputMaybe<Scalars['Float']['input']>
  /** type */
  type?: InputMaybe<TransactionType>
  /** workingWeekIds */
  workingWeekIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']['output']>
  companyId?: Maybe<Scalars['Int']['output']>
  country?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the address */
  id: Scalars['ID']['output']
  line1?: Maybe<Scalars['String']['output']>
  line2?: Maybe<Scalars['String']['output']>
  profileId?: Maybe<Scalars['Int']['output']>
  state?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export type ApproveWorkInput = {
  contractId?: InputMaybe<Scalars['Int']['input']>
  milestoneId?: InputMaybe<Scalars['Int']['input']>
  requestChangeId?: InputMaybe<Scalars['Int']['input']>
  submitWorkId?: InputMaybe<Scalars['Int']['input']>
  workingWeekId?: InputMaybe<Scalars['Int']['input']>
}

export type ApproveWorkResponse = {
  __typename?: 'ApproveWorkResponse'
  message: Scalars['String']['output']
}

export type AssignedUsers = {
  __typename?: 'AssignedUsers'
  /** contractId */
  contractId?: Maybe<Scalars['Int']['output']>
  /** email */
  email?: Maybe<Scalars['String']['output']>
  /** firstName */
  firstName?: Maybe<Scalars['String']['output']>
  /** id */
  id?: Maybe<Scalars['Int']['output']>
  /** lastName */
  lastName?: Maybe<Scalars['String']['output']>
  /** role */
  role?: Maybe<Scalars['String']['output']>
  /** userId */
  userId?: Maybe<Scalars['Int']['output']>
}

export enum ChangeStatus {
  Decrease = 'DECREASE',
  Increase = 'INCREASE',
  WithoutChange = 'WITHOUT_CHANGE',
}

export type Company = {
  __typename?: 'Company'
  address?: Maybe<Address>
  addressId?: Maybe<Scalars['Int']['output']>
  /** The unique identifier of the company */
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  profileId?: Maybe<Scalars['Int']['output']>
  size?: Maybe<Scalars['String']['output']>
}

export type ContactStatusChange = {
  __typename?: 'ContactStatusChange'
  contractId: Scalars['ID']['output']
  createdAt: Scalars['Timestamp']['output']
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  /** The status of the contract */
  status: Scalars['String']['output']
}

export type Contract = {
  __typename?: 'Contract'
  activationHistory: Array<ActivationHistory>
  /** The activation status of the contract */
  activationStatus: Scalars['String']['output']
  activity: Array<Activity>
  archived: Scalars['Boolean']['output']
  assignUsers: Array<ContractUserAssign>
  budget?: Maybe<Scalars['Float']['output']>
  category?: Maybe<Scalars['String']['output']>
  /** The hash of the content contract */
  contractName: Scalars['String']['output']
  createdAt: Scalars['Timestamp']['output']
  /** The activation status of the contract */
  currency: Scalars['String']['output']
  descriptions: Array<ContractDescription>
  dispute: Array<Dispute>
  /** UTC or Timestamp, example: "2023-12-26T23:42:00" or 1703634120000 */
  dueDate?: Maybe<Scalars['Timestamp']['output']>
  email: Scalars['String']['output']
  endDate?: Maybe<Scalars['Timestamp']['output']>
  escrowContractId?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  initialPrepayment?: Maybe<Scalars['Float']['output']>
  limit?: Maybe<Scalars['Int']['output']>
  limitType?: Maybe<Scalars['String']['output']>
  milestones: Array<ContractMilestone>
  paymentType: Scalars['String']['output']
  /** Escrow contract ID in smart contract */
  prepayment?: Maybe<Scalars['Float']['output']>
  projectRequirements?: Maybe<Scalars['String']['output']>
  rate?: Maybe<Scalars['Float']['output']>
  rates: Array<ContractRate>
  /** Reference id */
  referenceId?: Maybe<Scalars['String']['output']>
  sendContract: Array<SendContract>
  /** The status of the contract */
  status: Scalars['String']['output']
  statusHistory?: Maybe<Array<ContactStatusChange>>
  submitWork: Array<SubmitWork>
  transactions: Array<Transaction>
  type: Scalars['String']['output']
  useMilestones: Scalars['Boolean']['output']
  verified: Scalars['Boolean']['output']
  weekLimitInfo: Array<WorkingWeekLimit>
  workingWeek: Array<WorkingWeek>
}

export enum ContractActivationStatus {
  Accepted = 'ACCEPTED',
  Funded = 'FUNDED',
  NotActivated = 'NOT_ACTIVATED',
  Pending = 'PENDING',
  Verified = 'VERIFIED',
}

export enum ContractCurrency {
  Dai = 'DAI',
  Usdc = 'USDC',
  Usdt = 'USDT',
}

export type ContractDescription = {
  __typename?: 'ContractDescription'
  contractId: Scalars['Int']['output']
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  reason: Scalars['String']['output']
}

export type ContractFilterInput = {
  contractStatus?: InputMaybe<ContractStatusType>
  subStatus?: InputMaybe<Array<ContractSubStatusType>>
}

export enum ContractLimitType {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
}

export type ContractMilestone = {
  __typename?: 'ContractMilestone'
  activity: Array<Activity>
  budget: Scalars['Float']['output']
  contractId?: Maybe<Scalars['Int']['output']>
  createdAt: Scalars['Timestamp']['output']
  /** UTC or Timestamp, example: "2023-12-26T23:42:00" or 1703634120000 */
  dueDate?: Maybe<Scalars['Timestamp']['output']>
  escrowMilestoneId?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the milestone contract */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  /** The status of the milestone */
  status: Scalars['String']['output']
  statusHistory?: Maybe<Array<MilestoneStatusChange>>
  submitWork: Array<SubmitWork>
}

export type ContractPaginated = {
  __typename?: 'ContractPaginated'
  hasNextPage: Scalars['Boolean']['output']
  nodes?: Maybe<Array<ViewContract>>
  totalCount: Scalars['Int']['output']
}

export enum ContractPaymentType {
  Bank = 'BANK',
  Blockchain = 'BLOCKCHAIN',
}

export type ContractRate = {
  __typename?: 'ContractRate'
  contractId: Scalars['Int']['output']
  createdAt: Scalars['Timestamp']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  rate: Scalars['Float']['output']
  /** The status of the contract rate */
  status: ContractRateStatus
  userId?: Maybe<Scalars['Int']['output']>
}

export enum ContractRateStatus {
  Current = 'CURRENT',
  Draft = 'DRAFT',
  Future = 'FUTURE',
  Previous = 'PREVIOUS',
}

export enum ContractStatus {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Disputed = 'DISPUTED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Onhold = 'ONHOLD',
  Paused = 'PAUSED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum ContractStatusType {
  Archive = 'ARCHIVE',
  Ongoing = 'ONGOING',
  Pending = 'PENDING',
}

export enum ContractSubStatusType {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Disputed = 'DISPUTED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  NewOffer = 'NEW_OFFER',
  OfferSend = 'OFFER_SEND',
  Onhold = 'ONHOLD',
  Paused = 'PAUSED',
  Rejected = 'REJECTED',
}

export enum ContractType {
  HourlyRate = 'HOURLY_RATE',
  PerProject = 'PER_PROJECT',
}

export type ContractUserAssign = {
  __typename?: 'ContractUserAssign'
  /** Contract id */
  contractId: Scalars['String']['output']
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  /** The role of the user */
  role: Scalars['String']['output']
  /** User id */
  userId?: Maybe<Scalars['String']['output']>
}

export enum ContractUserRole {
  Agency = 'AGENCY',
  Client = 'CLIENT',
  Freelancer = 'FREELANCER',
}

export type CreateAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>
  country: Scalars['String']['input']
  isCompanyProfile?: InputMaybe<Scalars['Boolean']['input']>
  line1?: InputMaybe<Scalars['String']['input']>
  line2?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type CreateCompanyInput = {
  name: Scalars['String']['input']
  size?: InputMaybe<Scalars['String']['input']>
}

export type CreateContractInput = {
  budget?: InputMaybe<Scalars['Float']['input']>
  category?: InputMaybe<Scalars['String']['input']>
  contractName: Scalars['String']['input']
  /** The activation status of the contract */
  currency?: InputMaybe<ContractCurrency>
  dueDate?: InputMaybe<Scalars['Timestamp']['input']>
  email: Scalars['String']['input']
  escrowContractId?: InputMaybe<Scalars['String']['input']>
  initialPrepayment?: InputMaybe<Scalars['Float']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  limitType?: InputMaybe<ContractLimitType>
  milestones?: Array<CreateContractMilestoneInput>
  paymentType?: InputMaybe<ContractPaymentType>
  prepayment?: InputMaybe<Scalars['Float']['input']>
  projectRequirements?: InputMaybe<Scalars['String']['input']>
  rate?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<ContractType>
  verified?: Scalars['Boolean']['input']
  /** Week limit. Use when contract have type hourly rate */
  weekLimit?: InputMaybe<Scalars['Int']['input']>
}

export type CreateContractMilestoneInput = {
  budget?: InputMaybe<Scalars['Float']['input']>
  /** UTC or Timestamp, example: "2023-12-26T23:42:00" or 1703634120000 */
  dueDate?: InputMaybe<Scalars['Timestamp']['input']>
  escrowMilestoneId?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<MilestoneStatus>
}

export type CreateContractSendDto = {
  clientMessage?: InputMaybe<Scalars['String']['input']>
  contractId?: InputMaybe<Scalars['Int']['input']>
}

export type CreateDepositInput = {
  contractId?: InputMaybe<Scalars['Int']['input']>
  milestoneId?: InputMaybe<Scalars['Int']['input']>
  workingWeekId?: InputMaybe<Scalars['Int']['input']>
}

export type CreateDepositParamsInput = {
  contractId?: InputMaybe<Scalars['Int']['input']>
  milestoneIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type CreateDisputeHourlyOnlyTimeInput = {
  contractId: Scalars['Int']['input']
  disputedTime: Scalars['Int']['input']
  message?: InputMaybe<Scalars['String']['input']>
  workingWeekId: Scalars['Int']['input']
}

export type CreateProfileInput = {
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  middleName?: InputMaybe<Scalars['String']['input']>
  role: UserRole
}

export type CreateRequestChangeInput = {
  budget: Scalars['Float']['input']
  description: Scalars['String']['input']
  submitWorkId: Scalars['ID']['input']
}

export type CreateUserEscrowAddressInput = {
  contractType?: InputMaybe<EscrowContractTypeEnum>
  escrowAddress?: InputMaybe<Scalars['String']['input']>
  escrowSalt?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['Int']['input']>
}

export type CreateWalletInput = {
  /** contractId */
  contractId?: InputMaybe<Scalars['Int']['input']>
  /** userId */
  userId?: InputMaybe<Scalars['Int']['input']>
  /** Wallet */
  wallet: Scalars['String']['input']
}

export type DeclineContractDto = {
  contractId?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  reason?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<ContractUserRole>
}

export type Deposit = {
  __typename?: 'Deposit'
  contractId?: Maybe<Scalars['Int']['output']>
  createdAt: Scalars['Timestamp']['output']
  freelancerSecret?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  milestoneId?: Maybe<Scalars['Int']['output']>
  milestones?: Maybe<Array<DepositMilestone>>
  smartContract?: Maybe<Scalars['String']['output']>
  workingWeeks?: Maybe<Array<DepositWorkingWeek>>
}

export type DepositMilestone = {
  __typename?: 'DepositMilestone'
  createdAt: Scalars['Timestamp']['output']
  deposit: Deposit
  depositId?: Maybe<Scalars['Int']['output']>
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  milestoneId?: Maybe<Scalars['Int']['output']>
}

export type DepositWorkingWeek = {
  __typename?: 'DepositWorkingWeek'
  createdAt: Scalars['Timestamp']['output']
  deposit: Deposit
  depositId?: Maybe<Scalars['Int']['output']>
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  workingWeekId?: Maybe<Scalars['Int']['output']>
}

export type Dispute = {
  __typename?: 'Dispute'
  clientAmount?: Maybe<Scalars['Float']['output']>
  contract?: Maybe<Contract>
  contractId: Scalars['Int']['output']
  contractorAmount?: Maybe<Scalars['Float']['output']>
  createdAt: Scalars['Timestamp']['output']
  disputedTime?: Maybe<Scalars['Int']['output']>
  /** The unique identifier of the dispute */
  id: Scalars['ID']['output']
  initiatorId?: Maybe<Scalars['Int']['output']>
  message?: Maybe<Scalars['String']['output']>
  milestones?: Maybe<Array<DisputeMilestone>>
  parentRequestId?: Maybe<Scalars['Int']['output']>
  resolutionDate?: Maybe<Scalars['Timestamp']['output']>
  /** The status resolution of the dispute */
  resolutionStatus?: Maybe<Scalars['String']['output']>
  resolutionTime?: Maybe<Scalars['Int']['output']>
  /** The status of the dispute */
  status: DisputeStatus
  subject?: Maybe<Scalars['String']['output']>
  ticketId?: Maybe<Scalars['Int']['output']>
  total?: Maybe<Scalars['Float']['output']>
  /** The type of the dispute */
  type: Scalars['String']['output']
  updatedAt: Scalars['Timestamp']['output']
  workingWeek?: Maybe<Array<WorkingWeek>>
  workingWeeks?: Maybe<Array<WorkingWeekView>>
}

export type DisputeListParamsInput = {
  /** contractId */
  contractId?: InputMaybe<Scalars['Int']['input']>
  /** limit */
  limit?: InputMaybe<Scalars['Int']['input']>
  milestoneIds?: InputMaybe<Array<Scalars['Int']['input']>>
  /** page */
  page?: InputMaybe<Scalars['Int']['input']>
}

export type DisputeListViewOutputPaginated = {
  __typename?: 'DisputeListViewOutputPaginated'
  hasNextPage?: Maybe<Scalars['Boolean']['output']>
  nodes?: Maybe<Array<DisputeViewOutput>>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type DisputeMilestone = {
  __typename?: 'DisputeMilestone'
  createdAt: Scalars['Timestamp']['output']
  dispute: Dispute
  disputeId?: Maybe<Scalars['Int']['output']>
  /** The unique identifier of the dispute milestone */
  id: Scalars['ID']['output']
  milestone: ContractMilestone
  milestoneId?: Maybe<Scalars['Int']['output']>
}

export enum DisputeStatus {
  Approved = 'APPROVED',
  AutoApproved = 'AUTO_APPROVED',
  Cancelled = 'CANCELLED',
  Disputed = 'DISPUTED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  SOlVed = 'SOlVED',
  Withdrawn = 'WITHDRAWN',
}

export type DisputeViewOutput = {
  __typename?: 'DisputeViewOutput'
  /** approveDate */
  approveDate?: Maybe<Scalars['Timestamp']['output']>
  clientAmount?: Maybe<Scalars['Float']['output']>
  contract?: Maybe<Contract>
  contractId: Scalars['Int']['output']
  contractorAmount?: Maybe<Scalars['Float']['output']>
  createdAt: Scalars['Timestamp']['output']
  /** currency */
  currency?: Maybe<ContractCurrency>
  /** id of dispute for escrow return request */
  disputeId?: Maybe<Scalars['Float']['output']>
  disputedTime?: Maybe<Scalars['Int']['output']>
  /** dueDate */
  dueDate?: Maybe<Scalars['Timestamp']['output']>
  /** fee */
  fee?: Maybe<Scalars['Float']['output']>
  /** The unique identifier of the dispute */
  id: Scalars['ID']['output']
  /** initiator first name */
  initiatorFirstName?: Maybe<Scalars['String']['output']>
  initiatorId?: Maybe<Scalars['Int']['output']>
  /** initiator last name */
  initiatorLastName?: Maybe<Scalars['String']['output']>
  /** initiator role */
  initiatorRole?: Maybe<Scalars['String']['output']>
  message?: Maybe<Scalars['String']['output']>
  milestones?: Maybe<Array<DisputeMilestone>>
  parentRequestId?: Maybe<Scalars['Int']['output']>
  resolutionDate?: Maybe<Scalars['Timestamp']['output']>
  /** The status resolution of the dispute */
  resolutionStatus?: Maybe<Scalars['String']['output']>
  resolutionTime?: Maybe<Scalars['Int']['output']>
  /** The status of the dispute */
  status: DisputeStatus
  subject?: Maybe<Scalars['String']['output']>
  ticketId?: Maybe<Scalars['Int']['output']>
  /** link to zendesk ticket for dispute */
  ticketLink?: Maybe<Scalars['String']['output']>
  total?: Maybe<Scalars['Float']['output']>
  transactions?: Maybe<Array<TransactionOutput>>
  /** The type of the dispute */
  type: Scalars['String']['output']
  updatedAt: Scalars['Timestamp']['output']
  workingWeek?: Maybe<Array<WorkingWeek>>
  workingWeeks?: Maybe<Array<WorkingWeekView>>
}

export enum EscrowContractTypeEnum {
  FixPrice = 'FIX_PRICE',
  Hourly = 'HOURLY',
  Milestone = 'MILESTONE',
}

export type FilterToTransactionList = {
  /** contractId */
  contractId?: InputMaybe<Scalars['Int']['input']>
  /** depositId */
  depositId?: InputMaybe<Scalars['Int']['input']>
  /** limit */
  limit?: InputMaybe<Scalars['Int']['input']>
  /** page */
  page?: InputMaybe<Scalars['Int']['input']>
}

export type FilterToWalletListInput = {
  /** contractId */
  contractId?: InputMaybe<Scalars['Int']['input']>
  /** limit */
  limit?: InputMaybe<Scalars['Int']['input']>
  /** page */
  page?: InputMaybe<Scalars['Int']['input']>
}

export type FindContractAssigneesAddressesOutput = {
  __typename?: 'FindContractAssigneesAddressesOutput'
  clientAddress: Address
  contractorAddress: Address
}

export type FindContractCompaniesOutput = {
  __typename?: 'FindContractCompaniesOutput'
  clientCompany?: Maybe<Company>
  contractorCompany?: Maybe<Company>
}

export type GetDepositParamsDto = {
  contractId?: InputMaybe<Scalars['Int']['input']>
  depositId?: InputMaybe<Scalars['Int']['input']>
  milestoneIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type GetUserEscrowAddressOutput = {
  __typename?: 'GetUserEscrowAddressOutput'
  userEscrowAddress?: Maybe<Array<UserEscrowAddress>>
}

export type GetUserEscrowAndSaltOutput = {
  __typename?: 'GetUserEscrowAndSaltOutput'
  escrowAddress?: Maybe<Scalars['String']['output']>
  escrowSalt?: Maybe<Scalars['String']['output']>
}

export type LastWeekLimitChange = {
  __typename?: 'LastWeekLimitChange'
  createdAt?: Maybe<Scalars['Timestamp']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  limit?: Maybe<Scalars['Int']['output']>
  userId: Scalars['String']['output']
}

export type MessageCompleteDto = {
  __typename?: 'MessageCompleteDto'
  message?: Maybe<Scalars['String']['output']>
}

export type MessageOutputDto = {
  __typename?: 'MessageOutputDto'
  message?: Maybe<Scalars['String']['output']>
}

export enum MilestoneStatus {
  Active = 'ACTIVE',
  ActiveFunded = 'ACTIVE_FUNDED',
  Approved = 'APPROVED',
  ChangesRequested = 'CHANGES_REQUESTED',
  Claimed = 'CLAIMED',
  Closed = 'CLOSED',
  Disputed = 'DISPUTED',
  Idle = 'IDLE',
  Onhold = 'ONHOLD',
  PendingReview = 'PENDING_REVIEW',
}

export type MilestoneStatusChange = {
  __typename?: 'MilestoneStatusChange'
  createdAt: Scalars['Timestamp']['output']
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  milestoneId: Scalars['ID']['output']
  /** The status of the contract */
  status: Scalars['String']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  acceptContract: Contract
  activateMilestoneByIds: Array<ContractMilestone>
  addTransaction: TransactionWrap
  approveDispute: Dispute
  approveWork: ApproveWorkResponse
  approvedMilestoneByIds: Array<ContractMilestone>
  cancelDispute: Dispute
  checkTransaction: Transaction
  claimFunds: MessageCompleteDto
  claimedMilestoneByIds: Array<ContractMilestone>
  closeContract: Contract
  createAddress: Address
  createCompany: Company
  createContract: Contract
  createContractMilestone: ContractMilestone
  createDeposit: ViewDepositOutput
  createDisputeHourlyOnlyHours: DisputeViewOutput
  createProfile: Profile
  createUserByEmail: User
  createUserEscrowAddress: UserEscrowAddress
  createWallet: Wallet
  declineContract: Contract
  deleteWorkingDay: WorkingDay
  markAllAsSeen: Array<Notification>
  registerUser: User
  rejectDispute: DisputeViewOutput
  rejectWork: ApproveWorkResponse
  removeContract: Scalars['Boolean']['output']
  removeDispute: Dispute
  removeMyProfile: Profile
  removeMyUser: User
  removeWallet: Wallet
  saveUserWallet: UserWallet
  sendContract: Contract
  sendContractorDispute: Dispute
  sendDisputeRequest: Dispute
  sendRequestChange: RequestChange
  setActiveProfile: Profile
  setWorkingDay: WorkingDay
  submitWork: SubmitWork
  updateContract: Contract
  updateContractMilestone: ContractMilestone
  updateDispute: Dispute
  updateMyAddress: Address
  updateMyCompany: Company
  updateMyProfile: Profile
  updateMyUser: User
  updateNotification: Notification
  updateUserEscrowAddress: UserEscrowAddress
  updateWallet: Wallet
  updateWorkingWeek: WorkingWeek
}

export type MutationAcceptContractArgs = {
  data: AcceptContractDto
}

export type MutationActivateMilestoneByIdsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationAddTransactionArgs = {
  data: AddTransactionDataInput
}

export type MutationApproveDisputeArgs = {
  id: Scalars['Int']['input']
}

export type MutationApproveWorkArgs = {
  data: ApproveWorkInput
}

export type MutationApprovedMilestoneByIdsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationCancelDisputeArgs = {
  id: Scalars['Int']['input']
}

export type MutationCheckTransactionArgs = {
  hash: Scalars['String']['input']
}

export type MutationClaimFundsArgs = {
  data: GetDepositParamsDto
}

export type MutationClaimedMilestoneByIdsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationCloseContractArgs = {
  id: Scalars['ID']['input']
}

export type MutationCreateAddressArgs = {
  data: CreateAddressInput
}

export type MutationCreateCompanyArgs = {
  data: CreateCompanyInput
}

export type MutationCreateContractArgs = {
  data: CreateContractInput
}

export type MutationCreateContractMilestoneArgs = {
  contractId: Scalars['ID']['input']
  data: CreateContractMilestoneInput
}

export type MutationCreateDepositArgs = {
  data: CreateDepositParamsInput
}

export type MutationCreateDisputeHourlyOnlyHoursArgs = {
  data: CreateDisputeHourlyOnlyTimeInput
}

export type MutationCreateProfileArgs = {
  data: CreateProfileInput
}

export type MutationCreateUserByEmailArgs = {
  email: Scalars['String']['input']
}

export type MutationCreateUserEscrowAddressArgs = {
  data: CreateUserEscrowAddressInput
}

export type MutationCreateWalletArgs = {
  createWalletInput: CreateWalletInput
}

export type MutationDeclineContractArgs = {
  data: DeclineContractDto
}

export type MutationDeleteWorkingDayArgs = {
  id: Scalars['Int']['input']
}

export type MutationRejectDisputeArgs = {
  id: Scalars['Int']['input']
  message: Scalars['String']['input']
}

export type MutationRejectWorkArgs = {
  data: ApproveWorkInput
}

export type MutationRemoveContractArgs = {
  id: Scalars['ID']['input']
}

export type MutationRemoveDisputeArgs = {
  id: Scalars['Int']['input']
}

export type MutationRemoveWalletArgs = {
  id: Scalars['Int']['input']
}

export type MutationSaveUserWalletArgs = {
  wallet: SaveWalletInput
}

export type MutationSendContractArgs = {
  data: CreateContractSendDto
}

export type MutationSendContractorDisputeArgs = {
  data: SendDisputeContractorInput
}

export type MutationSendDisputeRequestArgs = {
  data: SendDisputeRequestInput
}

export type MutationSendRequestChangeArgs = {
  data: CreateRequestChangeInput
}

export type MutationSetActiveProfileArgs = {
  id: Scalars['Int']['input']
}

export type MutationSetWorkingDayArgs = {
  data: SetWorkingDayInput
}

export type MutationSubmitWorkArgs = {
  data: SubmitWorkInput
}

export type MutationUpdateContractArgs = {
  data: UpdateContractInput
  id: Scalars['ID']['input']
}

export type MutationUpdateContractMilestoneArgs = {
  data: UpdateContractMilestoneInput
  milestoneId: Scalars['ID']['input']
}

export type MutationUpdateDisputeArgs = {
  updateDisputeInput: UpdateDisputeInput
}

export type MutationUpdateMyAddressArgs = {
  data: UpdateAddressInput
}

export type MutationUpdateMyCompanyArgs = {
  data: UpdateCompanyInput
}

export type MutationUpdateMyProfileArgs = {
  data: UpdateProfileInput
}

export type MutationUpdateMyUserArgs = {
  data: UpdateUserInput
}

export type MutationUpdateNotificationArgs = {
  data: UpdateNotificationInput
  id: Scalars['ID']['input']
}

export type MutationUpdateUserEscrowAddressArgs = {
  data: CreateUserEscrowAddressInput
}

export type MutationUpdateWalletArgs = {
  id: Scalars['Int']['input']
  updateWalletInput: UpdateWalletInput
}

export type MutationUpdateWorkingWeekArgs = {
  data: UpdateWorkingWeekInput
  id: Scalars['Int']['input']
}

export type Notification = {
  __typename?: 'Notification'
  createdAt: Scalars['Timestamp']['output']
  data: Scalars['String']['output']
  /** Event type of the notification */
  event: Scalars['String']['output']
  /** The unique identifier of the notification */
  id: Scalars['ID']['output']
  /** Event type of the notification */
  status: Scalars['String']['output']
  /** Event type of the notification */
  type: Scalars['String']['output']
  /** User id */
  userId: Scalars['String']['output']
}

export type NotificationPaginated = {
  __typename?: 'NotificationPaginated'
  hasNextPage: Scalars['Boolean']['output']
  nodes?: Maybe<Array<Notification>>
  totalCount: Scalars['Int']['output']
}

export enum NotificationStatus {
  New = 'NEW',
  Seen = 'SEEN',
}

export enum NotificationType {
  Necessary = 'NECESSARY',
  Unnecessary = 'UNNECESSARY',
}

export type PaginationInput = {
  /** limit */
  limit?: InputMaybe<Scalars['Int']['input']>
  /** page */
  page?: InputMaybe<Scalars['Int']['input']>
}

export type Profile = {
  __typename?: 'Profile'
  address?: Maybe<Address>
  addressId?: Maybe<Scalars['Int']['output']>
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['Int']['output']>
  deleted?: Maybe<Scalars['Boolean']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the company */
  id?: Maybe<Scalars['ID']['output']>
  isActive?: Maybe<Scalars['Boolean']['output']>
  isVerified: Scalars['Boolean']['output']
  lastName?: Maybe<Scalars['String']['output']>
  middleName?: Maybe<Scalars['String']['output']>
  /** The role of the user */
  role?: Maybe<Scalars['String']['output']>
  /** User id */
  userId?: Maybe<Scalars['String']['output']>
}

export type Query = {
  __typename?: 'Query'
  checkContractBeforeClose: MessageOutputDto
  checkRoleByEmail: UserRolesOutputByEmail
  checkRoleForRegister: UserRolesOutput
  contract: ViewContract
  contracts: ContractPaginated
  disputeList: DisputeListViewOutputPaginated
  findAllWallets: Array<Wallet>
  findByEmail: User
  findById: User
  findByIds: Array<User>
  findCompaniesByContractId: FindContractCompaniesOutput
  findContractAssigneesAddresses: FindContractAssigneesAddressesOutput
  findMyAddress: Address
  findMyCompany: Company
  findMyProfile: Profile
  findMyUser: User
  findOneDispute: DisputeViewOutput
  findOneDisputeByWorkingWeekId: DisputeViewOutput
  findProfileById: Profile
  findWalletById: Wallet
  getActiveUserContracts: Array<Contract>
  getActivity: Array<Activity>
  getContractById: Contract
  getContractByReceiverEmail: Contract
  getDeposit: ViewDepositOutput
  getDepositData: Deposit
  getMilestoneByIds: Array<ContractMilestone>
  getMyTransactions: TransactionListPaginated
  getTransakData: Array<Transak>
  getUserEscrowAddress: GetUserEscrowAddressOutput
  getUserEscrowAndSalt: GetUserEscrowAndSaltOutput
  getUserWallets: Array<UserWallet>
  getWeekReport: WeekView
  notifications: NotificationPaginated
  transaction: Transaction
  transactionAmounts: TransactionAmountsOutput
  transactions: Array<TransactionList>
  weekReportList: Array<WeekView>
}

export type QueryCheckContractBeforeCloseArgs = {
  id: Scalars['ID']['input']
}

export type QueryCheckRoleByEmailArgs = {
  email: Scalars['String']['input']
}

export type QueryContractArgs = {
  id: Scalars['ID']['input']
}

export type QueryContractsArgs = {
  after?: Scalars['Int']['input']
  archived?: Scalars['Boolean']['input']
  filter?: InputMaybe<ContractFilterInput>
  limit?: Scalars['Int']['input']
  status?: InputMaybe<ContractStatus>
  useMilestones?: Scalars['Boolean']['input']
}

export type QueryDisputeListArgs = {
  filter: DisputeListParamsInput
}

export type QueryFindAllWalletsArgs = {
  filter: FilterToWalletListInput
}

export type QueryFindByEmailArgs = {
  email: Scalars['String']['input']
}

export type QueryFindByIdArgs = {
  id: Scalars['Int']['input']
}

export type QueryFindByIdsArgs = {
  ids: Array<Scalars['Int']['input']>
}

export type QueryFindCompaniesByContractIdArgs = {
  contractId: Scalars['Int']['input']
}

export type QueryFindContractAssigneesAddressesArgs = {
  contractId: Scalars['Int']['input']
}

export type QueryFindMyAddressArgs = {
  isCompanyProfile: Scalars['Boolean']['input']
}

export type QueryFindOneDisputeArgs = {
  id: Scalars['Int']['input']
}

export type QueryFindOneDisputeByWorkingWeekIdArgs = {
  workingWeekId: Scalars['Int']['input']
}

export type QueryFindProfileByIdArgs = {
  id: Scalars['Int']['input']
}

export type QueryFindWalletByIdArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetActivityArgs = {
  filter: ActivityFilterParams
}

export type QueryGetContractByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryGetContractByReceiverEmailArgs = {
  email: Scalars['String']['input']
}

export type QueryGetDepositArgs = {
  data: CreateDepositInput
}

export type QueryGetDepositDataArgs = {
  depositId: Scalars['Int']['input']
}

export type QueryGetMilestoneByIdsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type QueryGetMyTransactionsArgs = {
  filter?: InputMaybe<PaginationInput>
}

export type QueryGetUserEscrowAddressArgs = {
  clientId: Scalars['Int']['input']
}

export type QueryGetUserEscrowAndSaltArgs = {
  clientId: Scalars['Int']['input']
}

export type QueryGetWeekReportArgs = {
  id: Scalars['Int']['input']
}

export type QueryNotificationsArgs = {
  after?: Scalars['Int']['input']
  limit?: Scalars['Int']['input']
}

export type QueryTransactionArgs = {
  hash: Scalars['String']['input']
}

export type QueryTransactionAmountsArgs = {
  data: TransactionAmountsInput
}

export type QueryTransactionsArgs = {
  filter: FilterToTransactionList
}

export type QueryWeekReportListArgs = {
  contractId: Scalars['Int']['input']
}

export type RequestChange = {
  __typename?: 'RequestChange'
  budget: Scalars['Float']['output']
  createdAt: Scalars['Timestamp']['output']
  description: Scalars['String']['output']
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  /** The status of the request change */
  status: Scalars['String']['output']
  submitWorkId: Scalars['Int']['output']
}

export type SaveWalletInput = {
  contractType?: InputMaybe<EscrowContractTypeEnum>
  icon?: InputMaybe<Scalars['String']['input']>
  wallet?: InputMaybe<Scalars['String']['input']>
  walletType: WalletType
}

export type SendContract = {
  __typename?: 'SendContract'
  contractId: Scalars['Int']['output']
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  message: Scalars['String']['output']
  /** The status of the send contract */
  sendStatus: Scalars['String']['output']
  userId: Scalars['Int']['output']
}

export type SendDisputeContractorInput = {
  changeRequestId: Scalars['Int']['input']
  contractId: Scalars['Int']['input']
  message?: InputMaybe<Scalars['String']['input']>
  milestoneIds?: InputMaybe<Array<Scalars['Int']['input']>>
  workingWeekIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type SendDisputeRequestInput = {
  contractId: Scalars['Int']['input']
  message?: InputMaybe<Scalars['String']['input']>
  milestoneIds?: InputMaybe<Array<Scalars['Int']['input']>>
  parentRequestId?: InputMaybe<Scalars['Int']['input']>
  workingWeekIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type SetWorkingDayInput = {
  amount?: InputMaybe<Scalars['Float']['input']>
  day: Scalars['Timestamp']['input']
  description?: InputMaybe<Scalars['String']['input']>
  workingTime: Scalars['Int']['input']
  /** Contract Id */
  workingWeekId: Scalars['Int']['input']
}

export type SubmitWork = {
  __typename?: 'SubmitWork'
  budget: Scalars['Float']['output']
  contractId?: Maybe<Scalars['Int']['output']>
  createdAt: Scalars['Timestamp']['output']
  /** The activation status of the contract */
  currency?: Maybe<ContractCurrency>
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  milestoneId?: Maybe<Scalars['Int']['output']>
  requestChanges: Array<RequestChange>
  /** The status of the submit work */
  status: Scalars['String']['output']
  workingWeekId?: Maybe<Scalars['Int']['output']>
}

export type SubmitWorkInput = {
  budget?: InputMaybe<Scalars['Float']['input']>
  contractId?: InputMaybe<Scalars['Int']['input']>
  /** The activation status of the contract */
  currency: ContractCurrency
  description?: InputMaybe<Scalars['String']['input']>
  milestoneId?: InputMaybe<Scalars['Int']['input']>
  workingWeekId?: InputMaybe<Scalars['Int']['input']>
}

export type Transaction = {
  __typename?: 'Transaction'
  amount?: Maybe<Scalars['Float']['output']>
  clientAmount?: Maybe<Scalars['Float']['output']>
  contract: Contract
  contractId: Contract
  contractorAmount?: Maybe<Scalars['Float']['output']>
  createdAt: Scalars['Timestamp']['output']
  /** The activation status of the contract */
  currency: Scalars['String']['output']
  depositId?: Maybe<Scalars['Int']['output']>
  descriptions: Array<TransactionDescription>
  disputeId?: Maybe<Scalars['Int']['output']>
  fee?: Maybe<Scalars['Float']['output']>
  hash: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The status of the contract */
  status: Scalars['String']['output']
  transactionId?: Maybe<Scalars['String']['output']>
  /** The activation status of the contract */
  transactionType: Scalars['String']['output']
  userAccount?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['Int']['output']>
}

export type TransactionAmountsInput = {
  amount: Scalars['Float']['input']
  transactionFeeType: TransactionFeeType
  wallet?: InputMaybe<Scalars['String']['input']>
}

export type TransactionAmountsOutput = {
  __typename?: 'TransactionAmountsOutput'
  amountWithFee: Scalars['Float']['output']
  amountWithoutFee: Scalars['Float']['output']
  fee: Scalars['Float']['output']
  percentage: Scalars['Float']['output']
}

export type TransactionDescription = {
  __typename?: 'TransactionDescription'
  contractType: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  transaction: Transaction
  transactionId: Scalars['Int']['output']
}

export enum TransactionFeeType {
  Claim = 'CLAIM',
  Deposit = 'DEPOSIT',
}

export type TransactionList = {
  __typename?: 'TransactionList'
  amount?: Maybe<Scalars['Float']['output']>
  assignedUsers?: Maybe<Array<AssignedUsers>>
  contractId: Scalars['Int']['output']
  contractName?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Timestamp']['output']
  /** The activation status of the contract */
  currency: Scalars['String']['output']
  depositId?: Maybe<Scalars['Int']['output']>
  descriptions: Array<TransactionDescription>
  fee?: Maybe<Scalars['Float']['output']>
  hash: Scalars['ID']['output']
  mainTransaction?: Maybe<TransactionList>
  /** The status of the contract */
  status: Scalars['String']['output']
  transactionId?: Maybe<Scalars['String']['output']>
  transactionLink?: Maybe<Scalars['String']['output']>
  /** The activation status of the contract */
  transactionType: Scalars['String']['output']
  userAccount?: Maybe<Scalars['String']['output']>
}

export type TransactionListPaginated = {
  __typename?: 'TransactionListPaginated'
  hasNextPage?: Maybe<Scalars['Boolean']['output']>
  nodes: Array<TransactionList>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type TransactionOutput = {
  __typename?: 'TransactionOutput'
  amount?: Maybe<Scalars['Float']['output']>
  contractId: Scalars['Int']['output']
  createdAt: Scalars['Timestamp']['output']
  /** The activation status of the contract */
  currency: Scalars['String']['output']
  depositId?: Maybe<Scalars['Int']['output']>
  fee?: Maybe<Scalars['Float']['output']>
  hash: Scalars['ID']['output']
  link?: Maybe<Scalars['String']['output']>
  /** The status of the contract */
  status: Scalars['String']['output']
  transactionId?: Maybe<Scalars['String']['output']>
  /** The activation status of the contract */
  transactionType: Scalars['String']['output']
  userAccount?: Maybe<Scalars['String']['output']>
}

export enum TransactionType {
  AdminApprove = 'ADMIN_APPROVE',
  Approve = 'APPROVE',
  CancelReturn = 'CANCEL_RETURN',
  Claim = 'CLAIM',
  ClaimAll = 'CLAIM_ALL',
  ClaimFee = 'CLAIM_FEE',
  CoverageFee = 'COVERAGE_FEE',
  DepositEscrow = 'DEPOSIT_ESCROW',
  DepositPrepayment = 'DEPOSIT_PREPAYMENT',
  DisputeCreated = 'DISPUTE_CREATED',
  DisputeResolved = 'DISPUTE_RESOLVED',
  PartialDeposit = 'PARTIAL_DEPOSIT',
  PartialWithdrawal = 'PARTIAL_WITHDRAWAL',
  Payment = 'PAYMENT',
  Refill = 'REFILL',
  ReturnApprove = 'RETURN_APPROVE',
  ReturnRequested = 'RETURN_REQUESTED',
  Submit = 'SUBMIT',
  Withdraw = 'WITHDRAW',
}

export type TransactionWrap = {
  __typename?: 'TransactionWrap'
  amount?: Maybe<Scalars['Float']['output']>
  clientAmount?: Maybe<Scalars['Float']['output']>
  contract: Contract
  contractId: Contract
  contractorAmount?: Maybe<Scalars['Float']['output']>
  createdAt: Scalars['Timestamp']['output']
  /** The activation status of the contract */
  currency: Scalars['String']['output']
  depositId?: Maybe<Scalars['Int']['output']>
  descriptions: Array<TransactionDescription>
  disputeId?: Maybe<Scalars['Int']['output']>
  fee?: Maybe<Scalars['Float']['output']>
  hash: Scalars['String']['output']
  id: Scalars['ID']['output']
  mainTransaction?: Maybe<TransactionWrap>
  /** The status of the contract */
  status: Scalars['String']['output']
  transactionId?: Maybe<Scalars['String']['output']>
  transactionLink?: Maybe<Scalars['String']['output']>
  /** The activation status of the contract */
  transactionType: Scalars['String']['output']
  userAccount?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['Int']['output']>
}

export type Transak = {
  __typename?: 'Transak'
  amountPaid?: Maybe<Scalars['Float']['output']>
  conversionPrice?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['Timestamp']['output']>
  cryptoAmount?: Maybe<Scalars['Float']['output']>
  cryptoCurrency?: Maybe<Scalars['String']['output']>
  deletedAt?: Maybe<Scalars['Timestamp']['output']>
  fiatAmount?: Maybe<Scalars['Float']['output']>
  fiatAmountInUsd?: Maybe<Scalars['Float']['output']>
  fiatCurrency?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  /** The isBuyOrSell of the transak */
  isBuyOrSell?: Maybe<Scalars['String']['output']>
  network?: Maybe<Scalars['String']['output']>
  orderId?: Maybe<Scalars['String']['output']>
  paymentId?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  totalFeeInFiat?: Maybe<Scalars['Float']['output']>
  transakId?: Maybe<Scalars['String']['output']>
  transakUserId?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['Timestamp']['output']>
  walletAddress?: Maybe<Scalars['String']['output']>
}

export type UpdateAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  isCompanyProfile: Scalars['Boolean']['input']
  line1?: InputMaybe<Scalars['String']['input']>
  line2?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCompanyInput = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['String']['input']>
}

export type UpdateContractInput = {
  activationStatus?: InputMaybe<ContractActivationStatus>
  archived?: InputMaybe<Scalars['Boolean']['input']>
  budget?: InputMaybe<Scalars['Float']['input']>
  category?: InputMaybe<Scalars['String']['input']>
  contractName?: InputMaybe<Scalars['String']['input']>
  /** The activation status of the contract */
  currency?: InputMaybe<ContractCurrency>
  dueDate?: InputMaybe<Scalars['Timestamp']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  escrowContractId?: InputMaybe<Scalars['String']['input']>
  initialPrepayment?: InputMaybe<Scalars['Float']['input']>
  /** Week limit. Use when contract have type hourly rate */
  limit?: InputMaybe<Scalars['Int']['input']>
  limitType?: InputMaybe<ContractLimitType>
  milestones?: InputMaybe<Array<UpdateContractMilestoneInput>>
  paymentType?: InputMaybe<ContractPaymentType>
  prepayment?: InputMaybe<Scalars['Float']['input']>
  projectRequirements?: InputMaybe<Scalars['String']['input']>
  /** Rate. Use when contract have type hourly rate */
  rate?: InputMaybe<Scalars['Float']['input']>
  /** Reference id */
  referenceId?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<ContractStatus>
  type?: InputMaybe<ContractType>
  useMilestones?: InputMaybe<Scalars['Boolean']['input']>
  verified?: InputMaybe<Scalars['Boolean']['input']>
  /** Week limit. Use when contract have type hourly rate */
  weekLimit?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateContractMilestoneInput = {
  budget?: InputMaybe<Scalars['Float']['input']>
  /** UTC or Timestamp, example: "2023-12-26T23:42:00" or 1703634120000 */
  dueDate?: InputMaybe<Scalars['Timestamp']['input']>
  /** Escrow milestone id */
  escrowMilestoneId?: InputMaybe<Scalars['ID']['input']>
  /** Milestone id */
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<MilestoneStatus>
}

export type UpdateDisputeInput = {
  contractId?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['Int']['input']
  message?: InputMaybe<Scalars['String']['input']>
  milestoneIds?: InputMaybe<Array<Scalars['Int']['input']>>
  parentRequestId?: InputMaybe<Scalars['Int']['input']>
  /** The status of the dispute */
  status: Scalars['String']['input']
  workingWeekIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type UpdateNotificationInput = {
  status?: InputMaybe<NotificationStatus>
  type?: InputMaybe<NotificationType>
}

export type UpdateProfileInput = {
  country?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  isVerified?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  middleName?: InputMaybe<Scalars['String']['input']>
}

export type UpdateUserInput = {
  companyName?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  escrowAddress?: InputMaybe<Scalars['String']['input']>
  escrowSalt?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  is2FAEnabled?: InputMaybe<Scalars['Boolean']['input']>
  isEmailVerified?: InputMaybe<Scalars['Boolean']['input']>
  isVerified?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<UserRole>
}

export type UpdateWalletInput = {
  /** contractId */
  contractId?: InputMaybe<Scalars['Int']['input']>
  /** userId */
  userId?: InputMaybe<Scalars['Int']['input']>
  /** Wallet */
  wallet?: InputMaybe<Scalars['String']['input']>
}

export type UpdateWorkingWeekInput = {
  description?: InputMaybe<Scalars['String']['input']>
  rate?: InputMaybe<Scalars['Float']['input']>
  status?: InputMaybe<WorkingWeekStatus>
  subStatus?: InputMaybe<WorkingWeekSubStatus>
}

export type User = {
  __typename?: 'User'
  companyName?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  deleted?: Maybe<Scalars['Boolean']['output']>
  email: Scalars['String']['output']
  escrowAddress?: Maybe<Scalars['String']['output']>
  escrowSalt?: Maybe<Scalars['String']['output']>
  firebaseId?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the user */
  id: Scalars['ID']['output']
  is2FAEnabled?: Maybe<Scalars['Boolean']['output']>
  isEmailVerified: Scalars['Boolean']['output']
  isVerified: Scalars['Boolean']['output']
  lastName?: Maybe<Scalars['String']['output']>
  profile?: Maybe<Profile>
  /** The role of the user */
  role?: Maybe<Scalars['String']['output']>
}

export type UserEscrowAddress = {
  __typename?: 'UserEscrowAddress'
  /** Type of contract */
  contractType?: Maybe<Scalars['String']['output']>
  /** User Escrow Address */
  escrowAddress?: Maybe<Scalars['String']['output']>
  /** User Escrow Salt, being used to encode freelancer data that being sent to blockchain */
  escrowSalt?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the user escrow address entity */
  id?: Maybe<Scalars['ID']['output']>
  /** User id */
  userId?: Maybe<Scalars['String']['output']>
}

export enum UserRole {
  Agency = 'AGENCY',
  Client = 'CLIENT',
  Freelancer = 'FREELANCER',
}

export type UserRolesOutput = {
  __typename?: 'UserRolesOutput'
  roles?: Maybe<Array<UserRole>>
}

export type UserRolesOutputByEmail = {
  __typename?: 'UserRolesOutputByEmail'
  email?: Maybe<Scalars['String']['output']>
  role?: Maybe<UserRole>
  userId?: Maybe<Scalars['Int']['output']>
}

export type UserWallet = {
  __typename?: 'UserWallet'
  contractType?: Maybe<Scalars['String']['output']>
  contractWallet?: Maybe<Array<Wallet>>
  icon?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the company */
  id: Scalars['ID']['output']
  profileId?: Maybe<Scalars['Int']['output']>
  userId?: Maybe<Scalars['Int']['output']>
  wallet?: Maybe<Scalars['String']['output']>
  walletType?: Maybe<Scalars['String']['output']>
}

export type ViewContract = {
  __typename?: 'ViewContract'
  activationHistory: Array<ActivationHistory>
  /** The activation status of the contract */
  activationStatus: Scalars['String']['output']
  activity: Array<Activity>
  archived: Scalars['Boolean']['output']
  assignUsers: Array<ContractUserAssign>
  budget?: Maybe<Scalars['Float']['output']>
  category?: Maybe<Scalars['String']['output']>
  contextStatus?: Maybe<ContractSubStatusType>
  /** The hash of the content contract */
  contractName: Scalars['String']['output']
  createdAt: Scalars['Timestamp']['output']
  /** The activation status of the contract */
  currency: Scalars['String']['output']
  descriptions: Array<ContractDescription>
  dispute: Array<Dispute>
  /** UTC or Timestamp, example: "2023-12-26T23:42:00" or 1703634120000 */
  dueDate?: Maybe<Scalars['Timestamp']['output']>
  email: Scalars['String']['output']
  endDate?: Maybe<Scalars['Timestamp']['output']>
  escrowContractId?: Maybe<Scalars['String']['output']>
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  initialPrepayment?: Maybe<Scalars['Float']['output']>
  isRateIncrease?: Maybe<ChangeStatus>
  isRateWillUpdate?: Maybe<Scalars['Boolean']['output']>
  isWeekLimitIncrease?: Maybe<ChangeStatus>
  isWeekLimitWillUpdate?: Maybe<Scalars['Boolean']['output']>
  lastWeekLimitChange?: Maybe<LastWeekLimitChange>
  limit?: Maybe<Scalars['Int']['output']>
  limitType?: Maybe<Scalars['String']['output']>
  milestones: Array<ContractMilestone>
  paymentType: Scalars['String']['output']
  /** Escrow contract ID in smart contract */
  prepayment?: Maybe<Scalars['Float']['output']>
  projectRequirements?: Maybe<Scalars['String']['output']>
  rate?: Maybe<Scalars['Float']['output']>
  rates: Array<ContractRate>
  /** Reference id */
  referenceId?: Maybe<Scalars['String']['output']>
  sendContract: Array<SendContract>
  /** The status of the contract */
  status: Scalars['String']['output']
  statusHistory?: Maybe<Array<ContactStatusChange>>
  submitWork: Array<SubmitWork>
  type: Scalars['String']['output']
  useMilestones: Scalars['Boolean']['output']
  verified: Scalars['Boolean']['output']
  weekLimitInfo: Array<WorkingWeekLimit>
  workingWeek: Array<WorkingWeek>
}

export type ViewDepositOutput = {
  __typename?: 'ViewDepositOutput'
  amountToClaim: Scalars['Float']['output']
  amountToWithdraw: Scalars['Float']['output']
  availableAmount: Scalars['Float']['output']
  contractBudget: Scalars['Float']['output']
  depositId: Scalars['Int']['output']
  disputeResolveAmount: Scalars['Float']['output']
  freelancerSecret?: Maybe<Scalars['String']['output']>
  freelancerSecretHash: Scalars['String']['output']
  inEscrow: Scalars['Float']['output']
  inEscrowRemainder: Scalars['Float']['output']
  received: Scalars['Float']['output']
}

export type Wallet = {
  __typename?: 'Wallet'
  /** contractId */
  contractId?: Maybe<Scalars['Int']['output']>
  /** The unique identifier of the contract */
  id: Scalars['ID']['output']
  /** userId */
  userId?: Maybe<Scalars['Int']['output']>
  userWallet?: Maybe<UserWallet>
  /** Wallet */
  wallet: Scalars['String']['output']
}

export enum WalletType {
  Embedded = 'EMBEDDED',
  External = 'EXTERNAL',
}

export type WeekDayView = {
  __typename?: 'WeekDayView'
  amount?: Maybe<Scalars['Float']['output']>
  day: Scalars['Timestamp']['output']
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Use to understand if day is editable or not */
  isEditable?: Maybe<Scalars['Boolean']['output']>
  /** Use when week start before contract */
  isEnabled?: Maybe<Scalars['Boolean']['output']>
  workingTime: Scalars['Int']['output']
  workingWeekId: Scalars['Int']['output']
}

export type WeekView = {
  __typename?: 'WeekView'
  contractId: Scalars['Int']['output']
  description?: Maybe<Scalars['String']['output']>
  disputes?: Maybe<Array<Dispute>>
  end?: Maybe<Scalars['Timestamp']['output']>
  escrowWeekId?: Maybe<Scalars['Int']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  limit?: Maybe<Scalars['Int']['output']>
  rate?: Maybe<Scalars['Float']['output']>
  /** Total amount for a week after dispute */
  resolutionBudget?: Maybe<Scalars['Float']['output']>
  /** Total time for a week after dispute */
  resolutionTime?: Maybe<Scalars['Int']['output']>
  start?: Maybe<Scalars['Timestamp']['output']>
  /** The status of the contract */
  status: Scalars['String']['output']
  subStatus?: Maybe<Scalars['String']['output']>
  submitWork: Array<SubmitWork>
  /** Total amount for a week */
  totalBudget: Scalars['Float']['output']
  /** Total time for a week */
  totalTime: Scalars['Int']['output']
  transactions?: Maybe<Array<TransactionOutput>>
  workingDays: Array<WeekDayView>
}

export type WorkingDay = {
  __typename?: 'WorkingDay'
  amount?: Maybe<Scalars['Float']['output']>
  day: Scalars['Timestamp']['output']
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  workingTime: Scalars['Int']['output']
  workingWeekId: Scalars['Int']['output']
}

export type WorkingWeek = {
  __typename?: 'WorkingWeek'
  contractId: Scalars['Int']['output']
  description?: Maybe<Scalars['String']['output']>
  disputes?: Maybe<Array<Dispute>>
  end?: Maybe<Scalars['Timestamp']['output']>
  escrowWeekId?: Maybe<Scalars['Int']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  limit?: Maybe<Scalars['Int']['output']>
  rate?: Maybe<Scalars['Float']['output']>
  start?: Maybe<Scalars['Timestamp']['output']>
  /** The status of the contract */
  status: Scalars['String']['output']
  subStatus?: Maybe<Scalars['String']['output']>
  submitWork: Array<SubmitWork>
  workingDays: Array<WeekDayView>
}

export type WorkingWeekLimit = {
  __typename?: 'WorkingWeekLimit'
  contractId: Scalars['Int']['output']
  createdAt: Scalars['Timestamp']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  limit?: Maybe<Scalars['Int']['output']>
  /** The status of the contract */
  status: WorkingWeekLimitStatus
  userId?: Maybe<Scalars['Int']['output']>
}

export enum WorkingWeekLimitStatus {
  Current = 'CURRENT',
  Draft = 'DRAFT',
  Future = 'FUTURE',
  Previous = 'PREVIOUS',
}

export enum WorkingWeekStatus {
  ApprovedAndPaid = 'APPROVED_AND_PAID',
  AutoApproved = 'AUTO_APPROVED',
  AwaitingPayment = 'AWAITING_PAYMENT',
  Completed = 'COMPLETED',
  Disputed = 'DISPUTED',
  InProgress = 'IN_PROGRESS',
  PendingReview = 'PENDING_REVIEW',
}

export enum WorkingWeekSubStatus {
  AutoapprovedAfterNonPaid = 'AUTOAPPROVED_AFTER_NON_PAID',
  AutoapprovedAfterPartlyPaid = 'AUTOAPPROVED_AFTER_PARTLY_PAID',
  AutoapprovedAndNonPaid = 'AUTOAPPROVED_AND_NON_PAID',
  AutoapprovedAndPaid = 'AUTOAPPROVED_AND_PAID',
  AutoapprovedAndPartlyPaid = 'AUTOAPPROVED_AND_PARTLY_PAID',
}

export type WorkingWeekView = {
  __typename?: 'WorkingWeekView'
  contractId: Scalars['Int']['output']
  description?: Maybe<Scalars['String']['output']>
  disputes?: Maybe<Array<Dispute>>
  end?: Maybe<Scalars['Timestamp']['output']>
  escrowWeekId?: Maybe<Scalars['Int']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  limit?: Maybe<Scalars['Int']['output']>
  rate?: Maybe<Scalars['Float']['output']>
  start?: Maybe<Scalars['Timestamp']['output']>
  /** The status of the contract */
  status: Scalars['String']['output']
  subStatus?: Maybe<Scalars['String']['output']>
  submitWork: Array<SubmitWork>
  totalTime?: Maybe<Scalars['Int']['output']>
  workingDays: Array<WeekDayView>
}

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentUserQuery = {
  __typename?: 'Query'
  findMyUser: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    country?: string | null
    role?: string | null
    isVerified: boolean
    isEmailVerified: boolean
    is2FAEnabled?: boolean | null
  }
}

export type UserFieldsFragment = {
  __typename?: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  country?: string | null
  role?: string | null
  isVerified: boolean
  isEmailVerified: boolean
  is2FAEnabled?: boolean | null
}

export type RegisterUserMutationVariables = Exact<{ [key: string]: never }>

export type RegisterUserMutation = {
  __typename?: 'Mutation'
  registerUser: { __typename?: 'User'; email: string }
}

export type RemoveRegisterMutationVariables = Exact<{ [key: string]: never }>

export type RemoveRegisterMutation = {
  __typename?: 'Mutation'
  removeMyUser: { __typename?: 'User'; email: string }
}

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateMyUser: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    country?: string | null
    role?: string | null
    isVerified: boolean
    isEmailVerified: boolean
    is2FAEnabled?: boolean | null
  }
}

export type AcceptContractMutationVariables = Exact<{
  data: AcceptContractDto
}>

export type AcceptContractMutation = {
  __typename?: 'Mutation'
  acceptContract: { __typename?: 'Contract'; id: string; status: string }
}

export type ApproveWorkMutationVariables = Exact<{
  submitWorkId?: InputMaybe<Scalars['Int']['input']>
  requestChangeId?: InputMaybe<Scalars['Int']['input']>
  workingWeekId?: InputMaybe<Scalars['Int']['input']>
}>

export type ApproveWorkMutation = {
  __typename?: 'Mutation'
  approveWork: { __typename?: 'ApproveWorkResponse'; message: string }
}

export type CheckContractBeforeCloseQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CheckContractBeforeCloseQuery = {
  __typename?: 'Query'
  checkContractBeforeClose: {
    __typename?: 'MessageOutputDto'
    message?: string | null
  }
}

export type CheckRoleByEmailQueryVariables = Exact<{
  email: Scalars['String']['input']
}>

export type CheckRoleByEmailQuery = {
  __typename?: 'Query'
  checkRoleByEmail: {
    __typename?: 'UserRolesOutputByEmail'
    userId?: number | null
    role?: UserRole | null
    email?: string | null
  }
}

export type CloseContractMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CloseContractMutation = {
  __typename?: 'Mutation'
  closeContract: {
    __typename?: 'Contract'
    id: string
    status: string
    email: string
  }
}

export type CreateContractMutationVariables = Exact<{
  data: CreateContractInput
}>

export type CreateContractMutation = {
  __typename?: 'Mutation'
  createContract: {
    __typename?: 'Contract'
    id: string
    status: string
    contractName: string
    email: string
    projectRequirements?: string | null
    category?: string | null
    type: string
    budget?: number | null
    dueDate?: any | null
    rate?: number | null
    prepayment?: number | null
    limit?: number | null
    archived: boolean
    limitType?: string | null
    paymentType: string
    activationStatus: string
    currency: string
    escrowContractId?: string | null
    milestones: Array<{
      __typename?: 'ContractMilestone'
      id: string
      name: string
      budget: number
      dueDate?: any | null
      escrowMilestoneId?: string | null
      status: string
      submitWork: Array<{
        __typename?: 'SubmitWork'
        id: string
        status: string
        contractId?: number | null
        description?: string | null
        budget: number
        currency?: ContractCurrency | null
        createdAt: any
        requestChanges: Array<{
          __typename?: 'RequestChange'
          id: string
          submitWorkId: number
          description: string
          budget: number
          createdAt: any
        }>
      }>
    }>
    transactions: Array<{ __typename?: 'Transaction'; hash: string }>
    activationHistory: Array<{
      __typename?: 'ActivationHistory'
      id: string
      activationStatus: ContractActivationStatus
      createdAt: any
    }>
    assignUsers: Array<{
      __typename?: 'ContractUserAssign'
      id: string
      userId?: string | null
      role: string
      email?: string | null
      firstName?: string | null
      lastName?: string | null
    }>
    sendContract: Array<{
      __typename?: 'SendContract'
      id: string
      contractId: number
      userId: number
      sendStatus: string
    }>
    descriptions: Array<{
      __typename?: 'ContractDescription'
      id: string
      contractId: number
      description?: string | null
      reason: string
    }>
  }
}

export type CreateMilestoneMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
  data: CreateContractMilestoneInput
}>

export type CreateMilestoneMutation = {
  __typename?: 'Mutation'
  createContractMilestone: {
    __typename?: 'ContractMilestone'
    id: string
    name: string
    budget: number
    dueDate?: any | null
    escrowMilestoneId?: string | null
    status: string
    submitWork: Array<{
      __typename?: 'SubmitWork'
      id: string
      status: string
      contractId?: number | null
      description?: string | null
      budget: number
      currency?: ContractCurrency | null
      createdAt: any
      requestChanges: Array<{
        __typename?: 'RequestChange'
        id: string
        submitWorkId: number
        description: string
        budget: number
        createdAt: any
      }>
    }>
  }
}

export type DeclineContractMutationVariables = Exact<{
  data: DeclineContractDto
}>

export type DeclineContractMutation = {
  __typename?: 'Mutation'
  declineContract: { __typename?: 'Contract'; id: string }
}

export type EditContractMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateContractInput
}>

export type EditContractMutation = {
  __typename?: 'Mutation'
  updateContract: {
    __typename?: 'Contract'
    id: string
    status: string
    contractName: string
    email: string
    projectRequirements?: string | null
    category?: string | null
    type: string
    budget?: number | null
    dueDate?: any | null
    rate?: number | null
    prepayment?: number | null
    limit?: number | null
    archived: boolean
    limitType?: string | null
    paymentType: string
    activationStatus: string
    currency: string
    escrowContractId?: string | null
    milestones: Array<{
      __typename?: 'ContractMilestone'
      id: string
      name: string
      budget: number
      dueDate?: any | null
      escrowMilestoneId?: string | null
      status: string
      submitWork: Array<{
        __typename?: 'SubmitWork'
        id: string
        status: string
        contractId?: number | null
        description?: string | null
        budget: number
        currency?: ContractCurrency | null
        createdAt: any
        requestChanges: Array<{
          __typename?: 'RequestChange'
          id: string
          submitWorkId: number
          description: string
          budget: number
          createdAt: any
        }>
      }>
    }>
    transactions: Array<{ __typename?: 'Transaction'; hash: string }>
    activationHistory: Array<{
      __typename?: 'ActivationHistory'
      id: string
      activationStatus: ContractActivationStatus
      createdAt: any
    }>
    assignUsers: Array<{
      __typename?: 'ContractUserAssign'
      id: string
      userId?: string | null
      role: string
      email?: string | null
      firstName?: string | null
      lastName?: string | null
    }>
    sendContract: Array<{
      __typename?: 'SendContract'
      id: string
      contractId: number
      userId: number
      sendStatus: string
    }>
    descriptions: Array<{
      __typename?: 'ContractDescription'
      id: string
      contractId: number
      description?: string | null
      reason: string
    }>
  }
}

export type SubmitWorkItemFragment = {
  __typename?: 'SubmitWork'
  id: string
  status: string
  contractId?: number | null
  description?: string | null
  budget: number
  currency?: ContractCurrency | null
  createdAt: any
  requestChanges: Array<{
    __typename?: 'RequestChange'
    id: string
    submitWorkId: number
    description: string
    budget: number
    createdAt: any
  }>
}

export type MilestoneItemFragment = {
  __typename?: 'ContractMilestone'
  id: string
  name: string
  budget: number
  dueDate?: any | null
  escrowMilestoneId?: string | null
  status: string
  submitWork: Array<{
    __typename?: 'SubmitWork'
    id: string
    status: string
    contractId?: number | null
    description?: string | null
    budget: number
    currency?: ContractCurrency | null
    createdAt: any
    requestChanges: Array<{
      __typename?: 'RequestChange'
      id: string
      submitWorkId: number
      description: string
      budget: number
      createdAt: any
    }>
  }>
}

export type TransactionItemFragment = {
  __typename?: 'Transaction'
  hash: string
}

export type ActivationHistoryItemFragment = {
  __typename?: 'ActivationHistory'
  id: string
  activationStatus: ContractActivationStatus
  createdAt: any
}

export type ContractUserAssignItemFragment = {
  __typename?: 'ContractUserAssign'
  id: string
  userId?: string | null
  role: string
  email?: string | null
  firstName?: string | null
  lastName?: string | null
}

export type SendContractItemFragment = {
  __typename?: 'SendContract'
  id: string
  contractId: number
  userId: number
  sendStatus: string
}

export type ContractDescriptionItemFragment = {
  __typename?: 'ContractDescription'
  id: string
  contractId: number
  description?: string | null
  reason: string
}

export type ViewContractItemFragment = {
  __typename?: 'ViewContract'
  id: string
  status: string
  contractName: string
  email: string
  projectRequirements?: string | null
  category?: string | null
  type: string
  budget?: number | null
  dueDate?: any | null
  createdAt: any
  referenceId?: string | null
  initialPrepayment?: number | null
  rate?: number | null
  prepayment?: number | null
  isRateIncrease?: ChangeStatus | null
  isWeekLimitIncrease?: ChangeStatus | null
  isWeekLimitWillUpdate?: boolean | null
  limit?: number | null
  archived: boolean
  limitType?: string | null
  paymentType: string
  activationStatus: string
  currency: string
  useMilestones: boolean
  escrowContractId?: string | null
  contextStatus?: ContractSubStatusType | null
  milestones: Array<{
    __typename?: 'ContractMilestone'
    id: string
    name: string
    budget: number
    dueDate?: any | null
    escrowMilestoneId?: string | null
    status: string
    submitWork: Array<{
      __typename?: 'SubmitWork'
      id: string
      status: string
      contractId?: number | null
      description?: string | null
      budget: number
      currency?: ContractCurrency | null
      createdAt: any
      requestChanges: Array<{
        __typename?: 'RequestChange'
        id: string
        submitWorkId: number
        description: string
        budget: number
        createdAt: any
      }>
    }>
  }>
  activity: Array<{
    __typename?: 'Activity'
    id: string
    event: string
    createdAt: any
    milestoneId?: string | null
  }>
  activationHistory: Array<{
    __typename?: 'ActivationHistory'
    id: string
    activationStatus: ContractActivationStatus
    createdAt: any
  }>
  assignUsers: Array<{
    __typename?: 'ContractUserAssign'
    id: string
    userId?: string | null
    role: string
    email?: string | null
    firstName?: string | null
    lastName?: string | null
  }>
  sendContract: Array<{
    __typename?: 'SendContract'
    id: string
    contractId: number
    userId: number
    sendStatus: string
  }>
  descriptions: Array<{
    __typename?: 'ContractDescription'
    id: string
    contractId: number
    description?: string | null
    reason: string
  }>
  submitWork: Array<{
    __typename?: 'SubmitWork'
    id: string
    status: string
    contractId?: number | null
    description?: string | null
    budget: number
    currency?: ContractCurrency | null
    createdAt: any
    requestChanges: Array<{
      __typename?: 'RequestChange'
      id: string
      submitWorkId: number
      description: string
      budget: number
      createdAt: any
    }>
  }>
}

export type ContractItemFragment = {
  __typename?: 'Contract'
  id: string
  status: string
  contractName: string
  email: string
  projectRequirements?: string | null
  category?: string | null
  type: string
  budget?: number | null
  dueDate?: any | null
  rate?: number | null
  prepayment?: number | null
  limit?: number | null
  archived: boolean
  limitType?: string | null
  paymentType: string
  activationStatus: string
  currency: string
  escrowContractId?: string | null
  milestones: Array<{
    __typename?: 'ContractMilestone'
    id: string
    name: string
    budget: number
    dueDate?: any | null
    escrowMilestoneId?: string | null
    status: string
    submitWork: Array<{
      __typename?: 'SubmitWork'
      id: string
      status: string
      contractId?: number | null
      description?: string | null
      budget: number
      currency?: ContractCurrency | null
      createdAt: any
      requestChanges: Array<{
        __typename?: 'RequestChange'
        id: string
        submitWorkId: number
        description: string
        budget: number
        createdAt: any
      }>
    }>
  }>
  transactions: Array<{ __typename?: 'Transaction'; hash: string }>
  activationHistory: Array<{
    __typename?: 'ActivationHistory'
    id: string
    activationStatus: ContractActivationStatus
    createdAt: any
  }>
  assignUsers: Array<{
    __typename?: 'ContractUserAssign'
    id: string
    userId?: string | null
    role: string
    email?: string | null
    firstName?: string | null
    lastName?: string | null
  }>
  sendContract: Array<{
    __typename?: 'SendContract'
    id: string
    contractId: number
    userId: number
    sendStatus: string
  }>
  descriptions: Array<{
    __typename?: 'ContractDescription'
    id: string
    contractId: number
    description?: string | null
    reason: string
  }>
}

export type GetActiveUserContractsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetActiveUserContractsQuery = {
  __typename?: 'Query'
  getActiveUserContracts: Array<{ __typename?: 'Contract'; id: string }>
}

export type GetPendingContractsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  subStatus?: InputMaybe<Array<ContractSubStatusType> | ContractSubStatusType>
}>

export type GetPendingContractsQuery = {
  __typename?: 'Query'
  contracts: {
    __typename?: 'ContractPaginated'
    totalCount: number
    hasNextPage: boolean
    nodes?: Array<{
      __typename?: 'ViewContract'
      id: string
      status: string
      contractName: string
      email: string
      projectRequirements?: string | null
      category?: string | null
      type: string
      budget?: number | null
      dueDate?: any | null
      createdAt: any
      referenceId?: string | null
      initialPrepayment?: number | null
      rate?: number | null
      prepayment?: number | null
      isRateIncrease?: ChangeStatus | null
      isWeekLimitIncrease?: ChangeStatus | null
      isWeekLimitWillUpdate?: boolean | null
      limit?: number | null
      archived: boolean
      limitType?: string | null
      paymentType: string
      activationStatus: string
      currency: string
      useMilestones: boolean
      escrowContractId?: string | null
      contextStatus?: ContractSubStatusType | null
      milestones: Array<{
        __typename?: 'ContractMilestone'
        id: string
        name: string
        budget: number
        dueDate?: any | null
        escrowMilestoneId?: string | null
        status: string
        submitWork: Array<{
          __typename?: 'SubmitWork'
          id: string
          status: string
          contractId?: number | null
          description?: string | null
          budget: number
          currency?: ContractCurrency | null
          createdAt: any
          requestChanges: Array<{
            __typename?: 'RequestChange'
            id: string
            submitWorkId: number
            description: string
            budget: number
            createdAt: any
          }>
        }>
      }>
      activity: Array<{
        __typename?: 'Activity'
        id: string
        event: string
        createdAt: any
        milestoneId?: string | null
      }>
      activationHistory: Array<{
        __typename?: 'ActivationHistory'
        id: string
        activationStatus: ContractActivationStatus
        createdAt: any
      }>
      assignUsers: Array<{
        __typename?: 'ContractUserAssign'
        id: string
        userId?: string | null
        role: string
        email?: string | null
        firstName?: string | null
        lastName?: string | null
      }>
      sendContract: Array<{
        __typename?: 'SendContract'
        id: string
        contractId: number
        userId: number
        sendStatus: string
      }>
      descriptions: Array<{
        __typename?: 'ContractDescription'
        id: string
        contractId: number
        description?: string | null
        reason: string
      }>
      submitWork: Array<{
        __typename?: 'SubmitWork'
        id: string
        status: string
        contractId?: number | null
        description?: string | null
        budget: number
        currency?: ContractCurrency | null
        createdAt: any
        requestChanges: Array<{
          __typename?: 'RequestChange'
          id: string
          submitWorkId: number
          description: string
          budget: number
          createdAt: any
        }>
      }>
    }> | null
  }
}

export type GetPendingContractsBackgroundModeQueryVariables = Exact<{
  [key: string]: never
}>

export type GetPendingContractsBackgroundModeQuery = {
  __typename?: 'Query'
  contracts: { __typename?: 'ContractPaginated'; totalCount: number }
}

export type GetOngoingContractsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  subStatus?: InputMaybe<Array<ContractSubStatusType> | ContractSubStatusType>
}>

export type GetOngoingContractsQuery = {
  __typename?: 'Query'
  contracts: {
    __typename?: 'ContractPaginated'
    totalCount: number
    hasNextPage: boolean
    nodes?: Array<{
      __typename?: 'ViewContract'
      id: string
      status: string
      contractName: string
      email: string
      projectRequirements?: string | null
      category?: string | null
      type: string
      budget?: number | null
      dueDate?: any | null
      createdAt: any
      referenceId?: string | null
      initialPrepayment?: number | null
      rate?: number | null
      prepayment?: number | null
      isRateIncrease?: ChangeStatus | null
      isWeekLimitIncrease?: ChangeStatus | null
      isWeekLimitWillUpdate?: boolean | null
      limit?: number | null
      archived: boolean
      limitType?: string | null
      paymentType: string
      activationStatus: string
      currency: string
      useMilestones: boolean
      escrowContractId?: string | null
      contextStatus?: ContractSubStatusType | null
      milestones: Array<{
        __typename?: 'ContractMilestone'
        id: string
        name: string
        budget: number
        dueDate?: any | null
        escrowMilestoneId?: string | null
        status: string
        submitWork: Array<{
          __typename?: 'SubmitWork'
          id: string
          status: string
          contractId?: number | null
          description?: string | null
          budget: number
          currency?: ContractCurrency | null
          createdAt: any
          requestChanges: Array<{
            __typename?: 'RequestChange'
            id: string
            submitWorkId: number
            description: string
            budget: number
            createdAt: any
          }>
        }>
      }>
      activity: Array<{
        __typename?: 'Activity'
        id: string
        event: string
        createdAt: any
        milestoneId?: string | null
      }>
      activationHistory: Array<{
        __typename?: 'ActivationHistory'
        id: string
        activationStatus: ContractActivationStatus
        createdAt: any
      }>
      assignUsers: Array<{
        __typename?: 'ContractUserAssign'
        id: string
        userId?: string | null
        role: string
        email?: string | null
        firstName?: string | null
        lastName?: string | null
      }>
      sendContract: Array<{
        __typename?: 'SendContract'
        id: string
        contractId: number
        userId: number
        sendStatus: string
      }>
      descriptions: Array<{
        __typename?: 'ContractDescription'
        id: string
        contractId: number
        description?: string | null
        reason: string
      }>
      submitWork: Array<{
        __typename?: 'SubmitWork'
        id: string
        status: string
        contractId?: number | null
        description?: string | null
        budget: number
        currency?: ContractCurrency | null
        createdAt: any
        requestChanges: Array<{
          __typename?: 'RequestChange'
          id: string
          submitWorkId: number
          description: string
          budget: number
          createdAt: any
        }>
      }>
    }> | null
  }
}

export type GetArchivedContractsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  subStatus?: InputMaybe<Array<ContractSubStatusType> | ContractSubStatusType>
}>

export type GetArchivedContractsQuery = {
  __typename?: 'Query'
  contracts: {
    __typename?: 'ContractPaginated'
    totalCount: number
    hasNextPage: boolean
    nodes?: Array<{
      __typename?: 'ViewContract'
      id: string
      status: string
      contractName: string
      email: string
      projectRequirements?: string | null
      category?: string | null
      type: string
      budget?: number | null
      dueDate?: any | null
      createdAt: any
      referenceId?: string | null
      initialPrepayment?: number | null
      rate?: number | null
      prepayment?: number | null
      isRateIncrease?: ChangeStatus | null
      isWeekLimitIncrease?: ChangeStatus | null
      isWeekLimitWillUpdate?: boolean | null
      limit?: number | null
      archived: boolean
      limitType?: string | null
      paymentType: string
      activationStatus: string
      currency: string
      useMilestones: boolean
      escrowContractId?: string | null
      contextStatus?: ContractSubStatusType | null
      milestones: Array<{
        __typename?: 'ContractMilestone'
        id: string
        name: string
        budget: number
        dueDate?: any | null
        escrowMilestoneId?: string | null
        status: string
        submitWork: Array<{
          __typename?: 'SubmitWork'
          id: string
          status: string
          contractId?: number | null
          description?: string | null
          budget: number
          currency?: ContractCurrency | null
          createdAt: any
          requestChanges: Array<{
            __typename?: 'RequestChange'
            id: string
            submitWorkId: number
            description: string
            budget: number
            createdAt: any
          }>
        }>
      }>
      activity: Array<{
        __typename?: 'Activity'
        id: string
        event: string
        createdAt: any
        milestoneId?: string | null
      }>
      activationHistory: Array<{
        __typename?: 'ActivationHistory'
        id: string
        activationStatus: ContractActivationStatus
        createdAt: any
      }>
      assignUsers: Array<{
        __typename?: 'ContractUserAssign'
        id: string
        userId?: string | null
        role: string
        email?: string | null
        firstName?: string | null
        lastName?: string | null
      }>
      sendContract: Array<{
        __typename?: 'SendContract'
        id: string
        contractId: number
        userId: number
        sendStatus: string
      }>
      descriptions: Array<{
        __typename?: 'ContractDescription'
        id: string
        contractId: number
        description?: string | null
        reason: string
      }>
      submitWork: Array<{
        __typename?: 'SubmitWork'
        id: string
        status: string
        contractId?: number | null
        description?: string | null
        budget: number
        currency?: ContractCurrency | null
        createdAt: any
        requestChanges: Array<{
          __typename?: 'RequestChange'
          id: string
          submitWorkId: number
          description: string
          budget: number
          createdAt: any
        }>
      }>
    }> | null
  }
}

export type GetContractQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetContractQuery = {
  __typename?: 'Query'
  contract: {
    __typename?: 'ViewContract'
    id: string
    status: string
    contractName: string
    email: string
    projectRequirements?: string | null
    category?: string | null
    type: string
    budget?: number | null
    dueDate?: any | null
    createdAt: any
    referenceId?: string | null
    initialPrepayment?: number | null
    rate?: number | null
    prepayment?: number | null
    isRateIncrease?: ChangeStatus | null
    isWeekLimitIncrease?: ChangeStatus | null
    isWeekLimitWillUpdate?: boolean | null
    limit?: number | null
    archived: boolean
    limitType?: string | null
    paymentType: string
    activationStatus: string
    currency: string
    useMilestones: boolean
    escrowContractId?: string | null
    contextStatus?: ContractSubStatusType | null
    milestones: Array<{
      __typename?: 'ContractMilestone'
      id: string
      name: string
      budget: number
      dueDate?: any | null
      escrowMilestoneId?: string | null
      status: string
      submitWork: Array<{
        __typename?: 'SubmitWork'
        id: string
        status: string
        contractId?: number | null
        description?: string | null
        budget: number
        currency?: ContractCurrency | null
        createdAt: any
        requestChanges: Array<{
          __typename?: 'RequestChange'
          id: string
          submitWorkId: number
          description: string
          budget: number
          createdAt: any
        }>
      }>
    }>
    activity: Array<{
      __typename?: 'Activity'
      id: string
      event: string
      createdAt: any
      milestoneId?: string | null
    }>
    activationHistory: Array<{
      __typename?: 'ActivationHistory'
      id: string
      activationStatus: ContractActivationStatus
      createdAt: any
    }>
    assignUsers: Array<{
      __typename?: 'ContractUserAssign'
      id: string
      userId?: string | null
      role: string
      email?: string | null
      firstName?: string | null
      lastName?: string | null
    }>
    sendContract: Array<{
      __typename?: 'SendContract'
      id: string
      contractId: number
      userId: number
      sendStatus: string
    }>
    descriptions: Array<{
      __typename?: 'ContractDescription'
      id: string
      contractId: number
      description?: string | null
      reason: string
    }>
    submitWork: Array<{
      __typename?: 'SubmitWork'
      id: string
      status: string
      contractId?: number | null
      description?: string | null
      budget: number
      currency?: ContractCurrency | null
      createdAt: any
      requestChanges: Array<{
        __typename?: 'RequestChange'
        id: string
        submitWorkId: number
        description: string
        budget: number
        createdAt: any
      }>
    }>
  }
}

export type CheckRoleForRegistrationQueryVariables = Exact<{
  [key: string]: never
}>

export type CheckRoleForRegistrationQuery = {
  __typename?: 'Query'
  checkRoleForRegister: {
    __typename?: 'UserRolesOutput'
    roles?: Array<UserRole> | null
  }
}

export type RemoveContractMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type RemoveContractMutation = {
  __typename?: 'Mutation'
  removeContract: boolean
}

export type SendContractMutationVariables = Exact<{
  data: CreateContractSendDto
}>

export type SendContractMutation = {
  __typename?: 'Mutation'
  sendContract: { __typename?: 'Contract'; id: string }
}

export type ClaimFundsMutationVariables = Exact<{
  depositId?: InputMaybe<Scalars['Int']['input']>
  milestoneIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
}>

export type ClaimFundsMutation = {
  __typename?: 'Mutation'
  claimFunds: { __typename?: 'MessageCompleteDto'; message?: string | null }
}

export type CreateDepositMutationVariables = Exact<{
  contractId?: InputMaybe<Scalars['Int']['input']>
  milestoneIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
}>

export type CreateDepositMutation = {
  __typename?: 'Mutation'
  createDeposit: {
    __typename?: 'ViewDepositOutput'
    depositId: number
    freelancerSecretHash: string
  }
}

export type GetDepositQueryVariables = Exact<{
  contractId?: InputMaybe<Scalars['Int']['input']>
  milestoneId?: InputMaybe<Scalars['Int']['input']>
  workingWeekId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetDepositQuery = {
  __typename?: 'Query'
  getDeposit: {
    __typename?: 'ViewDepositOutput'
    depositId: number
    freelancerSecret?: string | null
    freelancerSecretHash: string
    contractBudget: number
    inEscrow: number
    inEscrowRemainder: number
    availableAmount: number
    disputeResolveAmount: number
    amountToWithdraw: number
    amountToClaim: number
    received: number
  }
}

export type ApproveDisputeMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ApproveDisputeMutation = {
  __typename?: 'Mutation'
  approveDispute: {
    __typename?: 'Dispute'
    id: string
    subject?: string | null
    initiatorId?: number | null
    total?: number | null
    status: DisputeStatus
    type: string
    createdAt: any
  }
}

export type CancelDisputeMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type CancelDisputeMutation = {
  __typename?: 'Mutation'
  cancelDispute: {
    __typename?: 'Dispute'
    id: string
    subject?: string | null
    initiatorId?: number | null
    total?: number | null
    status: DisputeStatus
    type: string
    createdAt: any
  }
}

export type CreateDisputeHourlyOnlyHoursMutationVariables = Exact<{
  data: CreateDisputeHourlyOnlyTimeInput
}>

export type CreateDisputeHourlyOnlyHoursMutation = {
  __typename?: 'Mutation'
  createDisputeHourlyOnlyHours: {
    __typename?: 'DisputeViewOutput'
    id: string
    subject?: string | null
    initiatorId?: number | null
    total?: number | null
    status: DisputeStatus
    type: string
    message?: string | null
    createdAt: any
    updatedAt: any
    ticketId?: number | null
    parentRequestId?: number | null
    disputedTime?: number | null
    fee?: number | null
    initiatorFirstName?: string | null
    initiatorLastName?: string | null
    initiatorRole?: string | null
    dueDate?: any | null
    approveDate?: any | null
    disputeId?: number | null
    ticketLink?: string | null
    resolutionStatus?: string | null
    resolutionDate?: any | null
    resolutionTime?: number | null
    clientAmount?: number | null
    contractorAmount?: number | null
    currency?: ContractCurrency | null
    milestones?: Array<{
      __typename?: 'DisputeMilestone'
      id: string
      disputeId?: number | null
      milestoneId?: number | null
      createdAt: any
      milestone: {
        __typename?: 'ContractMilestone'
        id: string
        name: string
        budget: number
        contractId?: number | null
        dueDate?: any | null
        status: string
        createdAt: any
        escrowMilestoneId?: string | null
      }
    }> | null
    contract?: {
      __typename?: 'Contract'
      id: string
      status: string
      contractName: string
      type: string
      budget?: number | null
      dueDate?: any | null
      prepayment?: number | null
      initialPrepayment?: number | null
      rate?: number | null
    } | null
    transactions?: Array<{
      __typename?: 'TransactionOutput'
      hash: string
      userAccount?: string | null
      transactionType: string
      status: string
      depositId?: number | null
      createdAt: any
      contractId: number
      link?: string | null
    }> | null
    workingWeeks?: Array<{
      __typename?: 'WorkingWeekView'
      id: string
      start?: any | null
      end?: any | null
      escrowWeekId?: number | null
      totalTime?: number | null
    }> | null
  }
}

export type DisputeListQueryVariables = Exact<{
  filter: DisputeListParamsInput
}>

export type DisputeListQuery = {
  __typename?: 'Query'
  disputeList: {
    __typename?: 'DisputeListViewOutputPaginated'
    totalCount?: number | null
    hasNextPage?: boolean | null
    nodes?: Array<{
      __typename?: 'DisputeViewOutput'
      id: string
      subject?: string | null
      initiatorId?: number | null
      total?: number | null
      status: DisputeStatus
      type: string
      createdAt: any
      initiatorFirstName?: string | null
      initiatorLastName?: string | null
      initiatorRole?: string | null
      disputeId?: number | null
      resolutionStatus?: string | null
      parentRequestId?: number | null
      disputedTime?: number | null
      workingWeeks?: Array<{
        __typename?: 'WorkingWeekView'
        id: string
        start?: any | null
        end?: any | null
        totalTime?: number | null
      }> | null
    }> | null
  }
}

export type DisputeListWidgetQueryVariables = Exact<{
  filter: DisputeListParamsInput
}>

export type DisputeListWidgetQuery = {
  __typename?: 'Query'
  disputeList: {
    __typename?: 'DisputeListViewOutputPaginated'
    totalCount?: number | null
    hasNextPage?: boolean | null
    nodes?: Array<{
      __typename?: 'DisputeViewOutput'
      id: string
      status: DisputeStatus
      type: string
      transactions?: Array<{
        __typename?: 'TransactionOutput'
        transactionType: string
      }> | null
    }> | null
  }
}

export type FindOneDisputeQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type FindOneDisputeQuery = {
  __typename?: 'Query'
  findOneDispute: {
    __typename?: 'DisputeViewOutput'
    id: string
    subject?: string | null
    initiatorId?: number | null
    total?: number | null
    status: DisputeStatus
    type: string
    message?: string | null
    createdAt: any
    updatedAt: any
    ticketId?: number | null
    parentRequestId?: number | null
    disputedTime?: number | null
    fee?: number | null
    initiatorFirstName?: string | null
    initiatorLastName?: string | null
    initiatorRole?: string | null
    dueDate?: any | null
    approveDate?: any | null
    disputeId?: number | null
    ticketLink?: string | null
    resolutionStatus?: string | null
    resolutionDate?: any | null
    resolutionTime?: number | null
    clientAmount?: number | null
    contractorAmount?: number | null
    currency?: ContractCurrency | null
    milestones?: Array<{
      __typename?: 'DisputeMilestone'
      id: string
      disputeId?: number | null
      milestoneId?: number | null
      createdAt: any
      milestone: {
        __typename?: 'ContractMilestone'
        id: string
        name: string
        budget: number
        contractId?: number | null
        dueDate?: any | null
        status: string
        createdAt: any
        escrowMilestoneId?: string | null
      }
    }> | null
    contract?: {
      __typename?: 'Contract'
      id: string
      status: string
      contractName: string
      type: string
      budget?: number | null
      dueDate?: any | null
      prepayment?: number | null
      initialPrepayment?: number | null
      rate?: number | null
    } | null
    transactions?: Array<{
      __typename?: 'TransactionOutput'
      hash: string
      userAccount?: string | null
      transactionType: string
      status: string
      depositId?: number | null
      createdAt: any
      contractId: number
      link?: string | null
    }> | null
    workingWeeks?: Array<{
      __typename?: 'WorkingWeekView'
      id: string
      start?: any | null
      end?: any | null
      escrowWeekId?: number | null
      totalTime?: number | null
    }> | null
  }
}

export type DisputeItemFragment = {
  __typename?: 'Dispute'
  id: string
  subject?: string | null
  initiatorId?: number | null
  total?: number | null
  status: DisputeStatus
  type: string
  createdAt: any
}

export type DisputeListViewOutputPaginatedItemFragment = {
  __typename?: 'DisputeListViewOutputPaginated'
  totalCount?: number | null
  hasNextPage?: boolean | null
  nodes?: Array<{
    __typename?: 'DisputeViewOutput'
    id: string
    subject?: string | null
    initiatorId?: number | null
    total?: number | null
    status: DisputeStatus
    type: string
    createdAt: any
    initiatorFirstName?: string | null
    initiatorLastName?: string | null
    initiatorRole?: string | null
    disputeId?: number | null
    resolutionStatus?: string | null
    parentRequestId?: number | null
    disputedTime?: number | null
    workingWeeks?: Array<{
      __typename?: 'WorkingWeekView'
      id: string
      start?: any | null
      end?: any | null
      totalTime?: number | null
    }> | null
  }> | null
}

export type DisputeViewOutputFragment = {
  __typename?: 'DisputeViewOutput'
  id: string
  subject?: string | null
  initiatorId?: number | null
  total?: number | null
  status: DisputeStatus
  type: string
  message?: string | null
  createdAt: any
  updatedAt: any
  ticketId?: number | null
  parentRequestId?: number | null
  disputedTime?: number | null
  fee?: number | null
  initiatorFirstName?: string | null
  initiatorLastName?: string | null
  initiatorRole?: string | null
  dueDate?: any | null
  approveDate?: any | null
  disputeId?: number | null
  ticketLink?: string | null
  resolutionStatus?: string | null
  resolutionDate?: any | null
  resolutionTime?: number | null
  clientAmount?: number | null
  contractorAmount?: number | null
  currency?: ContractCurrency | null
  milestones?: Array<{
    __typename?: 'DisputeMilestone'
    id: string
    disputeId?: number | null
    milestoneId?: number | null
    createdAt: any
    milestone: {
      __typename?: 'ContractMilestone'
      id: string
      name: string
      budget: number
      contractId?: number | null
      dueDate?: any | null
      status: string
      createdAt: any
      escrowMilestoneId?: string | null
    }
  }> | null
  contract?: {
    __typename?: 'Contract'
    id: string
    status: string
    contractName: string
    type: string
    budget?: number | null
    dueDate?: any | null
    prepayment?: number | null
    initialPrepayment?: number | null
    rate?: number | null
  } | null
  transactions?: Array<{
    __typename?: 'TransactionOutput'
    hash: string
    userAccount?: string | null
    transactionType: string
    status: string
    depositId?: number | null
    createdAt: any
    contractId: number
    link?: string | null
  }> | null
  workingWeeks?: Array<{
    __typename?: 'WorkingWeekView'
    id: string
    start?: any | null
    end?: any | null
    escrowWeekId?: number | null
    totalTime?: number | null
  }> | null
}

export type RejectDisputeMutationVariables = Exact<{
  id: Scalars['Int']['input']
  message: Scalars['String']['input']
}>

export type RejectDisputeMutation = {
  __typename?: 'Mutation'
  rejectDispute: {
    __typename?: 'DisputeViewOutput'
    ticketLink?: string | null
    id: string
    subject?: string | null
    initiatorId?: number | null
    total?: number | null
    status: DisputeStatus
    type: string
    message?: string | null
    createdAt: any
    updatedAt: any
    ticketId?: number | null
    parentRequestId?: number | null
    disputedTime?: number | null
    fee?: number | null
    initiatorFirstName?: string | null
    initiatorLastName?: string | null
    initiatorRole?: string | null
    dueDate?: any | null
    approveDate?: any | null
    disputeId?: number | null
    resolutionStatus?: string | null
    resolutionDate?: any | null
    resolutionTime?: number | null
    clientAmount?: number | null
    contractorAmount?: number | null
    currency?: ContractCurrency | null
    milestones?: Array<{
      __typename?: 'DisputeMilestone'
      id: string
      disputeId?: number | null
      milestoneId?: number | null
      createdAt: any
      milestone: {
        __typename?: 'ContractMilestone'
        id: string
        name: string
        budget: number
        contractId?: number | null
        dueDate?: any | null
        status: string
        createdAt: any
        escrowMilestoneId?: string | null
      }
    }> | null
    contract?: {
      __typename?: 'Contract'
      id: string
      status: string
      contractName: string
      type: string
      budget?: number | null
      dueDate?: any | null
      prepayment?: number | null
      initialPrepayment?: number | null
      rate?: number | null
    } | null
    transactions?: Array<{
      __typename?: 'TransactionOutput'
      hash: string
      userAccount?: string | null
      transactionType: string
      status: string
      depositId?: number | null
      createdAt: any
      contractId: number
      link?: string | null
    }> | null
    workingWeeks?: Array<{
      __typename?: 'WorkingWeekView'
      id: string
      start?: any | null
      end?: any | null
      escrowWeekId?: number | null
      totalTime?: number | null
    }> | null
  }
}

export type SendContractorDisputeMutationVariables = Exact<{
  data: SendDisputeContractorInput
}>

export type SendContractorDisputeMutation = {
  __typename?: 'Mutation'
  sendContractorDispute: {
    __typename?: 'Dispute'
    id: string
    subject?: string | null
    initiatorId?: number | null
    total?: number | null
    status: DisputeStatus
    type: string
    createdAt: any
  }
}

export type SendDisputeRequestMutationVariables = Exact<{
  data: SendDisputeRequestInput
}>

export type SendDisputeRequestMutation = {
  __typename?: 'Mutation'
  sendDisputeRequest: {
    __typename?: 'Dispute'
    id: string
    subject?: string | null
    initiatorId?: number | null
    total?: number | null
    status: DisputeStatus
    type: string
    createdAt: any
  }
}

export type FindOneDisputeByWorkingWeekIdQueryVariables = Exact<{
  workingWeekId: Scalars['Int']['input']
}>

export type FindOneDisputeByWorkingWeekIdQuery = {
  __typename?: 'Query'
  findOneDisputeByWorkingWeekId: {
    __typename?: 'DisputeViewOutput'
    id: string
    subject?: string | null
    initiatorId?: number | null
    total?: number | null
    status: DisputeStatus
    type: string
    message?: string | null
    createdAt: any
    updatedAt: any
    ticketId?: number | null
    parentRequestId?: number | null
    disputedTime?: number | null
    fee?: number | null
    initiatorFirstName?: string | null
    initiatorLastName?: string | null
    initiatorRole?: string | null
    dueDate?: any | null
    approveDate?: any | null
    disputeId?: number | null
    ticketLink?: string | null
    resolutionStatus?: string | null
    resolutionDate?: any | null
    resolutionTime?: number | null
    clientAmount?: number | null
    contractorAmount?: number | null
    currency?: ContractCurrency | null
    milestones?: Array<{
      __typename?: 'DisputeMilestone'
      id: string
      disputeId?: number | null
      milestoneId?: number | null
      createdAt: any
      milestone: {
        __typename?: 'ContractMilestone'
        id: string
        name: string
        budget: number
        contractId?: number | null
        dueDate?: any | null
        status: string
        createdAt: any
        escrowMilestoneId?: string | null
      }
    }> | null
    contract?: {
      __typename?: 'Contract'
      id: string
      status: string
      contractName: string
      type: string
      budget?: number | null
      dueDate?: any | null
      prepayment?: number | null
      initialPrepayment?: number | null
      rate?: number | null
    } | null
    transactions?: Array<{
      __typename?: 'TransactionOutput'
      hash: string
      userAccount?: string | null
      transactionType: string
      status: string
      depositId?: number | null
      createdAt: any
      contractId: number
      link?: string | null
    }> | null
    workingWeeks?: Array<{
      __typename?: 'WorkingWeekView'
      id: string
      start?: any | null
      end?: any | null
      escrowWeekId?: number | null
      totalTime?: number | null
    }> | null
  }
}

export type GetWeekReportDataQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetWeekReportDataQuery = {
  __typename?: 'Query'
  getWeekReport: {
    __typename?: 'WeekView'
    id: string
    contractId: number
    description?: string | null
    escrowWeekId?: number | null
    start?: any | null
    end?: any | null
    limit?: number | null
    totalTime: number
    totalBudget: number
    resolutionTime?: number | null
    resolutionBudget?: number | null
    status: string
    subStatus?: string | null
    workingDays: Array<{
      __typename?: 'WeekDayView'
      id: string
      workingTime: number
      description?: string | null
      day: any
      isEnabled?: boolean | null
      isEditable?: boolean | null
    }>
    submitWork: Array<{
      __typename?: 'SubmitWork'
      id: string
      workingWeekId?: number | null
    }>
    transactions?: Array<{
      __typename?: 'TransactionOutput'
      amount?: number | null
      hash: string
      currency: string
      status: string
      link?: string | null
      transactionId?: string | null
      transactionType: string
      createdAt: any
      userAccount?: string | null
    }> | null
  }
}

export type GetWeekReportListQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetWeekReportListQuery = {
  __typename?: 'Query'
  weekReportList: Array<{
    __typename?: 'WeekView'
    id: string
    contractId: number
    description?: string | null
    start?: any | null
    end?: any | null
    status: string
    rate?: number | null
    subStatus?: string | null
    totalTime: number
    totalBudget: number
    limit?: number | null
    resolutionTime?: number | null
    resolutionBudget?: number | null
    workingDays: Array<{
      __typename?: 'WeekDayView'
      id: string
      workingTime: number
      day: any
      description?: string | null
    }>
    disputes?: Array<{
      __typename?: 'Dispute'
      disputedTime?: number | null
      resolutionTime?: number | null
      resolutionDate?: any | null
    }> | null
  }>
}

export type SetWorkingDayMutationVariables = Exact<{
  data: SetWorkingDayInput
}>

export type SetWorkingDayMutation = {
  __typename?: 'Mutation'
  setWorkingDay: {
    __typename?: 'WorkingDay'
    id: string
    workingTime: number
    workingWeekId: number
    description?: string | null
    day: any
  }
}

export type GetNotificationsQueryVariables = Exact<{
  after: Scalars['Int']['input']
  limit: Scalars['Int']['input']
}>

export type GetNotificationsQuery = {
  __typename?: 'Query'
  notifications: {
    __typename?: 'NotificationPaginated'
    totalCount: number
    hasNextPage: boolean
    nodes?: Array<{
      __typename?: 'Notification'
      id: string
      event: string
      type: string
      status: string
      userId: string
      data: string
      createdAt: any
    }> | null
  }
}

export type MarkAllAsSeenMutationVariables = Exact<{ [key: string]: never }>

export type MarkAllAsSeenMutation = {
  __typename?: 'Mutation'
  markAllAsSeen: Array<{
    __typename?: 'Notification'
    id: string
    event: string
    type: string
    status: string
    userId: string
    data: string
    createdAt: any
  }>
}

export type UpdateNotificationMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateNotificationInput
}>

export type UpdateNotificationMutation = {
  __typename?: 'Mutation'
  updateNotification: {
    __typename?: 'Notification'
    id: string
    event: string
    type: string
    status: string
    userId: string
    data: string
    createdAt: any
  }
}

export type CreateAddressMutationVariables = Exact<{
  data: CreateAddressInput
}>

export type CreateAddressMutation = {
  __typename?: 'Mutation'
  createAddress: {
    __typename?: 'Address'
    id: string
    country?: string | null
    state?: string | null
    city?: string | null
    line1?: string | null
    line2?: string | null
    zip?: string | null
  }
}

export type CreateCompanyMutationVariables = Exact<{
  data: CreateCompanyInput
}>

export type CreateCompanyMutation = {
  __typename?: 'Mutation'
  createCompany: {
    __typename?: 'Company'
    id: string
    name?: string | null
    size?: string | null
  }
}

export type CreateProfileMutationVariables = Exact<{
  data: CreateProfileInput
}>

export type CreateProfileMutation = {
  __typename?: 'Mutation'
  createProfile: {
    __typename?: 'Profile'
    id?: string | null
    firstName?: string | null
    middleName?: string | null
    lastName?: string | null
  }
}

export type FindCompaniesByContractIdQueryVariables = Exact<{
  contractId: Scalars['Int']['input']
}>

export type FindCompaniesByContractIdQuery = {
  __typename?: 'Query'
  findCompaniesByContractId: {
    __typename?: 'FindContractCompaniesOutput'
    clientCompany?: {
      __typename?: 'Company'
      id: string
      name?: string | null
      size?: string | null
      addressId?: number | null
      profileId?: number | null
      address?: {
        __typename?: 'Address'
        id: string
        country?: string | null
        state?: string | null
        city?: string | null
        line1?: string | null
        line2?: string | null
        zip?: string | null
        profileId?: number | null
        companyId?: number | null
      } | null
    } | null
    contractorCompany?: {
      __typename?: 'Company'
      id: string
      name?: string | null
      size?: string | null
      addressId?: number | null
      profileId?: number | null
      address?: {
        __typename?: 'Address'
        id: string
        country?: string | null
        state?: string | null
        city?: string | null
        line1?: string | null
        line2?: string | null
        zip?: string | null
        profileId?: number | null
        companyId?: number | null
      } | null
    } | null
  }
}

export type FindContractAssigneesAddressesQueryVariables = Exact<{
  contractId: Scalars['Int']['input']
}>

export type FindContractAssigneesAddressesQuery = {
  __typename?: 'Query'
  findContractAssigneesAddresses: {
    __typename?: 'FindContractAssigneesAddressesOutput'
    clientAddress: {
      __typename?: 'Address'
      id: string
      country?: string | null
      state?: string | null
      city?: string | null
      line1?: string | null
      line2?: string | null
      zip?: string | null
      profileId?: number | null
      companyId?: number | null
    }
    contractorAddress: {
      __typename?: 'Address'
      id: string
      country?: string | null
      state?: string | null
      city?: string | null
      line1?: string | null
      line2?: string | null
      zip?: string | null
      profileId?: number | null
      companyId?: number | null
    }
  }
}

export type FindMyAddressQueryVariables = Exact<{
  isCompanyProfile: Scalars['Boolean']['input']
}>

export type FindMyAddressQuery = {
  __typename?: 'Query'
  findMyAddress: {
    __typename?: 'Address'
    id: string
    country?: string | null
    state?: string | null
    city?: string | null
    line1?: string | null
    line2?: string | null
    zip?: string | null
  }
}

export type FindMyCompanyQueryVariables = Exact<{ [key: string]: never }>

export type FindMyCompanyQuery = {
  __typename?: 'Query'
  findMyCompany: {
    __typename?: 'Company'
    id: string
    name?: string | null
    size?: string | null
  }
}

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetMyProfileQuery = {
  __typename?: 'Query'
  findMyProfile: {
    __typename?: 'Profile'
    id?: string | null
    firstName?: string | null
    middleName?: string | null
    lastName?: string | null
  }
}

export type RemoveMyProfileMutationVariables = Exact<{ [key: string]: never }>

export type RemoveMyProfileMutation = {
  __typename?: 'Mutation'
  removeMyProfile: { __typename?: 'Profile'; id?: string | null }
}

export type UpdateMyAddressMutationVariables = Exact<{
  data: UpdateAddressInput
}>

export type UpdateMyAddressMutation = {
  __typename?: 'Mutation'
  updateMyAddress: {
    __typename?: 'Address'
    id: string
    country?: string | null
    state?: string | null
    city?: string | null
    line1?: string | null
    line2?: string | null
    zip?: string | null
  }
}

export type UpdateMyCompanyMutationVariables = Exact<{
  data: UpdateCompanyInput
}>

export type UpdateMyCompanyMutation = {
  __typename?: 'Mutation'
  updateMyCompany: {
    __typename?: 'Company'
    id: string
    name?: string | null
    size?: string | null
  }
}

export type UpdateMyProfileMutationVariables = Exact<{
  data: UpdateProfileInput
}>

export type UpdateMyProfileMutation = {
  __typename?: 'Mutation'
  updateMyProfile: {
    __typename?: 'Profile'
    id?: string | null
    firstName?: string | null
    middleName?: string | null
    lastName?: string | null
  }
}

export type CreateUserEscrowAddressMutationVariables = Exact<{
  data: CreateUserEscrowAddressInput
}>

export type CreateUserEscrowAddressMutation = {
  __typename?: 'Mutation'
  createUserEscrowAddress: {
    __typename?: 'UserEscrowAddress'
    id?: string | null
    userId?: string | null
    contractType?: string | null
    escrowAddress?: string | null
    escrowSalt?: string | null
  }
}

export type GetUserEscrowAddressQueryVariables = Exact<{
  clientId: Scalars['Int']['input']
}>

export type GetUserEscrowAddressQuery = {
  __typename?: 'Query'
  getUserEscrowAddress: {
    __typename?: 'GetUserEscrowAddressOutput'
    userEscrowAddress?: Array<{
      __typename?: 'UserEscrowAddress'
      id?: string | null
      userId?: string | null
      contractType?: string | null
      escrowAddress?: string | null
      escrowSalt?: string | null
    }> | null
  }
}

export type GetUserEscrowAndSaltQueryVariables = Exact<{
  clientId: Scalars['Int']['input']
}>

export type GetUserEscrowAndSaltQuery = {
  __typename?: 'Query'
  getUserEscrowAndSalt: {
    __typename?: 'GetUserEscrowAndSaltOutput'
    escrowAddress?: string | null
    escrowSalt?: string | null
  }
}

export type RequestChangeMutationVariables = Exact<{
  data: CreateRequestChangeInput
}>

export type RequestChangeMutation = {
  __typename?: 'Mutation'
  sendRequestChange: {
    __typename?: 'RequestChange'
    id: string
    budget: number
    submitWorkId: number
    description: string
  }
}

export type SubmitWorkMutationVariables = Exact<{
  data: SubmitWorkInput
}>

export type SubmitWorkMutation = {
  __typename?: 'Mutation'
  submitWork: {
    __typename?: 'SubmitWork'
    id: string
    budget: number
    currency?: ContractCurrency | null
    contractId?: number | null
    milestoneId?: number | null
    description?: string | null
  }
}

export type GetMyTransactionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetMyTransactionsQuery = {
  __typename?: 'Query'
  getMyTransactions: {
    __typename?: 'TransactionListPaginated'
    totalCount?: number | null
    hasNextPage?: boolean | null
    nodes: Array<{
      __typename?: 'TransactionList'
      hash: string
      depositId?: number | null
      contractId: number
      amount?: number | null
      fee?: number | null
      transactionType: string
      currency: string
      userAccount?: string | null
      status: string
      contractName?: string | null
      transactionLink?: string | null
      createdAt: any
      transactionId?: string | null
      assignedUsers?: Array<{
        __typename?: 'AssignedUsers'
        id?: number | null
        email?: string | null
        role?: string | null
        userId?: number | null
        contractId?: number | null
        firstName?: string | null
        lastName?: string | null
      }> | null
      descriptions: Array<{
        __typename?: 'TransactionDescription'
        description?: string | null
        contractType: string
      }>
      mainTransaction?: {
        __typename?: 'TransactionList'
        hash: string
        depositId?: number | null
        contractId: number
        amount?: number | null
        fee?: number | null
        transactionType: string
        currency: string
        userAccount?: string | null
        status: string
        transactionLink?: string | null
        createdAt: any
        transactionId?: string | null
      } | null
    }>
  }
}

export type TransactionAmountsQueryVariables = Exact<{
  transactionFeeType: TransactionFeeType
  amount: Scalars['Float']['input']
  wallet: Scalars['String']['input']
}>

export type TransactionAmountsQuery = {
  __typename?: 'Query'
  transactionAmounts: {
    __typename?: 'TransactionAmountsOutput'
    percentage: number
    amountWithFee: number
    amountWithoutFee: number
    fee: number
  }
}

export type GetTransactionQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  contractId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetTransactionQuery = {
  __typename?: 'Query'
  transactions: Array<{
    __typename?: 'TransactionList'
    hash: string
    depositId?: number | null
    contractId: number
    amount?: number | null
    fee?: number | null
    transactionType: string
    currency: string
    userAccount?: string | null
    status: string
    contractName?: string | null
    transactionLink?: string | null
    createdAt: any
    transactionId?: string | null
    descriptions: Array<{
      __typename?: 'TransactionDescription'
      description?: string | null
      contractType: string
    }>
    assignedUsers?: Array<{
      __typename?: 'AssignedUsers'
      id?: number | null
      contractId?: number | null
      userId?: number | null
      role?: string | null
      email?: string | null
      firstName?: string | null
      lastName?: string | null
    }> | null
    mainTransaction?: {
      __typename?: 'TransactionList'
      hash: string
      depositId?: number | null
      contractId: number
      amount?: number | null
      fee?: number | null
      transactionType: string
      currency: string
      userAccount?: string | null
      status: string
      transactionLink?: string | null
      createdAt: any
      transactionId?: string | null
    } | null
  }>
}

export type AddTransactionMutationVariables = Exact<{
  data: AddTransactionDataInput
}>

export type AddTransactionMutation = {
  __typename?: 'Mutation'
  addTransaction: {
    __typename?: 'TransactionWrap'
    hash: string
    transactionLink?: string | null
  }
}

export type CreateWalletMutationVariables = Exact<{
  data: CreateWalletInput
}>

export type CreateWalletMutation = {
  __typename?: 'Mutation'
  createWallet: {
    __typename?: 'Wallet'
    id: string
    wallet: string
    contractId?: number | null
    userId?: number | null
  }
}

export type FindAllWalletsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  contractId?: InputMaybe<Scalars['Int']['input']>
}>

export type FindAllWalletsQuery = {
  __typename?: 'Query'
  findAllWallets: Array<{
    __typename?: 'Wallet'
    id: string
    wallet: string
    contractId?: number | null
    userId?: number | null
    userWallet?: {
      __typename?: 'UserWallet'
      id: string
      profileId?: number | null
      userId?: number | null
      wallet?: string | null
      icon?: string | null
      walletType?: string | null
    } | null
  }>
}

export type GetUserWalletsQueryVariables = Exact<{ [key: string]: never }>

export type GetUserWalletsQuery = {
  __typename?: 'Query'
  getUserWallets: Array<{
    __typename?: 'UserWallet'
    id: string
    profileId?: number | null
    userId?: number | null
    wallet?: string | null
    icon?: string | null
    walletType?: string | null
    contractType?: string | null
  }>
}

export type SaveUserWalletMutationVariables = Exact<{
  data: SaveWalletInput
}>

export type SaveUserWalletMutation = {
  __typename?: 'Mutation'
  saveUserWallet: {
    __typename?: 'UserWallet'
    id: string
    profileId?: number | null
    userId?: number | null
    wallet?: string | null
    icon?: string | null
    contractType?: string | null
  }
}

export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
    country
    role
    isVerified
    isEmailVerified
    is2FAEnabled
  }
`
export const SubmitWorkItemFragmentDoc = gql`
  fragment SubmitWorkItem on SubmitWork {
    id
    status
    contractId
    description
    budget
    currency
    createdAt
    requestChanges {
      id
      submitWorkId
      description
      budget
      createdAt
    }
  }
`
export const MilestoneItemFragmentDoc = gql`
  fragment MilestoneItem on ContractMilestone {
    id
    name
    budget
    dueDate
    escrowMilestoneId
    submitWork {
      ...SubmitWorkItem
    }
    status
  }
  ${SubmitWorkItemFragmentDoc}
`
export const ActivationHistoryItemFragmentDoc = gql`
  fragment ActivationHistoryItem on ActivationHistory {
    id
    activationStatus
    createdAt
  }
`
export const ContractUserAssignItemFragmentDoc = gql`
  fragment ContractUserAssignItem on ContractUserAssign {
    id
    userId
    role
    email
    firstName
    lastName
  }
`
export const SendContractItemFragmentDoc = gql`
  fragment SendContractItem on SendContract {
    id
    contractId
    userId
    sendStatus
  }
`
export const ContractDescriptionItemFragmentDoc = gql`
  fragment ContractDescriptionItem on ContractDescription {
    id
    contractId
    description
    reason
  }
`
export const ViewContractItemFragmentDoc = gql`
  fragment ViewContractItem on ViewContract {
    id
    status
    contractName
    email
    projectRequirements
    category
    type
    budget
    dueDate
    createdAt
    referenceId
    initialPrepayment
    milestones {
      ...MilestoneItem
    }
    rate
    prepayment
    isRateIncrease
    isWeekLimitIncrease
    isWeekLimitWillUpdate
    limit
    archived
    limitType
    paymentType
    activationStatus
    currency
    useMilestones
    activity {
      id
      event
      createdAt
      milestoneId
    }
    escrowContractId
    useMilestones
    activationHistory {
      ...ActivationHistoryItem
    }
    assignUsers {
      ...ContractUserAssignItem
    }
    contextStatus
    sendContract {
      ...SendContractItem
    }
    descriptions {
      ...ContractDescriptionItem
    }
    submitWork {
      ...SubmitWorkItem
    }
  }
  ${MilestoneItemFragmentDoc}
  ${ActivationHistoryItemFragmentDoc}
  ${ContractUserAssignItemFragmentDoc}
  ${SendContractItemFragmentDoc}
  ${ContractDescriptionItemFragmentDoc}
  ${SubmitWorkItemFragmentDoc}
`
export const TransactionItemFragmentDoc = gql`
  fragment TransactionItem on Transaction {
    hash
  }
`
export const ContractItemFragmentDoc = gql`
  fragment ContractItem on Contract {
    id
    status
    contractName
    email
    projectRequirements
    category
    type
    budget
    dueDate
    milestones {
      ...MilestoneItem
    }
    rate
    prepayment
    limit
    archived
    transactions {
      ...TransactionItem
    }
    limitType
    paymentType
    activationStatus
    currency
    escrowContractId
    activationHistory {
      ...ActivationHistoryItem
    }
    assignUsers {
      ...ContractUserAssignItem
    }
    sendContract {
      ...SendContractItem
    }
    descriptions {
      ...ContractDescriptionItem
    }
  }
  ${MilestoneItemFragmentDoc}
  ${TransactionItemFragmentDoc}
  ${ActivationHistoryItemFragmentDoc}
  ${ContractUserAssignItemFragmentDoc}
  ${SendContractItemFragmentDoc}
  ${ContractDescriptionItemFragmentDoc}
`
export const DisputeItemFragmentDoc = gql`
  fragment DisputeItem on Dispute {
    id
    subject
    initiatorId
    total
    status
    type
    createdAt
  }
`
export const DisputeListViewOutputPaginatedItemFragmentDoc = gql`
  fragment DisputeListViewOutputPaginatedItem on DisputeListViewOutputPaginated {
    totalCount
    hasNextPage
    nodes {
      id
      subject
      initiatorId
      total
      status
      type
      createdAt
      initiatorFirstName
      initiatorLastName
      initiatorRole
      disputeId
      resolutionStatus
      parentRequestId
      disputedTime
      workingWeeks {
        id
        start
        end
        totalTime
      }
    }
  }
`
export const DisputeViewOutputFragmentDoc = gql`
  fragment disputeViewOutput on DisputeViewOutput {
    id
    subject
    initiatorId
    total
    status
    type
    message
    createdAt
    updatedAt
    ticketId
    parentRequestId
    disputedTime
    milestones {
      id
      disputeId
      milestoneId
      createdAt
      milestone {
        id
        name
        budget
        contractId
        dueDate
        status
        createdAt
        escrowMilestoneId
      }
    }
    contract {
      id
      status
      contractName
      type
      budget
      dueDate
      prepayment
      initialPrepayment
      rate
    }
    fee
    initiatorFirstName
    initiatorLastName
    initiatorRole
    dueDate
    approveDate
    disputeId
    transactions {
      hash
      userAccount
      transactionType
      status
      depositId
      createdAt
      contractId
      depositId
      link
    }
    ticketLink
    resolutionStatus
    resolutionDate
    resolutionTime
    clientAmount
    contractorAmount
    currency
    workingWeeks {
      id
      start
      end
      escrowWeekId
      totalTime
    }
  }
`
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    findMyUser {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  )
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  )
}
export function useGetCurrentUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, options)
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentUserSuspenseQuery
>
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>
export const RegisterUserDocument = gql`
  mutation registerUser {
    registerUser {
      email
    }
  }
`
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, options)
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>
export type RegisterUserMutationResult =
  Apollo.MutationResult<RegisterUserMutation>
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>
export const RemoveRegisterDocument = gql`
  mutation RemoveRegister {
    removeMyUser {
      email
    }
  }
`
export type RemoveRegisterMutationFn = Apollo.MutationFunction<
  RemoveRegisterMutation,
  RemoveRegisterMutationVariables
>

/**
 * __useRemoveRegisterMutation__
 *
 * To run a mutation, you first call `useRemoveRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRegisterMutation, { data, loading, error }] = useRemoveRegisterMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRegisterMutation,
    RemoveRegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveRegisterMutation,
    RemoveRegisterMutationVariables
  >(RemoveRegisterDocument, options)
}
export type RemoveRegisterMutationHookResult = ReturnType<
  typeof useRemoveRegisterMutation
>
export type RemoveRegisterMutationResult =
  Apollo.MutationResult<RemoveRegisterMutation>
export type RemoveRegisterMutationOptions = Apollo.BaseMutationOptions<
  RemoveRegisterMutation,
  RemoveRegisterMutationVariables
>
export const UpdateUserDocument = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateMyUser(data: $data) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  )
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const AcceptContractDocument = gql`
  mutation AcceptContract($data: AcceptContractDto!) {
    acceptContract(data: $data) {
      id
      status
    }
  }
`
export type AcceptContractMutationFn = Apollo.MutationFunction<
  AcceptContractMutation,
  AcceptContractMutationVariables
>

/**
 * __useAcceptContractMutation__
 *
 * To run a mutation, you first call `useAcceptContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptContractMutation, { data, loading, error }] = useAcceptContractMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAcceptContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptContractMutation,
    AcceptContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AcceptContractMutation,
    AcceptContractMutationVariables
  >(AcceptContractDocument, options)
}
export type AcceptContractMutationHookResult = ReturnType<
  typeof useAcceptContractMutation
>
export type AcceptContractMutationResult =
  Apollo.MutationResult<AcceptContractMutation>
export type AcceptContractMutationOptions = Apollo.BaseMutationOptions<
  AcceptContractMutation,
  AcceptContractMutationVariables
>
export const ApproveWorkDocument = gql`
  mutation approveWork(
    $submitWorkId: Int
    $requestChangeId: Int
    $workingWeekId: Int
  ) {
    approveWork(
      data: {
        submitWorkId: $submitWorkId
        requestChangeId: $requestChangeId
        workingWeekId: $workingWeekId
      }
    ) {
      message
    }
  }
`
export type ApproveWorkMutationFn = Apollo.MutationFunction<
  ApproveWorkMutation,
  ApproveWorkMutationVariables
>

/**
 * __useApproveWorkMutation__
 *
 * To run a mutation, you first call `useApproveWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveWorkMutation, { data, loading, error }] = useApproveWorkMutation({
 *   variables: {
 *      submitWorkId: // value for 'submitWorkId'
 *      requestChangeId: // value for 'requestChangeId'
 *      workingWeekId: // value for 'workingWeekId'
 *   },
 * });
 */
export function useApproveWorkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveWorkMutation,
    ApproveWorkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ApproveWorkMutation, ApproveWorkMutationVariables>(
    ApproveWorkDocument,
    options,
  )
}
export type ApproveWorkMutationHookResult = ReturnType<
  typeof useApproveWorkMutation
>
export type ApproveWorkMutationResult =
  Apollo.MutationResult<ApproveWorkMutation>
export type ApproveWorkMutationOptions = Apollo.BaseMutationOptions<
  ApproveWorkMutation,
  ApproveWorkMutationVariables
>
export const CheckContractBeforeCloseDocument = gql`
  query CheckContractBeforeClose($id: ID!) {
    checkContractBeforeClose(id: $id) {
      message
    }
  }
`

/**
 * __useCheckContractBeforeCloseQuery__
 *
 * To run a query within a React component, call `useCheckContractBeforeCloseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckContractBeforeCloseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckContractBeforeCloseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckContractBeforeCloseQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckContractBeforeCloseQuery,
    CheckContractBeforeCloseQueryVariables
  > &
    (
      | { variables: CheckContractBeforeCloseQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CheckContractBeforeCloseQuery,
    CheckContractBeforeCloseQueryVariables
  >(CheckContractBeforeCloseDocument, options)
}
export function useCheckContractBeforeCloseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckContractBeforeCloseQuery,
    CheckContractBeforeCloseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CheckContractBeforeCloseQuery,
    CheckContractBeforeCloseQueryVariables
  >(CheckContractBeforeCloseDocument, options)
}
export function useCheckContractBeforeCloseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CheckContractBeforeCloseQuery,
        CheckContractBeforeCloseQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CheckContractBeforeCloseQuery,
    CheckContractBeforeCloseQueryVariables
  >(CheckContractBeforeCloseDocument, options)
}
export type CheckContractBeforeCloseQueryHookResult = ReturnType<
  typeof useCheckContractBeforeCloseQuery
>
export type CheckContractBeforeCloseLazyQueryHookResult = ReturnType<
  typeof useCheckContractBeforeCloseLazyQuery
>
export type CheckContractBeforeCloseSuspenseQueryHookResult = ReturnType<
  typeof useCheckContractBeforeCloseSuspenseQuery
>
export type CheckContractBeforeCloseQueryResult = Apollo.QueryResult<
  CheckContractBeforeCloseQuery,
  CheckContractBeforeCloseQueryVariables
>
export const CheckRoleByEmailDocument = gql`
  query checkRoleByEmail($email: String!) {
    checkRoleByEmail(email: $email) {
      userId
      role
      email
    }
  }
`

/**
 * __useCheckRoleByEmailQuery__
 *
 * To run a query within a React component, call `useCheckRoleByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckRoleByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckRoleByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckRoleByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckRoleByEmailQuery,
    CheckRoleByEmailQueryVariables
  > &
    (
      | { variables: CheckRoleByEmailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckRoleByEmailQuery, CheckRoleByEmailQueryVariables>(
    CheckRoleByEmailDocument,
    options,
  )
}
export function useCheckRoleByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckRoleByEmailQuery,
    CheckRoleByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CheckRoleByEmailQuery,
    CheckRoleByEmailQueryVariables
  >(CheckRoleByEmailDocument, options)
}
export function useCheckRoleByEmailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CheckRoleByEmailQuery,
        CheckRoleByEmailQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CheckRoleByEmailQuery,
    CheckRoleByEmailQueryVariables
  >(CheckRoleByEmailDocument, options)
}
export type CheckRoleByEmailQueryHookResult = ReturnType<
  typeof useCheckRoleByEmailQuery
>
export type CheckRoleByEmailLazyQueryHookResult = ReturnType<
  typeof useCheckRoleByEmailLazyQuery
>
export type CheckRoleByEmailSuspenseQueryHookResult = ReturnType<
  typeof useCheckRoleByEmailSuspenseQuery
>
export type CheckRoleByEmailQueryResult = Apollo.QueryResult<
  CheckRoleByEmailQuery,
  CheckRoleByEmailQueryVariables
>
export const CloseContractDocument = gql`
  mutation closeContract($id: ID!) {
    closeContract(id: $id) {
      id
      status
      email
    }
  }
`
export type CloseContractMutationFn = Apollo.MutationFunction<
  CloseContractMutation,
  CloseContractMutationVariables
>

/**
 * __useCloseContractMutation__
 *
 * To run a mutation, you first call `useCloseContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeContractMutation, { data, loading, error }] = useCloseContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseContractMutation,
    CloseContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CloseContractMutation,
    CloseContractMutationVariables
  >(CloseContractDocument, options)
}
export type CloseContractMutationHookResult = ReturnType<
  typeof useCloseContractMutation
>
export type CloseContractMutationResult =
  Apollo.MutationResult<CloseContractMutation>
export type CloseContractMutationOptions = Apollo.BaseMutationOptions<
  CloseContractMutation,
  CloseContractMutationVariables
>
export const CreateContractDocument = gql`
  mutation CreateContract($data: CreateContractInput!) {
    createContract(data: $data) {
      ...ContractItem
    }
  }
  ${ContractItemFragmentDoc}
`
export type CreateContractMutationFn = Apollo.MutationFunction<
  CreateContractMutation,
  CreateContractMutationVariables
>

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContractMutation,
    CreateContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateContractMutation,
    CreateContractMutationVariables
  >(CreateContractDocument, options)
}
export type CreateContractMutationHookResult = ReturnType<
  typeof useCreateContractMutation
>
export type CreateContractMutationResult =
  Apollo.MutationResult<CreateContractMutation>
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<
  CreateContractMutation,
  CreateContractMutationVariables
>
export const CreateMilestoneDocument = gql`
  mutation CreateMilestone(
    $contractId: ID!
    $data: CreateContractMilestoneInput!
  ) {
    createContractMilestone(contractId: $contractId, data: $data) {
      ...MilestoneItem
    }
  }
  ${MilestoneItemFragmentDoc}
`
export type CreateMilestoneMutationFn = Apollo.MutationFunction<
  CreateMilestoneMutation,
  CreateMilestoneMutationVariables
>

/**
 * __useCreateMilestoneMutation__
 *
 * To run a mutation, you first call `useCreateMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMilestoneMutation, { data, loading, error }] = useCreateMilestoneMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMilestoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMilestoneMutation,
    CreateMilestoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateMilestoneMutation,
    CreateMilestoneMutationVariables
  >(CreateMilestoneDocument, options)
}
export type CreateMilestoneMutationHookResult = ReturnType<
  typeof useCreateMilestoneMutation
>
export type CreateMilestoneMutationResult =
  Apollo.MutationResult<CreateMilestoneMutation>
export type CreateMilestoneMutationOptions = Apollo.BaseMutationOptions<
  CreateMilestoneMutation,
  CreateMilestoneMutationVariables
>
export const DeclineContractDocument = gql`
  mutation DeclineContract($data: DeclineContractDto!) {
    declineContract(data: $data) {
      id
    }
  }
`
export type DeclineContractMutationFn = Apollo.MutationFunction<
  DeclineContractMutation,
  DeclineContractMutationVariables
>

/**
 * __useDeclineContractMutation__
 *
 * To run a mutation, you first call `useDeclineContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineContractMutation, { data, loading, error }] = useDeclineContractMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeclineContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineContractMutation,
    DeclineContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeclineContractMutation,
    DeclineContractMutationVariables
  >(DeclineContractDocument, options)
}
export type DeclineContractMutationHookResult = ReturnType<
  typeof useDeclineContractMutation
>
export type DeclineContractMutationResult =
  Apollo.MutationResult<DeclineContractMutation>
export type DeclineContractMutationOptions = Apollo.BaseMutationOptions<
  DeclineContractMutation,
  DeclineContractMutationVariables
>
export const EditContractDocument = gql`
  mutation EditContract($id: ID!, $data: UpdateContractInput!) {
    updateContract(id: $id, data: $data) {
      ...ContractItem
    }
  }
  ${ContractItemFragmentDoc}
`
export type EditContractMutationFn = Apollo.MutationFunction<
  EditContractMutation,
  EditContractMutationVariables
>

/**
 * __useEditContractMutation__
 *
 * To run a mutation, you first call `useEditContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editContractMutation, { data, loading, error }] = useEditContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditContractMutation,
    EditContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EditContractMutation,
    EditContractMutationVariables
  >(EditContractDocument, options)
}
export type EditContractMutationHookResult = ReturnType<
  typeof useEditContractMutation
>
export type EditContractMutationResult =
  Apollo.MutationResult<EditContractMutation>
export type EditContractMutationOptions = Apollo.BaseMutationOptions<
  EditContractMutation,
  EditContractMutationVariables
>
export const GetActiveUserContractsDocument = gql`
  query GetActiveUserContracts {
    getActiveUserContracts {
      id
    }
  }
`

/**
 * __useGetActiveUserContractsQuery__
 *
 * To run a query within a React component, call `useGetActiveUserContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveUserContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveUserContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveUserContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveUserContractsQuery,
    GetActiveUserContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetActiveUserContractsQuery,
    GetActiveUserContractsQueryVariables
  >(GetActiveUserContractsDocument, options)
}
export function useGetActiveUserContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveUserContractsQuery,
    GetActiveUserContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetActiveUserContractsQuery,
    GetActiveUserContractsQueryVariables
  >(GetActiveUserContractsDocument, options)
}
export function useGetActiveUserContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetActiveUserContractsQuery,
        GetActiveUserContractsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetActiveUserContractsQuery,
    GetActiveUserContractsQueryVariables
  >(GetActiveUserContractsDocument, options)
}
export type GetActiveUserContractsQueryHookResult = ReturnType<
  typeof useGetActiveUserContractsQuery
>
export type GetActiveUserContractsLazyQueryHookResult = ReturnType<
  typeof useGetActiveUserContractsLazyQuery
>
export type GetActiveUserContractsSuspenseQueryHookResult = ReturnType<
  typeof useGetActiveUserContractsSuspenseQuery
>
export type GetActiveUserContractsQueryResult = Apollo.QueryResult<
  GetActiveUserContractsQuery,
  GetActiveUserContractsQueryVariables
>
export const GetPendingContractsDocument = gql`
  query GetPendingContracts(
    $after: Int
    $limit: Int
    $subStatus: [ContractSubStatusType!]
  ) {
    contracts(
      after: $after
      limit: $limit
      filter: { contractStatus: PENDING, subStatus: $subStatus }
    ) {
      nodes {
        ...ViewContractItem
      }
      totalCount
      hasNextPage
    }
  }
  ${ViewContractItemFragmentDoc}
`

/**
 * __useGetPendingContractsQuery__
 *
 * To run a query within a React component, call `useGetPendingContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingContractsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *      subStatus: // value for 'subStatus'
 *   },
 * });
 */
export function useGetPendingContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPendingContractsQuery,
    GetPendingContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPendingContractsQuery,
    GetPendingContractsQueryVariables
  >(GetPendingContractsDocument, options)
}
export function useGetPendingContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPendingContractsQuery,
    GetPendingContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPendingContractsQuery,
    GetPendingContractsQueryVariables
  >(GetPendingContractsDocument, options)
}
export function useGetPendingContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPendingContractsQuery,
        GetPendingContractsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetPendingContractsQuery,
    GetPendingContractsQueryVariables
  >(GetPendingContractsDocument, options)
}
export type GetPendingContractsQueryHookResult = ReturnType<
  typeof useGetPendingContractsQuery
>
export type GetPendingContractsLazyQueryHookResult = ReturnType<
  typeof useGetPendingContractsLazyQuery
>
export type GetPendingContractsSuspenseQueryHookResult = ReturnType<
  typeof useGetPendingContractsSuspenseQuery
>
export type GetPendingContractsQueryResult = Apollo.QueryResult<
  GetPendingContractsQuery,
  GetPendingContractsQueryVariables
>
export const GetPendingContractsBackgroundModeDocument = gql`
  query GetPendingContractsBackgroundMode {
    contracts(filter: { contractStatus: PENDING, subStatus: [NEW_OFFER] }) {
      totalCount
    }
  }
`

/**
 * __useGetPendingContractsBackgroundModeQuery__
 *
 * To run a query within a React component, call `useGetPendingContractsBackgroundModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingContractsBackgroundModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingContractsBackgroundModeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPendingContractsBackgroundModeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPendingContractsBackgroundModeQuery,
    GetPendingContractsBackgroundModeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPendingContractsBackgroundModeQuery,
    GetPendingContractsBackgroundModeQueryVariables
  >(GetPendingContractsBackgroundModeDocument, options)
}
export function useGetPendingContractsBackgroundModeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPendingContractsBackgroundModeQuery,
    GetPendingContractsBackgroundModeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPendingContractsBackgroundModeQuery,
    GetPendingContractsBackgroundModeQueryVariables
  >(GetPendingContractsBackgroundModeDocument, options)
}
export function useGetPendingContractsBackgroundModeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPendingContractsBackgroundModeQuery,
        GetPendingContractsBackgroundModeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetPendingContractsBackgroundModeQuery,
    GetPendingContractsBackgroundModeQueryVariables
  >(GetPendingContractsBackgroundModeDocument, options)
}
export type GetPendingContractsBackgroundModeQueryHookResult = ReturnType<
  typeof useGetPendingContractsBackgroundModeQuery
>
export type GetPendingContractsBackgroundModeLazyQueryHookResult = ReturnType<
  typeof useGetPendingContractsBackgroundModeLazyQuery
>
export type GetPendingContractsBackgroundModeSuspenseQueryHookResult =
  ReturnType<typeof useGetPendingContractsBackgroundModeSuspenseQuery>
export type GetPendingContractsBackgroundModeQueryResult = Apollo.QueryResult<
  GetPendingContractsBackgroundModeQuery,
  GetPendingContractsBackgroundModeQueryVariables
>
export const GetOngoingContractsDocument = gql`
  query GetOngoingContracts(
    $after: Int
    $limit: Int
    $subStatus: [ContractSubStatusType!]
  ) {
    contracts(
      after: $after
      limit: $limit
      filter: { contractStatus: ONGOING, subStatus: $subStatus }
    ) {
      nodes {
        ...ViewContractItem
      }
      totalCount
      hasNextPage
    }
  }
  ${ViewContractItemFragmentDoc}
`

/**
 * __useGetOngoingContractsQuery__
 *
 * To run a query within a React component, call `useGetOngoingContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOngoingContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOngoingContractsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *      subStatus: // value for 'subStatus'
 *   },
 * });
 */
export function useGetOngoingContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOngoingContractsQuery,
    GetOngoingContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOngoingContractsQuery,
    GetOngoingContractsQueryVariables
  >(GetOngoingContractsDocument, options)
}
export function useGetOngoingContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOngoingContractsQuery,
    GetOngoingContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOngoingContractsQuery,
    GetOngoingContractsQueryVariables
  >(GetOngoingContractsDocument, options)
}
export function useGetOngoingContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetOngoingContractsQuery,
        GetOngoingContractsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetOngoingContractsQuery,
    GetOngoingContractsQueryVariables
  >(GetOngoingContractsDocument, options)
}
export type GetOngoingContractsQueryHookResult = ReturnType<
  typeof useGetOngoingContractsQuery
>
export type GetOngoingContractsLazyQueryHookResult = ReturnType<
  typeof useGetOngoingContractsLazyQuery
>
export type GetOngoingContractsSuspenseQueryHookResult = ReturnType<
  typeof useGetOngoingContractsSuspenseQuery
>
export type GetOngoingContractsQueryResult = Apollo.QueryResult<
  GetOngoingContractsQuery,
  GetOngoingContractsQueryVariables
>
export const GetArchivedContractsDocument = gql`
  query GetArchivedContracts(
    $after: Int
    $limit: Int
    $subStatus: [ContractSubStatusType!]
  ) {
    contracts(
      after: $after
      limit: $limit
      filter: { contractStatus: ARCHIVE, subStatus: $subStatus }
    ) {
      nodes {
        ...ViewContractItem
      }
      totalCount
      hasNextPage
    }
  }
  ${ViewContractItemFragmentDoc}
`

/**
 * __useGetArchivedContractsQuery__
 *
 * To run a query within a React component, call `useGetArchivedContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedContractsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *      subStatus: // value for 'subStatus'
 *   },
 * });
 */
export function useGetArchivedContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetArchivedContractsQuery,
    GetArchivedContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetArchivedContractsQuery,
    GetArchivedContractsQueryVariables
  >(GetArchivedContractsDocument, options)
}
export function useGetArchivedContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArchivedContractsQuery,
    GetArchivedContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetArchivedContractsQuery,
    GetArchivedContractsQueryVariables
  >(GetArchivedContractsDocument, options)
}
export function useGetArchivedContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetArchivedContractsQuery,
        GetArchivedContractsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetArchivedContractsQuery,
    GetArchivedContractsQueryVariables
  >(GetArchivedContractsDocument, options)
}
export type GetArchivedContractsQueryHookResult = ReturnType<
  typeof useGetArchivedContractsQuery
>
export type GetArchivedContractsLazyQueryHookResult = ReturnType<
  typeof useGetArchivedContractsLazyQuery
>
export type GetArchivedContractsSuspenseQueryHookResult = ReturnType<
  typeof useGetArchivedContractsSuspenseQuery
>
export type GetArchivedContractsQueryResult = Apollo.QueryResult<
  GetArchivedContractsQuery,
  GetArchivedContractsQueryVariables
>
export const GetContractDocument = gql`
  query GetContract($id: ID!) {
    contract(id: $id) {
      ...ViewContractItem
    }
  }
  ${ViewContractItemFragmentDoc}
`

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractQuery,
    GetContractQueryVariables
  > &
    (
      | { variables: GetContractQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options,
  )
}
export function useGetContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractQuery,
    GetContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options,
  )
}
export function useGetContractSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractQuery,
        GetContractQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options,
  )
}
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>
export type GetContractLazyQueryHookResult = ReturnType<
  typeof useGetContractLazyQuery
>
export type GetContractSuspenseQueryHookResult = ReturnType<
  typeof useGetContractSuspenseQuery
>
export type GetContractQueryResult = Apollo.QueryResult<
  GetContractQuery,
  GetContractQueryVariables
>
export const CheckRoleForRegistrationDocument = gql`
  query checkRoleForRegistration {
    checkRoleForRegister {
      roles
    }
  }
`

/**
 * __useCheckRoleForRegistrationQuery__
 *
 * To run a query within a React component, call `useCheckRoleForRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckRoleForRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckRoleForRegistrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckRoleForRegistrationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CheckRoleForRegistrationQuery,
    CheckRoleForRegistrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CheckRoleForRegistrationQuery,
    CheckRoleForRegistrationQueryVariables
  >(CheckRoleForRegistrationDocument, options)
}
export function useCheckRoleForRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckRoleForRegistrationQuery,
    CheckRoleForRegistrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CheckRoleForRegistrationQuery,
    CheckRoleForRegistrationQueryVariables
  >(CheckRoleForRegistrationDocument, options)
}
export function useCheckRoleForRegistrationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CheckRoleForRegistrationQuery,
        CheckRoleForRegistrationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CheckRoleForRegistrationQuery,
    CheckRoleForRegistrationQueryVariables
  >(CheckRoleForRegistrationDocument, options)
}
export type CheckRoleForRegistrationQueryHookResult = ReturnType<
  typeof useCheckRoleForRegistrationQuery
>
export type CheckRoleForRegistrationLazyQueryHookResult = ReturnType<
  typeof useCheckRoleForRegistrationLazyQuery
>
export type CheckRoleForRegistrationSuspenseQueryHookResult = ReturnType<
  typeof useCheckRoleForRegistrationSuspenseQuery
>
export type CheckRoleForRegistrationQueryResult = Apollo.QueryResult<
  CheckRoleForRegistrationQuery,
  CheckRoleForRegistrationQueryVariables
>
export const RemoveContractDocument = gql`
  mutation RemoveContract($id: ID!) {
    removeContract(id: $id)
  }
`
export type RemoveContractMutationFn = Apollo.MutationFunction<
  RemoveContractMutation,
  RemoveContractMutationVariables
>

/**
 * __useRemoveContractMutation__
 *
 * To run a mutation, you first call `useRemoveContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContractMutation, { data, loading, error }] = useRemoveContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveContractMutation,
    RemoveContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveContractMutation,
    RemoveContractMutationVariables
  >(RemoveContractDocument, options)
}
export type RemoveContractMutationHookResult = ReturnType<
  typeof useRemoveContractMutation
>
export type RemoveContractMutationResult =
  Apollo.MutationResult<RemoveContractMutation>
export type RemoveContractMutationOptions = Apollo.BaseMutationOptions<
  RemoveContractMutation,
  RemoveContractMutationVariables
>
export const SendContractDocument = gql`
  mutation SendContract($data: CreateContractSendDto!) {
    sendContract(data: $data) {
      id
    }
  }
`
export type SendContractMutationFn = Apollo.MutationFunction<
  SendContractMutation,
  SendContractMutationVariables
>

/**
 * __useSendContractMutation__
 *
 * To run a mutation, you first call `useSendContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContractMutation, { data, loading, error }] = useSendContractMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendContractMutation,
    SendContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendContractMutation,
    SendContractMutationVariables
  >(SendContractDocument, options)
}
export type SendContractMutationHookResult = ReturnType<
  typeof useSendContractMutation
>
export type SendContractMutationResult =
  Apollo.MutationResult<SendContractMutation>
export type SendContractMutationOptions = Apollo.BaseMutationOptions<
  SendContractMutation,
  SendContractMutationVariables
>
export const ClaimFundsDocument = gql`
  mutation claimFunds($depositId: Int, $milestoneIds: [Int!]) {
    claimFunds(data: { depositId: $depositId, milestoneIds: $milestoneIds }) {
      message
    }
  }
`
export type ClaimFundsMutationFn = Apollo.MutationFunction<
  ClaimFundsMutation,
  ClaimFundsMutationVariables
>

/**
 * __useClaimFundsMutation__
 *
 * To run a mutation, you first call `useClaimFundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimFundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimFundsMutation, { data, loading, error }] = useClaimFundsMutation({
 *   variables: {
 *      depositId: // value for 'depositId'
 *      milestoneIds: // value for 'milestoneIds'
 *   },
 * });
 */
export function useClaimFundsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimFundsMutation,
    ClaimFundsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClaimFundsMutation, ClaimFundsMutationVariables>(
    ClaimFundsDocument,
    options,
  )
}
export type ClaimFundsMutationHookResult = ReturnType<
  typeof useClaimFundsMutation
>
export type ClaimFundsMutationResult = Apollo.MutationResult<ClaimFundsMutation>
export type ClaimFundsMutationOptions = Apollo.BaseMutationOptions<
  ClaimFundsMutation,
  ClaimFundsMutationVariables
>
export const CreateDepositDocument = gql`
  mutation CreateDeposit($contractId: Int, $milestoneIds: [Int!]) {
    createDeposit(
      data: { contractId: $contractId, milestoneIds: $milestoneIds }
    ) {
      depositId
      freelancerSecretHash
    }
  }
`
export type CreateDepositMutationFn = Apollo.MutationFunction<
  CreateDepositMutation,
  CreateDepositMutationVariables
>

/**
 * __useCreateDepositMutation__
 *
 * To run a mutation, you first call `useCreateDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepositMutation, { data, loading, error }] = useCreateDepositMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      milestoneIds: // value for 'milestoneIds'
 *   },
 * });
 */
export function useCreateDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDepositMutation,
    CreateDepositMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDepositMutation,
    CreateDepositMutationVariables
  >(CreateDepositDocument, options)
}
export type CreateDepositMutationHookResult = ReturnType<
  typeof useCreateDepositMutation
>
export type CreateDepositMutationResult =
  Apollo.MutationResult<CreateDepositMutation>
export type CreateDepositMutationOptions = Apollo.BaseMutationOptions<
  CreateDepositMutation,
  CreateDepositMutationVariables
>
export const GetDepositDocument = gql`
  query getDeposit($contractId: Int, $milestoneId: Int, $workingWeekId: Int) {
    getDeposit(
      data: {
        contractId: $contractId
        milestoneId: $milestoneId
        workingWeekId: $workingWeekId
      }
    ) {
      depositId
      freelancerSecret
      freelancerSecretHash
      contractBudget
      inEscrow
      inEscrowRemainder
      availableAmount
      disputeResolveAmount
      amountToWithdraw
      amountToClaim
      received
    }
  }
`

/**
 * __useGetDepositQuery__
 *
 * To run a query within a React component, call `useGetDepositQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepositQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepositQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      milestoneId: // value for 'milestoneId'
 *      workingWeekId: // value for 'workingWeekId'
 *   },
 * });
 */
export function useGetDepositQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDepositQuery,
    GetDepositQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDepositQuery, GetDepositQueryVariables>(
    GetDepositDocument,
    options,
  )
}
export function useGetDepositLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDepositQuery,
    GetDepositQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDepositQuery, GetDepositQueryVariables>(
    GetDepositDocument,
    options,
  )
}
export function useGetDepositSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetDepositQuery,
        GetDepositQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetDepositQuery, GetDepositQueryVariables>(
    GetDepositDocument,
    options,
  )
}
export type GetDepositQueryHookResult = ReturnType<typeof useGetDepositQuery>
export type GetDepositLazyQueryHookResult = ReturnType<
  typeof useGetDepositLazyQuery
>
export type GetDepositSuspenseQueryHookResult = ReturnType<
  typeof useGetDepositSuspenseQuery
>
export type GetDepositQueryResult = Apollo.QueryResult<
  GetDepositQuery,
  GetDepositQueryVariables
>
export const ApproveDisputeDocument = gql`
  mutation ApproveDispute($id: Int!) {
    approveDispute(id: $id) {
      ...DisputeItem
    }
  }
  ${DisputeItemFragmentDoc}
`
export type ApproveDisputeMutationFn = Apollo.MutationFunction<
  ApproveDisputeMutation,
  ApproveDisputeMutationVariables
>

/**
 * __useApproveDisputeMutation__
 *
 * To run a mutation, you first call `useApproveDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveDisputeMutation, { data, loading, error }] = useApproveDisputeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveDisputeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveDisputeMutation,
    ApproveDisputeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ApproveDisputeMutation,
    ApproveDisputeMutationVariables
  >(ApproveDisputeDocument, options)
}
export type ApproveDisputeMutationHookResult = ReturnType<
  typeof useApproveDisputeMutation
>
export type ApproveDisputeMutationResult =
  Apollo.MutationResult<ApproveDisputeMutation>
export type ApproveDisputeMutationOptions = Apollo.BaseMutationOptions<
  ApproveDisputeMutation,
  ApproveDisputeMutationVariables
>
export const CancelDisputeDocument = gql`
  mutation CancelDispute($id: Int!) {
    cancelDispute(id: $id) {
      ...DisputeItem
    }
  }
  ${DisputeItemFragmentDoc}
`
export type CancelDisputeMutationFn = Apollo.MutationFunction<
  CancelDisputeMutation,
  CancelDisputeMutationVariables
>

/**
 * __useCancelDisputeMutation__
 *
 * To run a mutation, you first call `useCancelDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDisputeMutation, { data, loading, error }] = useCancelDisputeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelDisputeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelDisputeMutation,
    CancelDisputeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelDisputeMutation,
    CancelDisputeMutationVariables
  >(CancelDisputeDocument, options)
}
export type CancelDisputeMutationHookResult = ReturnType<
  typeof useCancelDisputeMutation
>
export type CancelDisputeMutationResult =
  Apollo.MutationResult<CancelDisputeMutation>
export type CancelDisputeMutationOptions = Apollo.BaseMutationOptions<
  CancelDisputeMutation,
  CancelDisputeMutationVariables
>
export const CreateDisputeHourlyOnlyHoursDocument = gql`
  mutation createDisputeHourlyOnlyHours(
    $data: CreateDisputeHourlyOnlyTimeInput!
  ) {
    createDisputeHourlyOnlyHours(data: $data) {
      ...disputeViewOutput
    }
  }
  ${DisputeViewOutputFragmentDoc}
`
export type CreateDisputeHourlyOnlyHoursMutationFn = Apollo.MutationFunction<
  CreateDisputeHourlyOnlyHoursMutation,
  CreateDisputeHourlyOnlyHoursMutationVariables
>

/**
 * __useCreateDisputeHourlyOnlyHoursMutation__
 *
 * To run a mutation, you first call `useCreateDisputeHourlyOnlyHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisputeHourlyOnlyHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisputeHourlyOnlyHoursMutation, { data, loading, error }] = useCreateDisputeHourlyOnlyHoursMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDisputeHourlyOnlyHoursMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDisputeHourlyOnlyHoursMutation,
    CreateDisputeHourlyOnlyHoursMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDisputeHourlyOnlyHoursMutation,
    CreateDisputeHourlyOnlyHoursMutationVariables
  >(CreateDisputeHourlyOnlyHoursDocument, options)
}
export type CreateDisputeHourlyOnlyHoursMutationHookResult = ReturnType<
  typeof useCreateDisputeHourlyOnlyHoursMutation
>
export type CreateDisputeHourlyOnlyHoursMutationResult =
  Apollo.MutationResult<CreateDisputeHourlyOnlyHoursMutation>
export type CreateDisputeHourlyOnlyHoursMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDisputeHourlyOnlyHoursMutation,
    CreateDisputeHourlyOnlyHoursMutationVariables
  >
export const DisputeListDocument = gql`
  query DisputeList($filter: DisputeListParamsInput!) {
    disputeList(filter: $filter) {
      ...DisputeListViewOutputPaginatedItem
    }
  }
  ${DisputeListViewOutputPaginatedItemFragmentDoc}
`

/**
 * __useDisputeListQuery__
 *
 * To run a query within a React component, call `useDisputeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisputeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisputeListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDisputeListQuery(
  baseOptions: Apollo.QueryHookOptions<
    DisputeListQuery,
    DisputeListQueryVariables
  > &
    (
      | { variables: DisputeListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DisputeListQuery, DisputeListQueryVariables>(
    DisputeListDocument,
    options,
  )
}
export function useDisputeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DisputeListQuery,
    DisputeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DisputeListQuery, DisputeListQueryVariables>(
    DisputeListDocument,
    options,
  )
}
export function useDisputeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DisputeListQuery,
        DisputeListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DisputeListQuery, DisputeListQueryVariables>(
    DisputeListDocument,
    options,
  )
}
export type DisputeListQueryHookResult = ReturnType<typeof useDisputeListQuery>
export type DisputeListLazyQueryHookResult = ReturnType<
  typeof useDisputeListLazyQuery
>
export type DisputeListSuspenseQueryHookResult = ReturnType<
  typeof useDisputeListSuspenseQuery
>
export type DisputeListQueryResult = Apollo.QueryResult<
  DisputeListQuery,
  DisputeListQueryVariables
>
export const DisputeListWidgetDocument = gql`
  query DisputeListWidget($filter: DisputeListParamsInput!) {
    disputeList(filter: $filter) {
      totalCount
      hasNextPage
      nodes {
        id
        status
        type
        transactions {
          transactionType
        }
      }
    }
  }
`

/**
 * __useDisputeListWidgetQuery__
 *
 * To run a query within a React component, call `useDisputeListWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisputeListWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisputeListWidgetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDisputeListWidgetQuery(
  baseOptions: Apollo.QueryHookOptions<
    DisputeListWidgetQuery,
    DisputeListWidgetQueryVariables
  > &
    (
      | { variables: DisputeListWidgetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    DisputeListWidgetQuery,
    DisputeListWidgetQueryVariables
  >(DisputeListWidgetDocument, options)
}
export function useDisputeListWidgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DisputeListWidgetQuery,
    DisputeListWidgetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DisputeListWidgetQuery,
    DisputeListWidgetQueryVariables
  >(DisputeListWidgetDocument, options)
}
export function useDisputeListWidgetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DisputeListWidgetQuery,
        DisputeListWidgetQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    DisputeListWidgetQuery,
    DisputeListWidgetQueryVariables
  >(DisputeListWidgetDocument, options)
}
export type DisputeListWidgetQueryHookResult = ReturnType<
  typeof useDisputeListWidgetQuery
>
export type DisputeListWidgetLazyQueryHookResult = ReturnType<
  typeof useDisputeListWidgetLazyQuery
>
export type DisputeListWidgetSuspenseQueryHookResult = ReturnType<
  typeof useDisputeListWidgetSuspenseQuery
>
export type DisputeListWidgetQueryResult = Apollo.QueryResult<
  DisputeListWidgetQuery,
  DisputeListWidgetQueryVariables
>
export const FindOneDisputeDocument = gql`
  query FindOneDispute($id: Int!) {
    findOneDispute(id: $id) {
      ...disputeViewOutput
    }
  }
  ${DisputeViewOutputFragmentDoc}
`

/**
 * __useFindOneDisputeQuery__
 *
 * To run a query within a React component, call `useFindOneDisputeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneDisputeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneDisputeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindOneDisputeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindOneDisputeQuery,
    FindOneDisputeQueryVariables
  > &
    (
      | { variables: FindOneDisputeQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindOneDisputeQuery, FindOneDisputeQueryVariables>(
    FindOneDisputeDocument,
    options,
  )
}
export function useFindOneDisputeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindOneDisputeQuery,
    FindOneDisputeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindOneDisputeQuery, FindOneDisputeQueryVariables>(
    FindOneDisputeDocument,
    options,
  )
}
export function useFindOneDisputeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindOneDisputeQuery,
        FindOneDisputeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    FindOneDisputeQuery,
    FindOneDisputeQueryVariables
  >(FindOneDisputeDocument, options)
}
export type FindOneDisputeQueryHookResult = ReturnType<
  typeof useFindOneDisputeQuery
>
export type FindOneDisputeLazyQueryHookResult = ReturnType<
  typeof useFindOneDisputeLazyQuery
>
export type FindOneDisputeSuspenseQueryHookResult = ReturnType<
  typeof useFindOneDisputeSuspenseQuery
>
export type FindOneDisputeQueryResult = Apollo.QueryResult<
  FindOneDisputeQuery,
  FindOneDisputeQueryVariables
>
export const RejectDisputeDocument = gql`
  mutation RejectDispute($id: Int!, $message: String!) {
    rejectDispute(id: $id, message: $message) {
      ...disputeViewOutput
      ticketLink
    }
  }
  ${DisputeViewOutputFragmentDoc}
`
export type RejectDisputeMutationFn = Apollo.MutationFunction<
  RejectDisputeMutation,
  RejectDisputeMutationVariables
>

/**
 * __useRejectDisputeMutation__
 *
 * To run a mutation, you first call `useRejectDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectDisputeMutation, { data, loading, error }] = useRejectDisputeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useRejectDisputeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectDisputeMutation,
    RejectDisputeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RejectDisputeMutation,
    RejectDisputeMutationVariables
  >(RejectDisputeDocument, options)
}
export type RejectDisputeMutationHookResult = ReturnType<
  typeof useRejectDisputeMutation
>
export type RejectDisputeMutationResult =
  Apollo.MutationResult<RejectDisputeMutation>
export type RejectDisputeMutationOptions = Apollo.BaseMutationOptions<
  RejectDisputeMutation,
  RejectDisputeMutationVariables
>
export const SendContractorDisputeDocument = gql`
  mutation sendContractorDispute($data: SendDisputeContractorInput!) {
    sendContractorDispute(data: $data) {
      ...DisputeItem
    }
  }
  ${DisputeItemFragmentDoc}
`
export type SendContractorDisputeMutationFn = Apollo.MutationFunction<
  SendContractorDisputeMutation,
  SendContractorDisputeMutationVariables
>

/**
 * __useSendContractorDisputeMutation__
 *
 * To run a mutation, you first call `useSendContractorDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContractorDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContractorDisputeMutation, { data, loading, error }] = useSendContractorDisputeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendContractorDisputeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendContractorDisputeMutation,
    SendContractorDisputeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendContractorDisputeMutation,
    SendContractorDisputeMutationVariables
  >(SendContractorDisputeDocument, options)
}
export type SendContractorDisputeMutationHookResult = ReturnType<
  typeof useSendContractorDisputeMutation
>
export type SendContractorDisputeMutationResult =
  Apollo.MutationResult<SendContractorDisputeMutation>
export type SendContractorDisputeMutationOptions = Apollo.BaseMutationOptions<
  SendContractorDisputeMutation,
  SendContractorDisputeMutationVariables
>
export const SendDisputeRequestDocument = gql`
  mutation SendDisputeRequest($data: SendDisputeRequestInput!) {
    sendDisputeRequest(data: $data) {
      ...DisputeItem
    }
  }
  ${DisputeItemFragmentDoc}
`
export type SendDisputeRequestMutationFn = Apollo.MutationFunction<
  SendDisputeRequestMutation,
  SendDisputeRequestMutationVariables
>

/**
 * __useSendDisputeRequestMutation__
 *
 * To run a mutation, you first call `useSendDisputeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDisputeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDisputeRequestMutation, { data, loading, error }] = useSendDisputeRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendDisputeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendDisputeRequestMutation,
    SendDisputeRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendDisputeRequestMutation,
    SendDisputeRequestMutationVariables
  >(SendDisputeRequestDocument, options)
}
export type SendDisputeRequestMutationHookResult = ReturnType<
  typeof useSendDisputeRequestMutation
>
export type SendDisputeRequestMutationResult =
  Apollo.MutationResult<SendDisputeRequestMutation>
export type SendDisputeRequestMutationOptions = Apollo.BaseMutationOptions<
  SendDisputeRequestMutation,
  SendDisputeRequestMutationVariables
>
export const FindOneDisputeByWorkingWeekIdDocument = gql`
  query FindOneDisputeByWorkingWeekId($workingWeekId: Int!) {
    findOneDisputeByWorkingWeekId(workingWeekId: $workingWeekId) {
      ...disputeViewOutput
    }
  }
  ${DisputeViewOutputFragmentDoc}
`

/**
 * __useFindOneDisputeByWorkingWeekIdQuery__
 *
 * To run a query within a React component, call `useFindOneDisputeByWorkingWeekIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneDisputeByWorkingWeekIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneDisputeByWorkingWeekIdQuery({
 *   variables: {
 *      workingWeekId: // value for 'workingWeekId'
 *   },
 * });
 */
export function useFindOneDisputeByWorkingWeekIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindOneDisputeByWorkingWeekIdQuery,
    FindOneDisputeByWorkingWeekIdQueryVariables
  > &
    (
      | {
          variables: FindOneDisputeByWorkingWeekIdQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindOneDisputeByWorkingWeekIdQuery,
    FindOneDisputeByWorkingWeekIdQueryVariables
  >(FindOneDisputeByWorkingWeekIdDocument, options)
}
export function useFindOneDisputeByWorkingWeekIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindOneDisputeByWorkingWeekIdQuery,
    FindOneDisputeByWorkingWeekIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindOneDisputeByWorkingWeekIdQuery,
    FindOneDisputeByWorkingWeekIdQueryVariables
  >(FindOneDisputeByWorkingWeekIdDocument, options)
}
export function useFindOneDisputeByWorkingWeekIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindOneDisputeByWorkingWeekIdQuery,
        FindOneDisputeByWorkingWeekIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    FindOneDisputeByWorkingWeekIdQuery,
    FindOneDisputeByWorkingWeekIdQueryVariables
  >(FindOneDisputeByWorkingWeekIdDocument, options)
}
export type FindOneDisputeByWorkingWeekIdQueryHookResult = ReturnType<
  typeof useFindOneDisputeByWorkingWeekIdQuery
>
export type FindOneDisputeByWorkingWeekIdLazyQueryHookResult = ReturnType<
  typeof useFindOneDisputeByWorkingWeekIdLazyQuery
>
export type FindOneDisputeByWorkingWeekIdSuspenseQueryHookResult = ReturnType<
  typeof useFindOneDisputeByWorkingWeekIdSuspenseQuery
>
export type FindOneDisputeByWorkingWeekIdQueryResult = Apollo.QueryResult<
  FindOneDisputeByWorkingWeekIdQuery,
  FindOneDisputeByWorkingWeekIdQueryVariables
>
export const GetWeekReportDataDocument = gql`
  query getWeekReportData($id: Int!) {
    getWeekReport(id: $id) {
      id
      contractId
      description
      escrowWeekId
      start
      end
      limit
      totalTime
      totalBudget
      resolutionTime
      resolutionBudget
      status
      subStatus
      workingDays {
        id
        workingTime
        description
        day
        isEnabled
        isEditable
      }
      submitWork {
        id
        workingWeekId
      }
      transactions {
        amount
        hash
        currency
        status
        link
        transactionId
        transactionType
        createdAt
        userAccount
      }
    }
  }
`

/**
 * __useGetWeekReportDataQuery__
 *
 * To run a query within a React component, call `useGetWeekReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeekReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeekReportDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWeekReportDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWeekReportDataQuery,
    GetWeekReportDataQueryVariables
  > &
    (
      | { variables: GetWeekReportDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetWeekReportDataQuery,
    GetWeekReportDataQueryVariables
  >(GetWeekReportDataDocument, options)
}
export function useGetWeekReportDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWeekReportDataQuery,
    GetWeekReportDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWeekReportDataQuery,
    GetWeekReportDataQueryVariables
  >(GetWeekReportDataDocument, options)
}
export function useGetWeekReportDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetWeekReportDataQuery,
        GetWeekReportDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetWeekReportDataQuery,
    GetWeekReportDataQueryVariables
  >(GetWeekReportDataDocument, options)
}
export type GetWeekReportDataQueryHookResult = ReturnType<
  typeof useGetWeekReportDataQuery
>
export type GetWeekReportDataLazyQueryHookResult = ReturnType<
  typeof useGetWeekReportDataLazyQuery
>
export type GetWeekReportDataSuspenseQueryHookResult = ReturnType<
  typeof useGetWeekReportDataSuspenseQuery
>
export type GetWeekReportDataQueryResult = Apollo.QueryResult<
  GetWeekReportDataQuery,
  GetWeekReportDataQueryVariables
>
export const GetWeekReportListDocument = gql`
  query GetWeekReportList($id: Int!) {
    weekReportList(contractId: $id) {
      id
      contractId
      description
      start
      end
      status
      rate
      subStatus
      totalTime
      totalBudget
      limit
      workingDays {
        id
        workingTime
        day
        description
      }
      disputes {
        disputedTime
        resolutionTime
        resolutionDate
      }
      resolutionTime
      resolutionBudget
    }
  }
`

/**
 * __useGetWeekReportListQuery__
 *
 * To run a query within a React component, call `useGetWeekReportListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeekReportListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeekReportListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWeekReportListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWeekReportListQuery,
    GetWeekReportListQueryVariables
  > &
    (
      | { variables: GetWeekReportListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetWeekReportListQuery,
    GetWeekReportListQueryVariables
  >(GetWeekReportListDocument, options)
}
export function useGetWeekReportListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWeekReportListQuery,
    GetWeekReportListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWeekReportListQuery,
    GetWeekReportListQueryVariables
  >(GetWeekReportListDocument, options)
}
export function useGetWeekReportListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetWeekReportListQuery,
        GetWeekReportListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetWeekReportListQuery,
    GetWeekReportListQueryVariables
  >(GetWeekReportListDocument, options)
}
export type GetWeekReportListQueryHookResult = ReturnType<
  typeof useGetWeekReportListQuery
>
export type GetWeekReportListLazyQueryHookResult = ReturnType<
  typeof useGetWeekReportListLazyQuery
>
export type GetWeekReportListSuspenseQueryHookResult = ReturnType<
  typeof useGetWeekReportListSuspenseQuery
>
export type GetWeekReportListQueryResult = Apollo.QueryResult<
  GetWeekReportListQuery,
  GetWeekReportListQueryVariables
>
export const SetWorkingDayDocument = gql`
  mutation SetWorkingDay($data: SetWorkingDayInput!) {
    setWorkingDay(data: $data) {
      id
      workingTime
      workingWeekId
      description
      day
    }
  }
`
export type SetWorkingDayMutationFn = Apollo.MutationFunction<
  SetWorkingDayMutation,
  SetWorkingDayMutationVariables
>

/**
 * __useSetWorkingDayMutation__
 *
 * To run a mutation, you first call `useSetWorkingDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkingDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkingDayMutation, { data, loading, error }] = useSetWorkingDayMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetWorkingDayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetWorkingDayMutation,
    SetWorkingDayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetWorkingDayMutation,
    SetWorkingDayMutationVariables
  >(SetWorkingDayDocument, options)
}
export type SetWorkingDayMutationHookResult = ReturnType<
  typeof useSetWorkingDayMutation
>
export type SetWorkingDayMutationResult =
  Apollo.MutationResult<SetWorkingDayMutation>
export type SetWorkingDayMutationOptions = Apollo.BaseMutationOptions<
  SetWorkingDayMutation,
  SetWorkingDayMutationVariables
>
export const GetNotificationsDocument = gql`
  query GetNotifications($after: Int!, $limit: Int!) {
    notifications(after: $after, limit: $limit) {
      totalCount
      hasNextPage
      nodes {
        id
        event
        type
        status
        userId
        data
        createdAt
      }
    }
  }
`

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  > &
    (
      | { variables: GetNotificationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options,
  )
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >(GetNotificationsDocument, options)
}
export function useGetNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetNotificationsQuery,
        GetNotificationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >(GetNotificationsDocument, options)
}
export type GetNotificationsQueryHookResult = ReturnType<
  typeof useGetNotificationsQuery
>
export type GetNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetNotificationsLazyQuery
>
export type GetNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useGetNotificationsSuspenseQuery
>
export type GetNotificationsQueryResult = Apollo.QueryResult<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>
export const MarkAllAsSeenDocument = gql`
  mutation markAllAsSeen {
    markAllAsSeen {
      id
      event
      type
      status
      userId
      data
      createdAt
    }
  }
`
export type MarkAllAsSeenMutationFn = Apollo.MutationFunction<
  MarkAllAsSeenMutation,
  MarkAllAsSeenMutationVariables
>

/**
 * __useMarkAllAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkAllAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllAsSeenMutation, { data, loading, error }] = useMarkAllAsSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllAsSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAllAsSeenMutation,
    MarkAllAsSeenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkAllAsSeenMutation,
    MarkAllAsSeenMutationVariables
  >(MarkAllAsSeenDocument, options)
}
export type MarkAllAsSeenMutationHookResult = ReturnType<
  typeof useMarkAllAsSeenMutation
>
export type MarkAllAsSeenMutationResult =
  Apollo.MutationResult<MarkAllAsSeenMutation>
export type MarkAllAsSeenMutationOptions = Apollo.BaseMutationOptions<
  MarkAllAsSeenMutation,
  MarkAllAsSeenMutationVariables
>
export const UpdateNotificationDocument = gql`
  mutation updateNotification($id: ID!, $data: UpdateNotificationInput!) {
    updateNotification(id: $id, data: $data) {
      id
      event
      type
      status
      userId
      data
      createdAt
    }
  }
`
export type UpdateNotificationMutationFn = Apollo.MutationFunction<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >(UpdateNotificationDocument, options)
}
export type UpdateNotificationMutationHookResult = ReturnType<
  typeof useUpdateNotificationMutation
>
export type UpdateNotificationMutationResult =
  Apollo.MutationResult<UpdateNotificationMutation>
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>
export const CreateAddressDocument = gql`
  mutation createAddress($data: CreateAddressInput!) {
    createAddress(data: $data) {
      id
      country
      state
      city
      line1
      line2
      zip
    }
  }
`
export type CreateAddressMutationFn = Apollo.MutationFunction<
  CreateAddressMutation,
  CreateAddressMutationVariables
>

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAddressMutation,
    CreateAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAddressMutation,
    CreateAddressMutationVariables
  >(CreateAddressDocument, options)
}
export type CreateAddressMutationHookResult = ReturnType<
  typeof useCreateAddressMutation
>
export type CreateAddressMutationResult =
  Apollo.MutationResult<CreateAddressMutation>
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateAddressMutation,
  CreateAddressMutationVariables
>
export const CreateCompanyDocument = gql`
  mutation createCompany($data: CreateCompanyInput!) {
    createCompany(data: $data) {
      id
      name
      size
    }
  }
`
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >(CreateCompanyDocument, options)
}
export type CreateCompanyMutationHookResult = ReturnType<
  typeof useCreateCompanyMutation
>
export type CreateCompanyMutationResult =
  Apollo.MutationResult<CreateCompanyMutation>
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>
export const CreateProfileDocument = gql`
  mutation createProfile($data: CreateProfileInput!) {
    createProfile(data: $data) {
      id
      firstName
      middleName
      lastName
    }
  }
`
export type CreateProfileMutationFn = Apollo.MutationFunction<
  CreateProfileMutation,
  CreateProfileMutationVariables
>

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProfileMutation,
    CreateProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateProfileMutation,
    CreateProfileMutationVariables
  >(CreateProfileDocument, options)
}
export type CreateProfileMutationHookResult = ReturnType<
  typeof useCreateProfileMutation
>
export type CreateProfileMutationResult =
  Apollo.MutationResult<CreateProfileMutation>
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<
  CreateProfileMutation,
  CreateProfileMutationVariables
>
export const FindCompaniesByContractIdDocument = gql`
  query findCompaniesByContractId($contractId: Int!) {
    findCompaniesByContractId(contractId: $contractId) {
      clientCompany {
        id
        name
        size
        addressId
        profileId
        address {
          id
          country
          state
          city
          line1
          line2
          zip
          profileId
          companyId
        }
      }
      contractorCompany {
        id
        name
        size
        addressId
        profileId
        address {
          id
          country
          state
          city
          line1
          line2
          zip
          profileId
          companyId
        }
      }
    }
  }
`

/**
 * __useFindCompaniesByContractIdQuery__
 *
 * To run a query within a React component, call `useFindCompaniesByContractIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCompaniesByContractIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCompaniesByContractIdQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useFindCompaniesByContractIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCompaniesByContractIdQuery,
    FindCompaniesByContractIdQueryVariables
  > &
    (
      | { variables: FindCompaniesByContractIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCompaniesByContractIdQuery,
    FindCompaniesByContractIdQueryVariables
  >(FindCompaniesByContractIdDocument, options)
}
export function useFindCompaniesByContractIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCompaniesByContractIdQuery,
    FindCompaniesByContractIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCompaniesByContractIdQuery,
    FindCompaniesByContractIdQueryVariables
  >(FindCompaniesByContractIdDocument, options)
}
export function useFindCompaniesByContractIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindCompaniesByContractIdQuery,
        FindCompaniesByContractIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    FindCompaniesByContractIdQuery,
    FindCompaniesByContractIdQueryVariables
  >(FindCompaniesByContractIdDocument, options)
}
export type FindCompaniesByContractIdQueryHookResult = ReturnType<
  typeof useFindCompaniesByContractIdQuery
>
export type FindCompaniesByContractIdLazyQueryHookResult = ReturnType<
  typeof useFindCompaniesByContractIdLazyQuery
>
export type FindCompaniesByContractIdSuspenseQueryHookResult = ReturnType<
  typeof useFindCompaniesByContractIdSuspenseQuery
>
export type FindCompaniesByContractIdQueryResult = Apollo.QueryResult<
  FindCompaniesByContractIdQuery,
  FindCompaniesByContractIdQueryVariables
>
export const FindContractAssigneesAddressesDocument = gql`
  query findContractAssigneesAddresses($contractId: Int!) {
    findContractAssigneesAddresses(contractId: $contractId) {
      clientAddress {
        id
        country
        state
        city
        line1
        line2
        zip
        profileId
        companyId
      }
      contractorAddress {
        id
        country
        state
        city
        line1
        line2
        zip
        profileId
        companyId
      }
    }
  }
`

/**
 * __useFindContractAssigneesAddressesQuery__
 *
 * To run a query within a React component, call `useFindContractAssigneesAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindContractAssigneesAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindContractAssigneesAddressesQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useFindContractAssigneesAddressesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindContractAssigneesAddressesQuery,
    FindContractAssigneesAddressesQueryVariables
  > &
    (
      | {
          variables: FindContractAssigneesAddressesQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindContractAssigneesAddressesQuery,
    FindContractAssigneesAddressesQueryVariables
  >(FindContractAssigneesAddressesDocument, options)
}
export function useFindContractAssigneesAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindContractAssigneesAddressesQuery,
    FindContractAssigneesAddressesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindContractAssigneesAddressesQuery,
    FindContractAssigneesAddressesQueryVariables
  >(FindContractAssigneesAddressesDocument, options)
}
export function useFindContractAssigneesAddressesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindContractAssigneesAddressesQuery,
        FindContractAssigneesAddressesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    FindContractAssigneesAddressesQuery,
    FindContractAssigneesAddressesQueryVariables
  >(FindContractAssigneesAddressesDocument, options)
}
export type FindContractAssigneesAddressesQueryHookResult = ReturnType<
  typeof useFindContractAssigneesAddressesQuery
>
export type FindContractAssigneesAddressesLazyQueryHookResult = ReturnType<
  typeof useFindContractAssigneesAddressesLazyQuery
>
export type FindContractAssigneesAddressesSuspenseQueryHookResult = ReturnType<
  typeof useFindContractAssigneesAddressesSuspenseQuery
>
export type FindContractAssigneesAddressesQueryResult = Apollo.QueryResult<
  FindContractAssigneesAddressesQuery,
  FindContractAssigneesAddressesQueryVariables
>
export const FindMyAddressDocument = gql`
  query findMyAddress($isCompanyProfile: Boolean!) {
    findMyAddress(isCompanyProfile: $isCompanyProfile) {
      id
      country
      state
      city
      line1
      line2
      zip
    }
  }
`

/**
 * __useFindMyAddressQuery__
 *
 * To run a query within a React component, call `useFindMyAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyAddressQuery({
 *   variables: {
 *      isCompanyProfile: // value for 'isCompanyProfile'
 *   },
 * });
 */
export function useFindMyAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindMyAddressQuery,
    FindMyAddressQueryVariables
  > &
    (
      | { variables: FindMyAddressQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindMyAddressQuery, FindMyAddressQueryVariables>(
    FindMyAddressDocument,
    options,
  )
}
export function useFindMyAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindMyAddressQuery,
    FindMyAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindMyAddressQuery, FindMyAddressQueryVariables>(
    FindMyAddressDocument,
    options,
  )
}
export function useFindMyAddressSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindMyAddressQuery,
        FindMyAddressQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    FindMyAddressQuery,
    FindMyAddressQueryVariables
  >(FindMyAddressDocument, options)
}
export type FindMyAddressQueryHookResult = ReturnType<
  typeof useFindMyAddressQuery
>
export type FindMyAddressLazyQueryHookResult = ReturnType<
  typeof useFindMyAddressLazyQuery
>
export type FindMyAddressSuspenseQueryHookResult = ReturnType<
  typeof useFindMyAddressSuspenseQuery
>
export type FindMyAddressQueryResult = Apollo.QueryResult<
  FindMyAddressQuery,
  FindMyAddressQueryVariables
>
export const FindMyCompanyDocument = gql`
  query findMyCompany {
    findMyCompany {
      id
      name
      size
    }
  }
`

/**
 * __useFindMyCompanyQuery__
 *
 * To run a query within a React component, call `useFindMyCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindMyCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindMyCompanyQuery,
    FindMyCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindMyCompanyQuery, FindMyCompanyQueryVariables>(
    FindMyCompanyDocument,
    options,
  )
}
export function useFindMyCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindMyCompanyQuery,
    FindMyCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindMyCompanyQuery, FindMyCompanyQueryVariables>(
    FindMyCompanyDocument,
    options,
  )
}
export function useFindMyCompanySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindMyCompanyQuery,
        FindMyCompanyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    FindMyCompanyQuery,
    FindMyCompanyQueryVariables
  >(FindMyCompanyDocument, options)
}
export type FindMyCompanyQueryHookResult = ReturnType<
  typeof useFindMyCompanyQuery
>
export type FindMyCompanyLazyQueryHookResult = ReturnType<
  typeof useFindMyCompanyLazyQuery
>
export type FindMyCompanySuspenseQueryHookResult = ReturnType<
  typeof useFindMyCompanySuspenseQuery
>
export type FindMyCompanyQueryResult = Apollo.QueryResult<
  FindMyCompanyQuery,
  FindMyCompanyQueryVariables
>
export const GetMyProfileDocument = gql`
  query getMyProfile {
    findMyProfile {
      id
      firstName
      middleName
      lastName
    }
  }
`

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyProfileQuery,
    GetMyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options,
  )
}
export function useGetMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyProfileQuery,
    GetMyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options,
  )
}
export function useGetMyProfileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMyProfileQuery,
        GetMyProfileQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options,
  )
}
export type GetMyProfileQueryHookResult = ReturnType<
  typeof useGetMyProfileQuery
>
export type GetMyProfileLazyQueryHookResult = ReturnType<
  typeof useGetMyProfileLazyQuery
>
export type GetMyProfileSuspenseQueryHookResult = ReturnType<
  typeof useGetMyProfileSuspenseQuery
>
export type GetMyProfileQueryResult = Apollo.QueryResult<
  GetMyProfileQuery,
  GetMyProfileQueryVariables
>
export const RemoveMyProfileDocument = gql`
  mutation removeMyProfile {
    removeMyProfile {
      id
    }
  }
`
export type RemoveMyProfileMutationFn = Apollo.MutationFunction<
  RemoveMyProfileMutation,
  RemoveMyProfileMutationVariables
>

/**
 * __useRemoveMyProfileMutation__
 *
 * To run a mutation, you first call `useRemoveMyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMyProfileMutation, { data, loading, error }] = useRemoveMyProfileMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveMyProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMyProfileMutation,
    RemoveMyProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveMyProfileMutation,
    RemoveMyProfileMutationVariables
  >(RemoveMyProfileDocument, options)
}
export type RemoveMyProfileMutationHookResult = ReturnType<
  typeof useRemoveMyProfileMutation
>
export type RemoveMyProfileMutationResult =
  Apollo.MutationResult<RemoveMyProfileMutation>
export type RemoveMyProfileMutationOptions = Apollo.BaseMutationOptions<
  RemoveMyProfileMutation,
  RemoveMyProfileMutationVariables
>
export const UpdateMyAddressDocument = gql`
  mutation updateMyAddress($data: UpdateAddressInput!) {
    updateMyAddress(data: $data) {
      id
      country
      state
      city
      line1
      line2
      zip
    }
  }
`
export type UpdateMyAddressMutationFn = Apollo.MutationFunction<
  UpdateMyAddressMutation,
  UpdateMyAddressMutationVariables
>

/**
 * __useUpdateMyAddressMutation__
 *
 * To run a mutation, you first call `useUpdateMyAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyAddressMutation, { data, loading, error }] = useUpdateMyAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMyAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyAddressMutation,
    UpdateMyAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMyAddressMutation,
    UpdateMyAddressMutationVariables
  >(UpdateMyAddressDocument, options)
}
export type UpdateMyAddressMutationHookResult = ReturnType<
  typeof useUpdateMyAddressMutation
>
export type UpdateMyAddressMutationResult =
  Apollo.MutationResult<UpdateMyAddressMutation>
export type UpdateMyAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyAddressMutation,
  UpdateMyAddressMutationVariables
>
export const UpdateMyCompanyDocument = gql`
  mutation updateMyCompany($data: UpdateCompanyInput!) {
    updateMyCompany(data: $data) {
      id
      name
      size
    }
  }
`
export type UpdateMyCompanyMutationFn = Apollo.MutationFunction<
  UpdateMyCompanyMutation,
  UpdateMyCompanyMutationVariables
>

/**
 * __useUpdateMyCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateMyCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyCompanyMutation, { data, loading, error }] = useUpdateMyCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMyCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyCompanyMutation,
    UpdateMyCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMyCompanyMutation,
    UpdateMyCompanyMutationVariables
  >(UpdateMyCompanyDocument, options)
}
export type UpdateMyCompanyMutationHookResult = ReturnType<
  typeof useUpdateMyCompanyMutation
>
export type UpdateMyCompanyMutationResult =
  Apollo.MutationResult<UpdateMyCompanyMutation>
export type UpdateMyCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyCompanyMutation,
  UpdateMyCompanyMutationVariables
>
export const UpdateMyProfileDocument = gql`
  mutation updateMyProfile($data: UpdateProfileInput!) {
    updateMyProfile(data: $data) {
      id
      firstName
      middleName
      lastName
    }
  }
`
export type UpdateMyProfileMutationFn = Apollo.MutationFunction<
  UpdateMyProfileMutation,
  UpdateMyProfileMutationVariables
>

/**
 * __useUpdateMyProfileMutation__
 *
 * To run a mutation, you first call `useUpdateMyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyProfileMutation, { data, loading, error }] = useUpdateMyProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMyProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyProfileMutation,
    UpdateMyProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMyProfileMutation,
    UpdateMyProfileMutationVariables
  >(UpdateMyProfileDocument, options)
}
export type UpdateMyProfileMutationHookResult = ReturnType<
  typeof useUpdateMyProfileMutation
>
export type UpdateMyProfileMutationResult =
  Apollo.MutationResult<UpdateMyProfileMutation>
export type UpdateMyProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyProfileMutation,
  UpdateMyProfileMutationVariables
>
export const CreateUserEscrowAddressDocument = gql`
  mutation CreateUserEscrowAddress($data: CreateUserEscrowAddressInput!) {
    createUserEscrowAddress(data: $data) {
      id
      userId
      contractType
      escrowAddress
      escrowSalt
    }
  }
`
export type CreateUserEscrowAddressMutationFn = Apollo.MutationFunction<
  CreateUserEscrowAddressMutation,
  CreateUserEscrowAddressMutationVariables
>

/**
 * __useCreateUserEscrowAddressMutation__
 *
 * To run a mutation, you first call `useCreateUserEscrowAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserEscrowAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserEscrowAddressMutation, { data, loading, error }] = useCreateUserEscrowAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserEscrowAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserEscrowAddressMutation,
    CreateUserEscrowAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUserEscrowAddressMutation,
    CreateUserEscrowAddressMutationVariables
  >(CreateUserEscrowAddressDocument, options)
}
export type CreateUserEscrowAddressMutationHookResult = ReturnType<
  typeof useCreateUserEscrowAddressMutation
>
export type CreateUserEscrowAddressMutationResult =
  Apollo.MutationResult<CreateUserEscrowAddressMutation>
export type CreateUserEscrowAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateUserEscrowAddressMutation,
  CreateUserEscrowAddressMutationVariables
>
export const GetUserEscrowAddressDocument = gql`
  query GetUserEscrowAddress($clientId: Int!) {
    getUserEscrowAddress(clientId: $clientId) {
      userEscrowAddress {
        id
        userId
        contractType
        escrowAddress
        escrowSalt
      }
    }
  }
`

/**
 * __useGetUserEscrowAddressQuery__
 *
 * To run a query within a React component, call `useGetUserEscrowAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEscrowAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEscrowAddressQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetUserEscrowAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserEscrowAddressQuery,
    GetUserEscrowAddressQueryVariables
  > &
    (
      | { variables: GetUserEscrowAddressQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserEscrowAddressQuery,
    GetUserEscrowAddressQueryVariables
  >(GetUserEscrowAddressDocument, options)
}
export function useGetUserEscrowAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEscrowAddressQuery,
    GetUserEscrowAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserEscrowAddressQuery,
    GetUserEscrowAddressQueryVariables
  >(GetUserEscrowAddressDocument, options)
}
export function useGetUserEscrowAddressSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUserEscrowAddressQuery,
        GetUserEscrowAddressQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetUserEscrowAddressQuery,
    GetUserEscrowAddressQueryVariables
  >(GetUserEscrowAddressDocument, options)
}
export type GetUserEscrowAddressQueryHookResult = ReturnType<
  typeof useGetUserEscrowAddressQuery
>
export type GetUserEscrowAddressLazyQueryHookResult = ReturnType<
  typeof useGetUserEscrowAddressLazyQuery
>
export type GetUserEscrowAddressSuspenseQueryHookResult = ReturnType<
  typeof useGetUserEscrowAddressSuspenseQuery
>
export type GetUserEscrowAddressQueryResult = Apollo.QueryResult<
  GetUserEscrowAddressQuery,
  GetUserEscrowAddressQueryVariables
>
export const GetUserEscrowAndSaltDocument = gql`
  query GetUserEscrowAndSalt($clientId: Int!) {
    getUserEscrowAndSalt(clientId: $clientId) {
      escrowAddress
      escrowSalt
    }
  }
`

/**
 * __useGetUserEscrowAndSaltQuery__
 *
 * To run a query within a React component, call `useGetUserEscrowAndSaltQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEscrowAndSaltQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEscrowAndSaltQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetUserEscrowAndSaltQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserEscrowAndSaltQuery,
    GetUserEscrowAndSaltQueryVariables
  > &
    (
      | { variables: GetUserEscrowAndSaltQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserEscrowAndSaltQuery,
    GetUserEscrowAndSaltQueryVariables
  >(GetUserEscrowAndSaltDocument, options)
}
export function useGetUserEscrowAndSaltLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEscrowAndSaltQuery,
    GetUserEscrowAndSaltQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserEscrowAndSaltQuery,
    GetUserEscrowAndSaltQueryVariables
  >(GetUserEscrowAndSaltDocument, options)
}
export function useGetUserEscrowAndSaltSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUserEscrowAndSaltQuery,
        GetUserEscrowAndSaltQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetUserEscrowAndSaltQuery,
    GetUserEscrowAndSaltQueryVariables
  >(GetUserEscrowAndSaltDocument, options)
}
export type GetUserEscrowAndSaltQueryHookResult = ReturnType<
  typeof useGetUserEscrowAndSaltQuery
>
export type GetUserEscrowAndSaltLazyQueryHookResult = ReturnType<
  typeof useGetUserEscrowAndSaltLazyQuery
>
export type GetUserEscrowAndSaltSuspenseQueryHookResult = ReturnType<
  typeof useGetUserEscrowAndSaltSuspenseQuery
>
export type GetUserEscrowAndSaltQueryResult = Apollo.QueryResult<
  GetUserEscrowAndSaltQuery,
  GetUserEscrowAndSaltQueryVariables
>
export const RequestChangeDocument = gql`
  mutation RequestChange($data: CreateRequestChangeInput!) {
    sendRequestChange(data: $data) {
      id
      budget
      submitWorkId
      description
    }
  }
`
export type RequestChangeMutationFn = Apollo.MutationFunction<
  RequestChangeMutation,
  RequestChangeMutationVariables
>

/**
 * __useRequestChangeMutation__
 *
 * To run a mutation, you first call `useRequestChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestChangeMutation, { data, loading, error }] = useRequestChangeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequestChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestChangeMutation,
    RequestChangeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestChangeMutation,
    RequestChangeMutationVariables
  >(RequestChangeDocument, options)
}
export type RequestChangeMutationHookResult = ReturnType<
  typeof useRequestChangeMutation
>
export type RequestChangeMutationResult =
  Apollo.MutationResult<RequestChangeMutation>
export type RequestChangeMutationOptions = Apollo.BaseMutationOptions<
  RequestChangeMutation,
  RequestChangeMutationVariables
>
export const SubmitWorkDocument = gql`
  mutation SubmitWork($data: SubmitWorkInput!) {
    submitWork(data: $data) {
      id
      budget
      currency
      contractId
      milestoneId
      description
    }
  }
`
export type SubmitWorkMutationFn = Apollo.MutationFunction<
  SubmitWorkMutation,
  SubmitWorkMutationVariables
>

/**
 * __useSubmitWorkMutation__
 *
 * To run a mutation, you first call `useSubmitWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitWorkMutation, { data, loading, error }] = useSubmitWorkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitWorkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitWorkMutation,
    SubmitWorkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitWorkMutation, SubmitWorkMutationVariables>(
    SubmitWorkDocument,
    options,
  )
}
export type SubmitWorkMutationHookResult = ReturnType<
  typeof useSubmitWorkMutation
>
export type SubmitWorkMutationResult = Apollo.MutationResult<SubmitWorkMutation>
export type SubmitWorkMutationOptions = Apollo.BaseMutationOptions<
  SubmitWorkMutation,
  SubmitWorkMutationVariables
>
export const GetMyTransactionsDocument = gql`
  query getMyTransactions($page: Int, $limit: Int) {
    getMyTransactions(filter: { page: $page, limit: $limit }) {
      totalCount
      hasNextPage
      nodes {
        hash
        depositId
        contractId
        amount
        fee
        transactionType
        currency
        userAccount
        status
        contractName
        transactionLink
        createdAt
        transactionId
        assignedUsers {
          id
          email
          role
          userId
          contractId
          firstName
          lastName
        }
        descriptions {
          description
          contractType
        }
        mainTransaction {
          hash
          depositId
          contractId
          amount
          fee
          transactionType
          currency
          userAccount
          status
          transactionLink
          createdAt
          transactionId
        }
      }
    }
  }
`

/**
 * __useGetMyTransactionsQuery__
 *
 * To run a query within a React component, call `useGetMyTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMyTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyTransactionsQuery,
    GetMyTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMyTransactionsQuery,
    GetMyTransactionsQueryVariables
  >(GetMyTransactionsDocument, options)
}
export function useGetMyTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyTransactionsQuery,
    GetMyTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMyTransactionsQuery,
    GetMyTransactionsQueryVariables
  >(GetMyTransactionsDocument, options)
}
export function useGetMyTransactionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMyTransactionsQuery,
        GetMyTransactionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetMyTransactionsQuery,
    GetMyTransactionsQueryVariables
  >(GetMyTransactionsDocument, options)
}
export type GetMyTransactionsQueryHookResult = ReturnType<
  typeof useGetMyTransactionsQuery
>
export type GetMyTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetMyTransactionsLazyQuery
>
export type GetMyTransactionsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyTransactionsSuspenseQuery
>
export type GetMyTransactionsQueryResult = Apollo.QueryResult<
  GetMyTransactionsQuery,
  GetMyTransactionsQueryVariables
>
export const TransactionAmountsDocument = gql`
  query transactionAmounts(
    $transactionFeeType: TransactionFeeType!
    $amount: Float!
    $wallet: String!
  ) {
    transactionAmounts(
      data: {
        transactionFeeType: $transactionFeeType
        amount: $amount
        wallet: $wallet
      }
    ) {
      percentage
      amountWithFee
      amountWithoutFee
      fee
    }
  }
`

/**
 * __useTransactionAmountsQuery__
 *
 * To run a query within a React component, call `useTransactionAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionAmountsQuery({
 *   variables: {
 *      transactionFeeType: // value for 'transactionFeeType'
 *      amount: // value for 'amount'
 *      wallet: // value for 'wallet'
 *   },
 * });
 */
export function useTransactionAmountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TransactionAmountsQuery,
    TransactionAmountsQueryVariables
  > &
    (
      | { variables: TransactionAmountsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TransactionAmountsQuery,
    TransactionAmountsQueryVariables
  >(TransactionAmountsDocument, options)
}
export function useTransactionAmountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionAmountsQuery,
    TransactionAmountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TransactionAmountsQuery,
    TransactionAmountsQueryVariables
  >(TransactionAmountsDocument, options)
}
export function useTransactionAmountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TransactionAmountsQuery,
        TransactionAmountsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    TransactionAmountsQuery,
    TransactionAmountsQueryVariables
  >(TransactionAmountsDocument, options)
}
export type TransactionAmountsQueryHookResult = ReturnType<
  typeof useTransactionAmountsQuery
>
export type TransactionAmountsLazyQueryHookResult = ReturnType<
  typeof useTransactionAmountsLazyQuery
>
export type TransactionAmountsSuspenseQueryHookResult = ReturnType<
  typeof useTransactionAmountsSuspenseQuery
>
export type TransactionAmountsQueryResult = Apollo.QueryResult<
  TransactionAmountsQuery,
  TransactionAmountsQueryVariables
>
export const GetTransactionDocument = gql`
  query getTransaction($page: Int, $limit: Int, $contractId: Int) {
    transactions(
      filter: { page: $page, limit: $limit, contractId: $contractId }
    ) {
      hash
      depositId
      contractId
      amount
      fee
      transactionType
      currency
      userAccount
      status
      contractName
      transactionLink
      createdAt
      transactionId
      descriptions {
        description
        contractType
      }
      assignedUsers {
        id
        contractId
        userId
        role
        email
        firstName
        lastName
      }
      mainTransaction {
        hash
        depositId
        contractId
        amount
        fee
        transactionType
        currency
        userAccount
        status
        transactionLink
        createdAt
        transactionId
      }
    }
  }
`

/**
 * __useGetTransactionQuery__
 *
 * To run a query within a React component, call `useGetTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetTransactionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransactionQuery,
    GetTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTransactionQuery, GetTransactionQueryVariables>(
    GetTransactionDocument,
    options,
  )
}
export function useGetTransactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionQuery,
    GetTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTransactionQuery, GetTransactionQueryVariables>(
    GetTransactionDocument,
    options,
  )
}
export function useGetTransactionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTransactionQuery,
        GetTransactionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTransactionQuery,
    GetTransactionQueryVariables
  >(GetTransactionDocument, options)
}
export type GetTransactionQueryHookResult = ReturnType<
  typeof useGetTransactionQuery
>
export type GetTransactionLazyQueryHookResult = ReturnType<
  typeof useGetTransactionLazyQuery
>
export type GetTransactionSuspenseQueryHookResult = ReturnType<
  typeof useGetTransactionSuspenseQuery
>
export type GetTransactionQueryResult = Apollo.QueryResult<
  GetTransactionQuery,
  GetTransactionQueryVariables
>
export const AddTransactionDocument = gql`
  mutation AddTransaction($data: AddTransactionDataInput!) {
    addTransaction(data: $data) {
      hash
      transactionLink
    }
  }
`
export type AddTransactionMutationFn = Apollo.MutationFunction<
  AddTransactionMutation,
  AddTransactionMutationVariables
>

/**
 * __useAddTransactionMutation__
 *
 * To run a mutation, you first call `useAddTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTransactionMutation, { data, loading, error }] = useAddTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTransactionMutation,
    AddTransactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddTransactionMutation,
    AddTransactionMutationVariables
  >(AddTransactionDocument, options)
}
export type AddTransactionMutationHookResult = ReturnType<
  typeof useAddTransactionMutation
>
export type AddTransactionMutationResult =
  Apollo.MutationResult<AddTransactionMutation>
export type AddTransactionMutationOptions = Apollo.BaseMutationOptions<
  AddTransactionMutation,
  AddTransactionMutationVariables
>
export const CreateWalletDocument = gql`
  mutation createWallet($data: CreateWalletInput!) {
    createWallet(createWalletInput: $data) {
      id
      wallet
      contractId
      userId
    }
  }
`
export type CreateWalletMutationFn = Apollo.MutationFunction<
  CreateWalletMutation,
  CreateWalletMutationVariables
>

/**
 * __useCreateWalletMutation__
 *
 * To run a mutation, you first call `useCreateWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWalletMutation, { data, loading, error }] = useCreateWalletMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWalletMutation,
    CreateWalletMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateWalletMutation,
    CreateWalletMutationVariables
  >(CreateWalletDocument, options)
}
export type CreateWalletMutationHookResult = ReturnType<
  typeof useCreateWalletMutation
>
export type CreateWalletMutationResult =
  Apollo.MutationResult<CreateWalletMutation>
export type CreateWalletMutationOptions = Apollo.BaseMutationOptions<
  CreateWalletMutation,
  CreateWalletMutationVariables
>
export const FindAllWalletsDocument = gql`
  query FindAllWallets($page: Int, $limit: Int, $contractId: Int) {
    findAllWallets(
      filter: { page: $page, limit: $limit, contractId: $contractId }
    ) {
      id
      wallet
      contractId
      userId
      userWallet {
        id
        profileId
        userId
        wallet
        icon
        walletType
      }
    }
  }
`

/**
 * __useFindAllWalletsQuery__
 *
 * To run a query within a React component, call `useFindAllWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllWalletsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useFindAllWalletsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindAllWalletsQuery,
    FindAllWalletsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindAllWalletsQuery, FindAllWalletsQueryVariables>(
    FindAllWalletsDocument,
    options,
  )
}
export function useFindAllWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllWalletsQuery,
    FindAllWalletsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindAllWalletsQuery, FindAllWalletsQueryVariables>(
    FindAllWalletsDocument,
    options,
  )
}
export function useFindAllWalletsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindAllWalletsQuery,
        FindAllWalletsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    FindAllWalletsQuery,
    FindAllWalletsQueryVariables
  >(FindAllWalletsDocument, options)
}
export type FindAllWalletsQueryHookResult = ReturnType<
  typeof useFindAllWalletsQuery
>
export type FindAllWalletsLazyQueryHookResult = ReturnType<
  typeof useFindAllWalletsLazyQuery
>
export type FindAllWalletsSuspenseQueryHookResult = ReturnType<
  typeof useFindAllWalletsSuspenseQuery
>
export type FindAllWalletsQueryResult = Apollo.QueryResult<
  FindAllWalletsQuery,
  FindAllWalletsQueryVariables
>
export const GetUserWalletsDocument = gql`
  query getUserWallets {
    getUserWallets {
      id
      profileId
      userId
      wallet
      icon
      walletType
      contractType
    }
  }
`

/**
 * __useGetUserWalletsQuery__
 *
 * To run a query within a React component, call `useGetUserWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWalletsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserWalletsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserWalletsQuery,
    GetUserWalletsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserWalletsQuery, GetUserWalletsQueryVariables>(
    GetUserWalletsDocument,
    options,
  )
}
export function useGetUserWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserWalletsQuery,
    GetUserWalletsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserWalletsQuery, GetUserWalletsQueryVariables>(
    GetUserWalletsDocument,
    options,
  )
}
export function useGetUserWalletsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUserWalletsQuery,
        GetUserWalletsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetUserWalletsQuery,
    GetUserWalletsQueryVariables
  >(GetUserWalletsDocument, options)
}
export type GetUserWalletsQueryHookResult = ReturnType<
  typeof useGetUserWalletsQuery
>
export type GetUserWalletsLazyQueryHookResult = ReturnType<
  typeof useGetUserWalletsLazyQuery
>
export type GetUserWalletsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserWalletsSuspenseQuery
>
export type GetUserWalletsQueryResult = Apollo.QueryResult<
  GetUserWalletsQuery,
  GetUserWalletsQueryVariables
>
export const SaveUserWalletDocument = gql`
  mutation saveUserWallet($data: SaveWalletInput!) {
    saveUserWallet(wallet: $data) {
      id
      profileId
      userId
      wallet
      icon
      contractType
    }
  }
`
export type SaveUserWalletMutationFn = Apollo.MutationFunction<
  SaveUserWalletMutation,
  SaveUserWalletMutationVariables
>

/**
 * __useSaveUserWalletMutation__
 *
 * To run a mutation, you first call `useSaveUserWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserWalletMutation, { data, loading, error }] = useSaveUserWalletMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveUserWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserWalletMutation,
    SaveUserWalletMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveUserWalletMutation,
    SaveUserWalletMutationVariables
  >(SaveUserWalletDocument, options)
}
export type SaveUserWalletMutationHookResult = ReturnType<
  typeof useSaveUserWalletMutation
>
export type SaveUserWalletMutationResult =
  Apollo.MutationResult<SaveUserWalletMutation>
export type SaveUserWalletMutationOptions = Apollo.BaseMutationOptions<
  SaveUserWalletMutation,
  SaveUserWalletMutationVariables
>
