import Modal from '../Modal'
import { useConnectWalletModal } from '../../hooks/useConnectWalletModal.tsx'
import { Col, Image, Row } from 'antd'
import { Spinner } from '../icons/Spinner.tsx'
import Title from '../Typography/Title'
import Text from '../Typography/Text'
import { useTheme } from '../../../app/theme/useTheme.tsx'
import { useCurrentAccount } from '../../hooks/useCurrentAccount.tsx'
import useLocales from '../../hooks/useLocales.ts'
import { useCallback, useEffect, useState } from 'react'
import insufficientFunds from '../../assets/insufficient-funds.svg'
import modalFlowError from '../../assets/modalFlowError.svg'
import transactionSuccess from '../../assets/transactionSuccess.svg'
import ConnectedWallet from '../../../pages/dashboard/ContractsPage/ContractsList/pages/ContractsDetails/components/Checklist/components/Steps/AddWallets/components/connectedWallet.tsx'
import { getEtherscanTxUrl } from '../../helpers/etherScanTransaction.ts'
import { Link } from 'react-router-dom'
import { useTruncateByChars } from '../../hooks/useTruncateByChars.tsx'

export const PaymentFlowModal = () => {
  const {
    isPaymentFlowModalOpen,
    setIsPaymentFlowModalOpen,
    transaction,
    isLoading: loadingState,
    isError: errorState,
    hasTransactionScreen,
    submitHandler,
    resetModalState,
  } = useConnectWalletModal()

  const [isLoading, setIsLoading] = useState(false)
  const [isInsufficient, setIsInsufficient] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isTransaction, setIsTransaction] = useState(false)

  const theme = useTheme()
  const truncated = useTruncateByChars()
  const { externalAccount } = useCurrentAccount()
  const { translate } = useLocales()

  useEffect(() => {
    if (!isPaymentFlowModalOpen) return
    const handleState = () => {
      if (transaction && hasTransactionScreen) {
        setIsPaymentFlowModalOpen(false)
        return
      }
      if (transaction && isPaymentFlowModalOpen) {
        setIsLoading(false)
        setIsInsufficient(false)
        setIsError(false)
        setIsTransaction(true)
      } else if (errorState) {
        setIsLoading(false)
        if (errorState.includes('rejected')) {
          setIsPaymentFlowModalOpen(false)
        } else if (errorState.includes('balance')) {
          setIsInsufficient(true)
        } else {
          setIsError(true)
        }
      } else if (loadingState) {
        setIsLoading(true)
      }
    }

    handleState()
  }, [
    transaction,
    errorState,
    hasTransactionScreen,
    setIsPaymentFlowModalOpen,
    isPaymentFlowModalOpen,
    loadingState,
  ])

  const handleCancel = useCallback(() => {
    setIsLoading(false)
    setIsInsufficient(false)
    setIsError(false)
    setIsTransaction(false)
    resetModalState()
  }, [resetModalState])

  useEffect(() => {
    return () => handleCancel()
  }, [handleCancel])

  const handleOk = () => {
    if (isInsufficient || isTransaction) {
      handleCancel()
    } else {
      if (submitHandler) {
        handleCancel()
        void submitHandler()
      }
    }
  }

  useEffect(() => {
    if (!isPaymentFlowModalOpen) {
      handleCancel()
    }
  }, [isPaymentFlowModalOpen, handleCancel])

  return (
    <Modal
      open={isPaymentFlowModalOpen}
      setOpen={setIsPaymentFlowModalOpen}
      onCancel={handleCancel}
      handleOk={handleOk}
      isNotBranded={!isError}
      width={480}
      cta={isError ? 'Try Again' : 'Close'}
      cancelText={isError && 'Cancel'}
      maskClosable={false}
      closable={false}
      isFooterHidden={isLoading}
      isVerticalButtons={true}
      destroyOnClose
    >
      <Row
        align="middle"
        justify="center"
        style={{ height: isLoading ? '118px' : undefined }}
      >
        {isLoading && <Spinner />}
        {isInsufficient && <Image src={insufficientFunds} preview={false} />}
        {isError && <Image src={modalFlowError} preview={false} />}
        {isTransaction && <Image src={transactionSuccess} preview={false} />}
      </Row>

      <Col
        style={{
          marginTop: isLoading
            ? theme.marginLG
            : isInsufficient
              ? theme.marginXL
              : isError
                ? theme.margin
                : isTransaction
                  ? theme.marginXL
                  : undefined,
          gap: theme.paddingXS,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Title level={2} style={{ textAlign: 'center' }}>
          {isLoading &&
            translate(
              `payment_modal_flow.${externalAccount ? 'check_title' : 'processing'}`,
            )}
          {isInsufficient &&
            translate(`payment_modal_flow.insufficient_funds_title`)}
          {isError && translate(`payment_modal_flow.error_title`)}
          {isTransaction &&
            translate(`payment_modal_flow.transaction_successful_title`)}
        </Title>
        <Text
          style={{
            textAlign: 'center',
            marginBottom: isLoading ? theme.marginLG : 0,
          }}
          type="secondary"
          size="lg"
        >
          {isLoading &&
            translate(
              `payment_modal_flow.${externalAccount ? 'check_text' : 'processing_text'}`,
            )}
          {isInsufficient &&
            translate(`payment_modal_flow.insufficient_funds_text`)}
          {isError && translate(`payment_modal_flow.error_text`)}
          {isTransaction &&
            translate(`payment_modal_flow.transaction_successful_text`)}
        </Text>
      </Col>

      {isInsufficient && (
        <Row style={{ marginTop: theme.marginLG }}>
          <ConnectedWallet isPaymentFlow />
        </Row>
      )}

      {isTransaction && transaction && (
        <Row justify="center" style={{ marginTop: theme.marginLG }}>
          <Link
            to={getEtherscanTxUrl(transaction)}
            style={{ display: 'block' }}
            target={'_blank'}
          >
            {truncated(transaction)}
          </Link>
        </Row>
      )}
    </Modal>
  )
}
