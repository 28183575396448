import { Flex } from 'antd'
import styled from 'styled-components'
import { useTheme } from '../../app/theme/useTheme'

export const Header = styled(Flex)(() => {
  const theme = useTheme()
  return {
    paddingInline: theme.marginXL,
    paddingTop: theme.marginLG,
  }
})

export const Content = styled(Flex)(() => {
  const theme = useTheme()
  return {
    flex: 1,
    paddingInline: theme.marginXL,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '138px',
  }
})

export const InnerContent = styled(Flex)(() => {
  return {
    maxWidth: '400px',
  }
})
export const Footer = styled(Flex)(() => {
  return {
    paddingBlock: '40px',
  }
})
