import { useTheme } from '../../../app/theme/useTheme'
import { IStyledComponent } from './types'

const createComponentStyles = ({
  type,
  size,
  color,
  $medium,
  $bold,
}: IStyledComponent) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme()

  const fontsSizeObj = {
    xs: '10px',
    sm: theme.fontSizeSM,
    lg: theme.fontSizeLG,
    xl: theme.fontSizeXL,
    base: theme.fontSize,
  }

  const fontSize = size ? fontsSizeObj[size] : fontsSizeObj.base
  const fontWeight =
    ($medium && theme.fontWeightMedium) || ($bold && theme.fontWeightBold)

  return {
    '&&&': {
      marginBottom: 0,
      ...(fontSize && { fontSize }),
      ...(fontWeight && { fontWeight }),
      lineHeight: 1.5,
    },
    ...(type === 'secondary' && {
      '&&&&': { color: theme.colorTextSecondary },
    }),
    ...(type === 'lightSecondary' && {
      '&&&&': { color: theme.colorTextDisabled },
    }),
    ...(color && { color }),
  }
}

export default createComponentStyles
