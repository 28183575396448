import { create } from 'zustand'
import {
  User,
  applyActionCode,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  fetchSignInMethodsForEmail,
} from 'firebase/auth'
import { auth, googleAuthProvider } from '../../../firebase'
import { immer } from 'zustand/middleware/immer'
import { devtools } from 'zustand/middleware'
import { AuthStore } from './types'
import { UpdateUserMutation } from '../../../app/graphQl/generated/graphql'
import client from '../../../app/apolloClient.ts'
import { FIREBASE_ERRORS } from '../../constants/constants.ts'
import apolloClient from '../../../app/apolloClient.ts'
import { UPDATE_USER } from '../../schemas/updateUser.tsx'

const initialState = {
  isLoading: false,
  error: '',
  accessToken: '',
  refreshToken: '',
  user: {
    onBoarding: false,
    displayName: '',
    email: '',
    phoneNumber: '',
    photoURL: '',
    uid: '',
    role: '',
    firstName: '',
    lastName: '',
    companyName: '',
    country: '',
    id: '',
    isVerified: false,
    isEmailVerified: false,
    is2FAEnabled: false,
    escrowAddress: null,
    escrowSalt: null,
  },
}

const setAuthState = (
  state: AuthStore,
  user: User,
  signUp?: boolean,
  apiUser?: UpdateUserMutation['updateMyUser'],
) => {
  state.accessToken = (user as any).accessToken
  state.refreshToken = user.refreshToken
  state.user.displayName = user.displayName
  state.user.email = user.email
  state.user.phoneNumber = user.phoneNumber
  state.user.photoURL = user.photoURL
  state.user.uid = user.uid
  signUp ? (state.user.onBoarding = false) : (state.user.onBoarding = true)
  if (apiUser) {
    state.user.id = apiUser.id
    state.user.role = apiUser.role
    state.user.firstName = apiUser.firstName
    state.user.lastName = apiUser.lastName
    state.user.country = apiUser.country
    state.user.displayName = `${apiUser.firstName} ${apiUser.lastName}`
    state.user.isVerified = apiUser.isVerified
    state.user.isEmailVerified = apiUser.isEmailVerified || user.emailVerified
    state.user.is2FAEnabled = apiUser.is2FAEnabled
  }
}

export const useAuthStore = create<AuthStore>()(
  devtools(
    immer((set) => {
      const setLoading = (loading: boolean) =>
        set((state) => {
          state.isLoading = loading
        })
      const errorHandler = (
        message: string,
        errorNotification?: (message: string) => void,
      ) => {
        set((state) => {
          state.error = message
        })
        errorNotification && errorNotification(message)
      }
      return {
        ...initialState,
        setLoading,
        login: async ({ email, password }, { success, error }) => {
          try {
            setLoading(true)
            const { user } = await signInWithEmailAndPassword(
              auth,
              email,
              password,
            )
            set((state) => {
              setAuthState(state, user)
            })
            success()
            // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
          } catch (err: Error | any) {
            if (
              err.message.includes('wrong-password') ||
              err.message.includes('user-not-found')
            ) {
              errorHandler('Email or password error', error)
            } else if (err.code.includes(FIREBASE_ERRORS.twoFactorRequired)) {
              errorHandler(err, error)
            } else {
              errorHandler('Some Error, pls try again', error)
            }
          } finally {
            setLoading(false)
          }
        },
        googleLogin: async ({ success, error }, isSignUp) => {
          try {
            set((state) => {
              state.isLoading = true
            })
            const result = await signInWithPopup(auth, googleAuthProvider)

            if (result.user.email) {
              const signInMethods = await fetchSignInMethodsForEmail(
                auth,
                result.user.email,
              )

              if (isSignUp && signInMethods.length > 0) {
                set((state) => {
                  state.error = 'User already exists'
                })
                throw new Error('User already exists')
              }
            }

            set((state) => setAuthState(state, result.user, isSignUp))
            success(result.user.email)
            // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
          } catch (err: Error | any) {
            if (err.code?.includes(FIREBASE_ERRORS.twoFactorRequired)) {
              errorHandler(err, error)
            } else errorHandler(err.message, error)
          } finally {
            set((state) => {
              state.isLoading = false
            })
          }
        },
        logOut: async () => {
          try {
            await signOut(auth)
            apolloClient.stop()
            await apolloClient.clearStore()
          } catch (e) {
            console.log(e)
          }
          set((state) => {
            state.error = ''
            state.accessToken = ''
            state.refreshToken = ''
            state.user = initialState.user
          })
        },
        setUserAfterRefresh: (user, onBoarding, apiUser) => {
          set((state) => {
            setAuthState(state, user, !onBoarding, apiUser)
          })
        },
        setUserAfterOnboarding: (user) => {
          set((state) => {
            state.user.id = user.id
            state.user.role = user.role
            state.user.firstName = user.firstName
            state.user.lastName = user.lastName
            state.user.country = user.country
            state.user.onBoarding = true
            state.user.isVerified = false
            state.user.isEmailVerified = false
            state.user.is2FAEnabled = false
            // state.companyName = user.companyName
          })
        },
        updateUserIsVerified: (user) => {
          set((state) => {
            state.user.isVerified = user.isVerified
          })
        },
        updateUserRole: (user) => {
          set((state) => {
            state.user.role = user.role
          })
        },
        updateUserNames: (user) => {
          set((state) => {
            state.user.firstName = user.firstName
            state.user.lastName = user.lastName
            state.user.displayName = `${user.firstName} ${user.lastName}`
          })
        },
        updateUserCountry: (user) => {
          set((state) => {
            state.user.country = user.country
          })
        },
        signUp: async ({ email, password }, { success, error }) => {
          try {
            setLoading(true)
            const { user } = await createUserWithEmailAndPassword(
              auth,
              email,
              password,
            )
            await success()
            set((state) => {
              setAuthState(state, user, true)
            })
            // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
          } catch (err: Error | any) {
            errorHandler(err.message, error)
          } finally {
            setLoading(false)
          }
        },
        sendVerification: async (notificationClb) => {
          try {
            setLoading(true)
            await sendEmailVerification(auth.currentUser!)
            notificationClb?.success()
            // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
          } catch (err: Error | any) {
            errorHandler(err.message, notificationClb?.error)
          } finally {
            setLoading(false)
          }
        },
        applyAction: async (oobCode) => {
          await applyActionCode(auth, oobCode)
          try {
            const { data } = await client.mutate({
              mutation: UPDATE_USER,
              variables: { data: { isEmailVerified: true } },
            })
            console.log(data)
          } catch (error) {
            console.error('Error performing mutation', error)
            // Handle error
          }
          set((state) => {
            state.user.isEmailVerified = true
          })
        },
      }
    }),
  ),
)
