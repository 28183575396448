import ReactDOM from 'react-dom/client'
import App from './app'
import './index.css'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import client from './app/apolloClient'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import '@rainbow-me/rainbowkit/styles.css'

import { getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { Config, WagmiProvider } from 'wagmi'
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  sepolia,
  blast,
  polygonAmoy,
} from 'wagmi/chains'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { configAlchemy } from './shared/blockchain/alchemy/config.ts'
import { AlchemyAccountProvider } from '@account-kit/react'

const config = getDefaultConfig({
  appName: 'RainbowKit App',
  // Every dApp that relies on WalletConnect now needs to obtain a projectId from WalletConnect Cloud.
  // This is absolutely free and only takes a few minutes.
  //https://cloud.walletconnect.com/
  projectId: import.meta.env.VITE_ESCROW_WEB3_PROJECT_ID,
  chains: [
    mainnet,
    polygon,
    optimism,
    arbitrum,
    base,
    sepolia,
    blast,
    polygonAmoy,
  ],
})

const queryClient = new QueryClient()

const transactionId =
  localStorage.getItem('@midcontract: transaction_id') || uuidv4()
localStorage.setItem('@midcontract: transaction_id', transactionId)

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // tracePropagationTargets: [
      //   "https://api-dev-8ff4b58b.nip.io/",
      //   /^https:\/\/api-dev-8ff4b58b\.nip\.io\/graphql/,
      // ],
      // tracePropagationTargets: ["localhost", /^https:\/\/api-dev-8ff4b58b\.nip\.io\/graphql/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Feedback({
      // Additional SDK configuration goes in here, for example:
      colorScheme: 'light',
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: [
    'https://api-dev-8ff4b58b.nip.io/',
    /^https:\/\/api-dev-8ff4b58b\.nip\.io\/graphql/,
  ],
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null
    }
    return event
  },
})
Sentry.configureScope((scope) => {
  scope.setTag('transaction_id', transactionId)
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <WagmiProvider config={config as Config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider showRecentTransactions={true}>
          <AlchemyAccountProvider
            config={configAlchemy}
            queryClient={queryClient}
          >
            <ApolloProvider client={client}>
              <App />
            </ApolloProvider>
          </AlchemyAccountProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </BrowserRouter>,
)
