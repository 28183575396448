import React, { createContext, useState, ReactNode, useCallback } from 'react'

export interface ModalConnectContextType {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  isHeader: boolean
  setIsHeader: React.Dispatch<React.SetStateAction<boolean>>
  isPaymentFlowModalOpen: boolean
  setIsPaymentFlowModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  hasTransactionScreen: boolean
  setHasTransactionScreen: React.Dispatch<React.SetStateAction<boolean>>
  isError: string
  setIsError: React.Dispatch<React.SetStateAction<string>>
  transaction: string
  setTransaction: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  submitHandler: (() => void | Promise<void> | undefined) | undefined
  setSubmitHandler: React.Dispatch<
    React.SetStateAction<(() => void | Promise<void> | undefined) | undefined>
  >
  resetModalState: () => void
  transactionLink: string | null
  setTransactionLink: React.Dispatch<React.SetStateAction<string | null>>
}

export const ConnectModalContext = createContext<
  ModalConnectContextType | undefined
>(undefined)

export const ConnectModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isHeader, setIsHeader] = useState(false)
  const [isPaymentFlowModalOpen, setIsPaymentFlowModalOpen] = useState(false)
  const [hasTransactionScreen, setHasTransactionScreen] = useState(false)
  const [isError, setIsError] = useState('')
  const [transaction, setTransaction] = useState('')
  const [transactionLink, setTransactionLink] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [submitHandler, setSubmitHandler] =
    useState<() => void | Promise<void> | undefined>()

  const resetModalState = useCallback(() => {
    setIsPaymentFlowModalOpen(false)
    setHasTransactionScreen(false)
    setIsError('')
    setTransaction('')
    setIsLoading(false)
    setSubmitHandler(undefined)
  }, [])

  return (
    <ConnectModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        isHeader,
        setIsHeader,
        isPaymentFlowModalOpen,
        setIsPaymentFlowModalOpen,
        hasTransactionScreen,
        setHasTransactionScreen,
        isError,
        setIsError,
        transaction,
        setTransaction,
        isLoading,
        setIsLoading,
        setSubmitHandler,
        submitHandler,
        resetModalState,
        transactionLink,
        setTransactionLink,
      }}
    >
      {children}
    </ConnectModalContext.Provider>
  )
}
