export function getEtherscanTxUrl(
  txHash?: string,
  isAddress?: boolean,
): string {
  const networkUrls: Record<string, string> = {
    mainnet: 'https://etherscan.io',
    sepolia: 'https://sepolia.etherscan.io',
    polygonAmoy: 'https://amoy.polygonscan.com',
  }

  const network = import.meta.env
    .VITE_APP_NETWORK_NAME as keyof typeof networkUrls

  const baseUrl = networkUrls[network] || networkUrls.mainnet

  if (!txHash) {
    console.warn('Transaction hash is undefined')
    return ''
  }

  return `${baseUrl}/${isAddress ? 'address/' : 'tx/'}${txHash}`
}
