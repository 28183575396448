import { gql } from '@apollo/client'

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateMyUser(data: $data) {
      id
      firstName
      lastName
      country
      role
      isVerified
      isEmailVerified
      is2FAEnabled
    }
  }
`
